/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { map }                                               from 'rxjs/operators';


import { ActivityTask } from '../model/activityTask';
import { ActivityTaskNew } from '../model/activityTaskNew';
import { AssignmentTaxonomyClassification } from '../model/assignmentTaxonomyClassification';
import { AssociatedFile } from '../model/associatedFile';
import { AssociatedLink } from '../model/associatedLink';
import { AssociatedLinkNew } from '../model/associatedLinkNew';
import { BiodiversityTarget } from '../model/biodiversityTarget';
import { BiodiversityTargetNew } from '../model/biodiversityTargetNew';
import { BiophysicalFactor } from '../model/biophysicalFactor';
import { BiophysicalFactorNew } from '../model/biophysicalFactorNew';
import { BiophysicalResult } from '../model/biophysicalResult';
import { BiophysicalResultNew } from '../model/biophysicalResultNew';
import { ChartData } from '../model/chartData';
import { ChildFactor } from '../model/childFactor';
import { ContributingFactor } from '../model/contributingFactor';
import { ContributingFactorNew } from '../model/contributingFactorNew';
import { CustomReport } from '../model/customReport';
import { CustomReportNew } from '../model/customReportNew';
import { DiagramResult } from '../model/diagramResult';
import { DiagramResultsTracking } from '../model/diagramResultsTracking';
import { DirectThreat } from '../model/directThreat';
import { DirectThreatNew } from '../model/directThreatNew';
import { ExpenseTaxonomyClassification } from '../model/expenseTaxonomyClassification';
import { FactorDeleteInfo } from '../model/factorDeleteInfo';
import { FactorDiagramInfo } from '../model/factorDiagramInfo';
import { FactorExpense } from '../model/factorExpense';
import { FactorExpenseDetail } from '../model/factorExpenseDetail';
import { FactorExpenseDetailNew } from '../model/factorExpenseDetailNew';
import { FactorExpenseDetailUpdate } from '../model/factorExpenseDetailUpdate';
import { FactorExpenseNew } from '../model/factorExpenseNew';
import { FactorFootprint } from '../model/factorFootprint';
import { FactorProgress } from '../model/factorProgress';
import { FactorProjectResource } from '../model/factorProjectResource';
import { FactorResourceIdentifier } from '../model/factorResourceIdentifier';
import { FactorShareable } from '../model/factorShareable';
import { FactorSummary } from '../model/factorSummary';
import { FactorSummaryNew } from '../model/factorSummaryNew';
import { FactorTaxonomy } from '../model/factorTaxonomy';
import { FactorTaxonomyClassification } from '../model/factorTaxonomyClassification';
import { FactorTimeframeDetail } from '../model/factorTimeframeDetail';
import { FactorType } from '../model/factorType';
import { FactorTypeFootprint } from '../model/factorTypeFootprint';
import { FeatureCollection } from '../model/featureCollection';
import { Goal } from '../model/goal';
import { GoalNew } from '../model/goalNew';
import { GoalRelevantFactors } from '../model/goalRelevantFactors';
import { HumanWellbeingTarget } from '../model/humanWellbeingTarget';
import { HumanWellbeingTargetNew } from '../model/humanWellbeingTargetNew';
import { Indicator } from '../model/indicator';
import { IndicatorFutureStatus } from '../model/indicatorFutureStatus';
import { IndicatorFutureStatusNew } from '../model/indicatorFutureStatusNew';
import { IndicatorMeasurement } from '../model/indicatorMeasurement';
import { IndicatorMeasurementNew } from '../model/indicatorMeasurementNew';
import { IndicatorMethod } from '../model/indicatorMethod';
import { IndicatorMethodNew } from '../model/indicatorMethodNew';
import { IndicatorNew } from '../model/indicatorNew';
import { IndicatorRelevantFactors } from '../model/indicatorRelevantFactors';
import { IndicatorScorecard } from '../model/indicatorScorecard';
import { IndicatorScorecardDetails } from '../model/indicatorScorecardDetails';
import { IndicatorScorecardFactors } from '../model/indicatorScorecardFactors';
import { IndicatorScorecardNew } from '../model/indicatorScorecardNew';
import { IndicatorViability } from '../model/indicatorViability';
import { IndicatorViabilityRatings } from '../model/indicatorViabilityRatings';
import { IntermediateResult } from '../model/intermediateResult';
import { IntermediateResultNew } from '../model/intermediateResultNew';
import { KeyEcologicalAttribute } from '../model/keyEcologicalAttribute';
import { KeyEcologicalAttributeNew } from '../model/keyEcologicalAttributeNew';
import { NestedTarget } from '../model/nestedTarget';
import { NestedTargetNew } from '../model/nestedTargetNew';
import { Objective } from '../model/objective';
import { ObjectiveNew } from '../model/objectiveNew';
import { ObjectiveRelevantFactors } from '../model/objectiveRelevantFactors';
import { Output } from '../model/output';
import { OutputNew } from '../model/outputNew';
import { OutputRelevantFactors } from '../model/outputRelevantFactors';
import { ParentFactorInfo } from '../model/parentFactorInfo';
import { ProblemDetail } from '../model/problemDetail';
import { ProgressPercent } from '../model/progressPercent';
import { ProgressPercentNew } from '../model/progressPercentNew';
import { ProgressReport } from '../model/progressReport';
import { ProgressReportNew } from '../model/progressReportNew';
import { Project } from '../model/project';
import { ProjectAccessState } from '../model/projectAccessState';
import { ProjectActionTimeframes } from '../model/projectActionTimeframes';
import { ProjectAssumptionSummaryReport } from '../model/projectAssumptionSummaryReport';
import { ProjectCheckoutInfo } from '../model/projectCheckoutInfo';
import { ProjectCustomReportGroup } from '../model/projectCustomReportGroup';
import { ProjectCustomReportGroupNew } from '../model/projectCustomReportGroupNew';
import { ProjectDocumentImage } from '../model/projectDocumentImage';
import { ProjectEditStatus } from '../model/projectEditStatus';
import { ProjectFactorEvidenceReport } from '../model/projectFactorEvidenceReport';
import { ProjectFactorGoal } from '../model/projectFactorGoal';
import { ProjectFactorGoalProgress } from '../model/projectFactorGoalProgress';
import { ProjectFactorIndicator } from '../model/projectFactorIndicator';
import { ProjectFactorObjective } from '../model/projectFactorObjective';
import { ProjectFactorObjectiveProgress } from '../model/projectFactorObjectiveProgress';
import { ProjectFactorSummaryReport } from '../model/projectFactorSummaryReport';
import { ProjectFactorTaxonomyClassificationsReport } from '../model/projectFactorTaxonomyClassificationsReport';
import { ProjectFeature } from '../model/projectFeature';
import { ProjectFileInfo } from '../model/projectFileInfo';
import { ProjectGoalObjectiveAssessmentReport } from '../model/projectGoalObjectiveAssessmentReport';
import { ProjectGoalObjectiveFactorProgressReport } from '../model/projectGoalObjectiveFactorProgressReport';
import { ProjectImportStatus } from '../model/projectImportStatus';
import { ProjectLocation } from '../model/projectLocation';
import { ProjectMap } from '../model/projectMap';
import { ProjectMonitoringPlan } from '../model/projectMonitoringPlan';
import { ProjectOwner } from '../model/projectOwner';
import { ProjectOwnerUpdate } from '../model/projectOwnerUpdate';
import { ProjectProgress } from '../model/projectProgress';
import { ProjectPublishStatus } from '../model/projectPublishStatus';
import { ProjectReportDocument } from '../model/projectReportDocument';
import { ProjectResource } from '../model/projectResource';
import { ProjectResourceNew } from '../model/projectResourceNew';
import { ProjectRestoreInfo } from '../model/projectRestoreInfo';
import { ProjectResults } from '../model/projectResults';
import { ProjectResultsProgressReport } from '../model/projectResultsProgressReport';
import { ProjectResultsTracking } from '../model/projectResultsTracking';
import { ProjectScope } from '../model/projectScope';
import { ProjectScopeBiodiversityFeatures } from '../model/projectScopeBiodiversityFeatures';
import { ProjectScopeHumanStakeholders } from '../model/projectScopeHumanStakeholders';
import { ProjectScopeProtectedAreaInformation } from '../model/projectScopeProtectedAreaInformation';
import { ProjectSnapshot } from '../model/projectSnapshot';
import { ProjectSnapshotNew } from '../model/projectSnapshotNew';
import { ProjectStrategyActivity } from '../model/projectStrategyActivity';
import { ProjectStrategyActivityProgressReport } from '../model/projectStrategyActivityProgressReport';
import { ProjectStrategyExpense } from '../model/projectStrategyExpense';
import { ProjectStrategyObjective } from '../model/projectStrategyObjective';
import { ProjectSummary } from '../model/projectSummary';
import { ProjectSummaryUpdate } from '../model/projectSummaryUpdate';
import { ProjectTargetKeyEcologicalAttribute } from '../model/projectTargetKeyEcologicalAttribute';
import { ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications } from '../model/projectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications';
import { ProjectTargetThreatRatingsReport } from '../model/projectTargetThreatRatingsReport';
import { ProjectTeamMember } from '../model/projectTeamMember';
import { ProjectTeamMemberNew } from '../model/projectTeamMemberNew';
import { ProjectTeamMemberUpdate } from '../model/projectTeamMemberUpdate';
import { ProjectTeamMessage } from '../model/projectTeamMessage';
import { ProjectTimeframe } from '../model/projectTimeframe';
import { ProjectTimeframeNew } from '../model/projectTimeframeNew';
import { ProjectTimeline } from '../model/projectTimeline';
import { ProjectViabilityAssessmentReport } from '../model/projectViabilityAssessmentReport';
import { ProjectViabilityMonitoring } from '../model/projectViabilityMonitoring';
import { ProjectWithFactorIndicatorMeasurements } from '../model/projectWithFactorIndicatorMeasurements';
import { ProjectWithFactorIndicatorTaxonomyClassifications } from '../model/projectWithFactorIndicatorTaxonomyClassifications';
import { ProjectWorkPlanReport } from '../model/projectWorkPlanReport';
import { ResourceIdentifierList } from '../model/resourceIdentifierList';
import { ResultReport } from '../model/resultReport';
import { ResultReportNew } from '../model/resultReportNew';
import { SlideShowImage } from '../model/slideShowImage';
import { SmartGoal } from '../model/smartGoal';
import { SmartGoalNew } from '../model/smartGoalNew';
import { SmartObjective } from '../model/smartObjective';
import { SmartObjectiveNew } from '../model/smartObjectiveNew';
import { Strategy } from '../model/strategy';
import { StrategyActivity } from '../model/strategyActivity';
import { StrategyActivityNew } from '../model/strategyActivityNew';
import { StrategyActivityRelevantFactors } from '../model/strategyActivityRelevantFactors';
import { StrategyFeasibilityRatingEnum } from '../model/strategyFeasibilityRatingEnum';
import { StrategyImpactRatingEnum } from '../model/strategyImpactRatingEnum';
import { StrategyNew } from '../model/strategyNew';
import { StrategyRating } from '../model/strategyRating';
import { StrategyRelevantFactors } from '../model/strategyRelevantFactors';
import { Stress } from '../model/stress';
import { StressNew } from '../model/stressNew';
import { StressRating } from '../model/stressRating';
import { StressScopeRatingEnum } from '../model/stressScopeRatingEnum';
import { StressSeverityRatingEnum } from '../model/stressSeverityRatingEnum';
import { TargetSimpleThreatRating } from '../model/targetSimpleThreatRating';
import { ThreatRatingMode } from '../model/threatRatingMode';
import { ThreatRatings } from '../model/threatRatings';
import { ThreatReductionResult } from '../model/threatReductionResult';
import { ThreatReductionResultNew } from '../model/threatReductionResultNew';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

import { CaseUtils } from 'app/shared/utils';



@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

    protected basePath = 'http://localhost/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.configuration.withCredentials = true;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    public cancelCheckoutProjectRequiredPermission() {
        return 'CancelCheckout' || '';
    }

    /**
     * Cancel check-out of project file
     * Cancel check-out of Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelCheckoutProject(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public cancelCheckoutProject(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public cancelCheckoutProject(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public cancelCheckoutProject(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling cancelCheckoutProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/projectcancelcheckout/${encodeURIComponent(String(projectIdentifier))}`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public checkinProjectRequiredPermission() {
        return 'CheckIn' || '';
    }

    /**
     * Check-in project file
     * Check-in Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectFileBytes Project file bytes (.xmpz2 format)
     * @param checkinMessage Check-in message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkinProject(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public checkinProject(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public checkinProject(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public checkinProject(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling checkinProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (projectFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('project_file_bytes', <any>projectFileBytes) as any || localVarFormParams;
        }
        if (checkinMessage !== undefined) {
            localVarFormParams = localVarFormParams.append('checkin_message', <any>checkinMessage) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/projectcheckin/${encodeURIComponent(String(projectIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public checkoutProjectRequiredPermission() {
        return 'CheckOut' || '';
    }

    /**
     * Check-out project file
     * Check-out Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectCheckoutInfo Check-out info for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkoutProject(projectIdentifier: string, projectCheckoutInfo: ProjectCheckoutInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<Blob>;
    public checkoutProject(projectIdentifier: string, projectCheckoutInfo: ProjectCheckoutInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public checkoutProject(projectIdentifier: string, projectCheckoutInfo: ProjectCheckoutInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public checkoutProject(projectIdentifier: string, projectCheckoutInfo: ProjectCheckoutInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling checkoutProject.');
        }
        if (projectCheckoutInfo === null || projectCheckoutInfo === undefined) {
            throw new Error('Required parameter projectCheckoutInfo was null or undefined when calling checkoutProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'multipart/form-data',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/projectcheckout/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectCheckoutInfo),
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Blob(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Blob(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public contactProjectTeamRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Contact Project team
     * Contact Project team
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectTeamMessage Message for Project team
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactProjectTeam(projectIdentifier: string, projectTeamMessage: ProjectTeamMessage, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public contactProjectTeam(projectIdentifier: string, projectTeamMessage: ProjectTeamMessage, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public contactProjectTeam(projectIdentifier: string, projectTeamMessage: ProjectTeamMessage, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public contactProjectTeam(projectIdentifier: string, projectTeamMessage: ProjectTeamMessage, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling contactProjectTeam.');
        }
        if (projectTeamMessage === null || projectTeamMessage === undefined) {
            throw new Error('Required parameter projectTeamMessage was null or undefined when calling contactProjectTeam.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/projectcontactteam/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectTeamMessage),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public copyProjectFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Copy project factor
     * Copy (child) factor to new parent
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param factorResourceIdentifier Factor Unique identifier
     * @param parentFactorInfo New parent info for copied child factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ChildFactor>;
    public copyProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ChildFactor>>;
    public copyProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ChildFactor>>;
    public copyProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling copyProjectFactor.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling copyProjectFactor.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling copyProjectFactor.');
        }
        if (parentFactorInfo === null || parentFactorInfo === undefined) {
            throw new Error('Required parameter parentFactorInfo was null or undefined when calling copyProjectFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ChildFactor>(`${this.configuration.basePath}/projectfactorcopy/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}/${encodeURIComponent(String(factorResourceIdentifier))}`,
            CaseUtils.toSnake(parentFactorInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ChildFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ChildFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createActivityOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Activity output
     * Add new output for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param outputNew New output for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputNew: OutputNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Output>;
    public createActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputNew: OutputNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Output>>;
    public createActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputNew: OutputNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Output>>;
    public createActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputNew: OutputNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createActivityOutput.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createActivityOutput.');
        }
        if (outputNew === null || outputNew === undefined) {
            throw new Error('Required parameter outputNew was null or undefined when calling createActivityOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Output>(`${this.configuration.basePath}/activityoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(outputNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createActivityTaskRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Activity task
     * Add new task for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param activityTaskNew New task for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityTask(projectIdentifier: string, activityResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ActivityTask>;
    public createActivityTask(projectIdentifier: string, activityResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ActivityTask>>;
    public createActivityTask(projectIdentifier: string, activityResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ActivityTask>>;
    public createActivityTask(projectIdentifier: string, activityResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createActivityTask.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createActivityTask.');
        }
        if (activityTaskNew === null || activityTaskNew === undefined) {
            throw new Error('Required parameter activityTaskNew was null or undefined when calling createActivityTask.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ActivityTask>(`${this.configuration.basePath}/activitytasks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(activityTaskNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ActivityTask(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ActivityTask(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createActivityTaskExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Task expense assignment
     * Add new expense to Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param factorExpenseNew New expense assignment for Task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpense>;
    public createActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpense>>;
    public createActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpense>>;
    public createActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createActivityTaskExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createActivityTaskExpense.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling createActivityTaskExpense.');
        }
        if (factorExpenseNew === null || factorExpenseNew === undefined) {
            throw new Error('Required parameter factorExpenseNew was null or undefined when calling createActivityTaskExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorExpense>(`${this.configuration.basePath}/activitytaskexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            CaseUtils.toSnake(factorExpenseNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createActivityTaskExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Task expense assignment detail
     * Add new expense to Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param factorExpenseDetailNew New expense detail for Task expense assignment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpenseDetail>;
    public createActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpenseDetail>>;
    public createActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpenseDetail>>;
    public createActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createActivityTaskExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createActivityTaskExpenseDetail.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling createActivityTaskExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling createActivityTaskExpenseDetail.');
        }
        if (factorExpenseDetailNew === null || factorExpenseDetailNew === undefined) {
            throw new Error('Required parameter factorExpenseDetailNew was null or undefined when calling createActivityTaskExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorExpenseDetail>(`${this.configuration.basePath}/activitytaskexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            CaseUtils.toSnake(factorExpenseDetailNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createActivityTaskOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Task output
     * Add new output for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param outputNew New output for Task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputNew: OutputNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Output>;
    public createActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputNew: OutputNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Output>>;
    public createActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputNew: OutputNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Output>>;
    public createActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputNew: OutputNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createActivityTaskOutput.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling createActivityTaskOutput.');
        }
        if (outputNew === null || outputNew === undefined) {
            throw new Error('Required parameter outputNew was null or undefined when calling createActivityTaskOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Output>(`${this.configuration.basePath}/activitytaskoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            CaseUtils.toSnake(outputNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createActivityTaskResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Task project resource assignment
     * Assign new project resource to Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param factorResourceIdentifier New project resource assignment for Task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorProjectResource>;
    public createActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorProjectResource>>;
    public createActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorProjectResource>>;
    public createActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createActivityTaskResource.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createActivityTaskResource.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling createActivityTaskResource.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling createActivityTaskResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorProjectResource>(`${this.configuration.basePath}/activitytaskresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            CaseUtils.toSnake(factorResourceIdentifier),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiodiversityTargetGoalRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biodiversity Target Goal
     * Add new Goal for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalNew New Goal for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Goal>;
    public createBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Goal>>;
    public createBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Goal>>;
    public createBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiodiversityTargetGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiodiversityTargetGoal.');
        }
        if (goalNew === null || goalNew === undefined) {
            throw new Error('Required parameter goalNew was null or undefined when calling createBiodiversityTargetGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Goal>(`${this.configuration.basePath}/biodiversitytargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(goalNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Goal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Goal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiodiversityTargetIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biodiversity Target Indicator
     * Add new Indicator for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New Indicator for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiodiversityTargetIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiodiversityTargetIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createBiodiversityTargetIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/biodiversitytargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiodiversityTargetKeyEcologicalAttributeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biodiversity Target Key Ecological Attribute
     * Add new Key Ecological Attibute for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeNew New Key Ecological Attibute for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<KeyEcologicalAttribute>;
    public createBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<KeyEcologicalAttribute>>;
    public createBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<KeyEcologicalAttribute>>;
    public createBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeNew === null || keyEcologicalAttributeNew === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeNew was null or undefined when calling createBiodiversityTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<KeyEcologicalAttribute>(`${this.configuration.basePath}/biodiversitytargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(keyEcologicalAttributeNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new KeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new KeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiodiversityTargetNestedTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biodiversity Target Nested Target
     * Add new Nested Target for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetNew New Nested Target for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<NestedTarget>;
    public createBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<NestedTarget>>;
    public createBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<NestedTarget>>;
    public createBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiodiversityTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiodiversityTargetNestedTarget.');
        }
        if (nestedTargetNew === null || nestedTargetNew === undefined) {
            throw new Error('Required parameter nestedTargetNew was null or undefined when calling createBiodiversityTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<NestedTarget>(`${this.configuration.basePath}/biodiversitytargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(nestedTargetNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new NestedTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new NestedTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiodiversityTargetStressRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biodiversity Target Stress
     * Add new Stress for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressNew New Stress for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Stress>;
    public createBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Stress>>;
    public createBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Stress>>;
    public createBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiodiversityTargetStress.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiodiversityTargetStress.');
        }
        if (stressNew === null || stressNew === undefined) {
            throw new Error('Required parameter stressNew was null or undefined when calling createBiodiversityTargetStress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Stress>(`${this.configuration.basePath}/biodiversitytargetstresses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(stressNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Stress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Stress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiophysicalFactorIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biophysical Factor indicator
     * Add new indicator for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New indicator for Biophysical Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiophysicalFactorIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiophysicalFactorIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createBiophysicalFactorIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/biophysicalfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiophysicalFactorObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biophysical Factor objective
     * Add new objective for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew New objective for Biophysical Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public createBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public createBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public createBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiophysicalFactorObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiophysicalFactorObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling createBiophysicalFactorObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Objective>(`${this.configuration.basePath}/biophysicalfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiophysicalResultIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biophysical Result indicator
     * Add new indicator for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New indicator for Biophysical Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiophysicalResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiophysicalResultIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createBiophysicalResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/biophysicalresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createBiophysicalResultObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Biophysical Result objective
     * Add new objective for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew New objective for Biophysical Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public createBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public createBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public createBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createBiophysicalResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createBiophysicalResultObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling createBiophysicalResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Objective>(`${this.configuration.basePath}/biophysicalresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createContributingFactorIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Contributing Factor indicator
     * Add new indicator for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New indicator for Contributing Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createContributingFactorIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createContributingFactorIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createContributingFactorIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/contributingfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createContributingFactorObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Contributing Factor objective
     * Add new objective for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew New objective for Contributing Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public createContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public createContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public createContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createContributingFactorObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createContributingFactorObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling createContributingFactorObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Objective>(`${this.configuration.basePath}/contributingfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createDirectThreatIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Direct Threat indicator
     * Add new indicator for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New indicator for Direct Threat
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createDirectThreatIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createDirectThreatIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createDirectThreatIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/directthreatindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createDirectThreatObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Direct Threat objective
     * Add new objective for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew New objective for Direct Threat
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public createDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public createDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public createDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createDirectThreatObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createDirectThreatObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling createDirectThreatObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Objective>(`${this.configuration.basePath}/directthreatobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createFactorIndicatorScorecardRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Indicator scorecard for factor
     * Add new Indicator scorecard for Factor (Goal / Objective)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorScorecardNew New scorecard for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorScorecard>;
    public createFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorScorecard>>;
    public createFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorScorecard>>;
    public createFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createFactorIndicatorScorecard.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createFactorIndicatorScorecard.');
        }
        if (indicatorScorecardNew === null || indicatorScorecardNew === undefined) {
            throw new Error('Required parameter indicatorScorecardNew was null or undefined when calling createFactorIndicatorScorecard.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<IndicatorScorecard>(`${this.configuration.basePath}/indicatorscorecards/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorScorecardNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorScorecard(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorScorecard(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createFavoriteProjectRequiredPermission() {
        return '' || '';
    }

    /**
     * Create Project favorite
     * Add new user favorite for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFavoriteProject(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public createFavoriteProject(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public createFavoriteProject(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public createFavoriteProject(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createFavoriteProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/favoriteprojects/${encodeURIComponent(String(projectIdentifier))}`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createGoalProgressPercentRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Goal progress percent
     * Add new progress percent for Goal
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentNew New progress percent for Goal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressPercent>;
    public createGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressPercent>>;
    public createGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressPercent>>;
    public createGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createGoalProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createGoalProgressPercent.');
        }
        if (progressPercentNew === null || progressPercentNew === undefined) {
            throw new Error('Required parameter progressPercentNew was null or undefined when calling createGoalProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgressPercent>(`${this.configuration.basePath}/goalprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(progressPercentNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressPercent(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressPercent(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createHumanWellbeingTargetGoalRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create human wellbeing target Goal
     * Add new Goal for human wellbeing target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalNew New Goal for human wellbeing target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Goal>;
    public createHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Goal>>;
    public createHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Goal>>;
    public createHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createHumanWellbeingTargetGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createHumanWellbeingTargetGoal.');
        }
        if (goalNew === null || goalNew === undefined) {
            throw new Error('Required parameter goalNew was null or undefined when calling createHumanWellbeingTargetGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Goal>(`${this.configuration.basePath}/humanwellbeingtargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(goalNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Goal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Goal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createHumanWellbeingTargetIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create human wellbeing target Indicator
     * Add new Indicator for human wellbeing target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New Indicator for human wellbeing target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createHumanWellbeingTargetIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createHumanWellbeingTargetIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createHumanWellbeingTargetIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/humanwellbeingtargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createHumanWellbeingTargetKeyEcologicalAttributeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Human Wellbeing Target Key Ecological Attribute
     * Add new Key Ecological Attibute for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeNew New Key Ecological Attibute for Human Wellbeing Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<KeyEcologicalAttribute>;
    public createHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<KeyEcologicalAttribute>>;
    public createHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<KeyEcologicalAttribute>>;
    public createHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeNew === null || keyEcologicalAttributeNew === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeNew was null or undefined when calling createHumanWellbeingTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<KeyEcologicalAttribute>(`${this.configuration.basePath}/humanwellbeingtargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(keyEcologicalAttributeNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new KeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new KeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createHumanWellbeingTargetNestedTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Human Wellbeing Target Nested Target
     * Add new Nested Target for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetNew New Nested Target for Human Wellbeing Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<NestedTarget>;
    public createHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<NestedTarget>>;
    public createHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<NestedTarget>>;
    public createHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createHumanWellbeingTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createHumanWellbeingTargetNestedTarget.');
        }
        if (nestedTargetNew === null || nestedTargetNew === undefined) {
            throw new Error('Required parameter nestedTargetNew was null or undefined when calling createHumanWellbeingTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<NestedTarget>(`${this.configuration.basePath}/humanwellbeingtargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(nestedTargetNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new NestedTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new NestedTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createIndicatorFutureStatusRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Indicator future status
     * Add new future status for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorFutureStatusNew New future status for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorFutureStatus>;
    public createIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorFutureStatus>>;
    public createIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorFutureStatus>>;
    public createIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createIndicatorFutureStatus.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createIndicatorFutureStatus.');
        }
        if (indicatorFutureStatusNew === null || indicatorFutureStatusNew === undefined) {
            throw new Error('Required parameter indicatorFutureStatusNew was null or undefined when calling createIndicatorFutureStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<IndicatorFutureStatus>(`${this.configuration.basePath}/indicatorfuturestatuses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorFutureStatusNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorFutureStatus(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorFutureStatus(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createIndicatorMeasurementRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Indicator measurement
     * Add new measurement for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorMeasurementNew New measurement for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorMeasurement>;
    public createIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorMeasurement>>;
    public createIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorMeasurement>>;
    public createIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createIndicatorMeasurement.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createIndicatorMeasurement.');
        }
        if (indicatorMeasurementNew === null || indicatorMeasurementNew === undefined) {
            throw new Error('Required parameter indicatorMeasurementNew was null or undefined when calling createIndicatorMeasurement.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<IndicatorMeasurement>(`${this.configuration.basePath}/indicatormeasurements/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorMeasurementNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorMeasurement(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorMeasurement(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createIndicatorMethodRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Indicator method
     * Add new method for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorMethodNew New method for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorMethod>;
    public createIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorMethod>>;
    public createIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorMethod>>;
    public createIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createIndicatorMethod.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createIndicatorMethod.');
        }
        if (indicatorMethodNew === null || indicatorMethodNew === undefined) {
            throw new Error('Required parameter indicatorMethodNew was null or undefined when calling createIndicatorMethod.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<IndicatorMethod>(`${this.configuration.basePath}/indicatormethods/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorMethodNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorMethod(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorMethod(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createIntermediateResultIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Intermediate Result indicator
     * Add new indicator for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New indicator for Intermediate Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createIntermediateResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createIntermediateResultIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createIntermediateResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/intermediateresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createIntermediateResultObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Intermediate Result objective
     * Add new objective for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew New objective for Intermediate Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public createIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public createIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public createIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createIntermediateResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createIntermediateResultObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling createIntermediateResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Objective>(`${this.configuration.basePath}/intermediateresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createKeyEcologicalAttributeIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create key ecological attribute Indicator
     * Add new Indicator for key ecological attribute
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New Indicator for key ecological attribute
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createKeyEcologicalAttributeIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createKeyEcologicalAttributeIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createKeyEcologicalAttributeIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/keyecologicalattributeindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createObjectiveProgressPercentRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Objective progress percent
     * Add new progress percent for Objective
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentNew New progress percent for Objective
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressPercent>;
    public createObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressPercent>>;
    public createObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressPercent>>;
    public createObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createObjectiveProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createObjectiveProgressPercent.');
        }
        if (progressPercentNew === null || progressPercentNew === undefined) {
            throw new Error('Required parameter progressPercentNew was null or undefined when calling createObjectiveProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgressPercent>(`${this.configuration.basePath}/objectiveprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(progressPercentNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressPercent(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressPercent(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectRequiredPermission() {
        return '' || 'CreateProject';
    }

    /**
     * Create new Project
     * Create new Project
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param name Project name
     * @param projectFileBytes Project file bytes (.xmpz2 format)
     * @param programTemplateIdentifier Program Template identifier
     * @param projectIdentifier Project identifier (for copy)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProject(programIdentifier: string, name?: string, projectFileBytes?: Blob, programTemplateIdentifier?: string, projectIdentifier?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Project>;
    public createProject(programIdentifier: string, name?: string, projectFileBytes?: Blob, programTemplateIdentifier?: string, projectIdentifier?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Project>>;
    public createProject(programIdentifier: string, name?: string, projectFileBytes?: Blob, programTemplateIdentifier?: string, projectIdentifier?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Project>>;
    public createProject(programIdentifier: string, name?: string, projectFileBytes?: Blob, programTemplateIdentifier?: string, projectIdentifier?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (projectFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('project_file_bytes', <any>projectFileBytes) as any || localVarFormParams;
        }
        if (programTemplateIdentifier !== undefined) {
            localVarFormParams = localVarFormParams.append('program_template_identifier', <any>programTemplateIdentifier) as any || localVarFormParams;
        }
        if (projectIdentifier !== undefined) {
            localVarFormParams = localVarFormParams.append('project_identifier', <any>projectIdentifier) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Project>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(programIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Project(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Project(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectAssociatedFileRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project associated file
     * Add new associated file for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param fileBytesList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectAssociatedFile(projectIdentifier: string, fileBytesList?: Array<Blob>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssociatedFile>>;
    public createProjectAssociatedFile(projectIdentifier: string, fileBytesList?: Array<Blob>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssociatedFile>>>;
    public createProjectAssociatedFile(projectIdentifier: string, fileBytesList?: Array<Blob>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssociatedFile>>>;
    public createProjectAssociatedFile(projectIdentifier: string, fileBytesList?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectAssociatedFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (fileBytesList) {
            if (localVarUseForm) {
                fileBytesList.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('file_bytes_list', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('file_bytes_list', fileBytesList.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<AssociatedFile>>(`${this.configuration.basePath}/projectassociatedfiles/${encodeURIComponent(String(projectIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedFile(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedFile(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectAssociatedLinkRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project associated link
     * Add new associated link for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param associatedLinkNew New associated link for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectAssociatedLink(projectIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssociatedLink>;
    public createProjectAssociatedLink(projectIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssociatedLink>>;
    public createProjectAssociatedLink(projectIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssociatedLink>>;
    public createProjectAssociatedLink(projectIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectAssociatedLink.');
        }
        if (associatedLinkNew === null || associatedLinkNew === undefined) {
            throw new Error('Required parameter associatedLinkNew was null or undefined when calling createProjectAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<AssociatedLink>(`${this.configuration.basePath}/projectassociatedlinks/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(associatedLinkNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectCustomReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project custom report
     * Add new custom report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param customReportNew New custom report for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectCustomReport(projectIdentifier: string, customReportNew: CustomReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CustomReport>;
    public createProjectCustomReport(projectIdentifier: string, customReportNew: CustomReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CustomReport>>;
    public createProjectCustomReport(projectIdentifier: string, customReportNew: CustomReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CustomReport>>;
    public createProjectCustomReport(projectIdentifier: string, customReportNew: CustomReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectCustomReport.');
        }
        if (customReportNew === null || customReportNew === undefined) {
            throw new Error('Required parameter customReportNew was null or undefined when calling createProjectCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CustomReport>(`${this.configuration.basePath}/projectcustomreports/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(customReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new CustomReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new CustomReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectCustomReportGroupRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project custom report group
     * Add new custom report group for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectCustomReportGroupNew New custom report group for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectCustomReportGroup(projectIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectCustomReportGroup>;
    public createProjectCustomReportGroup(projectIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectCustomReportGroup>>;
    public createProjectCustomReportGroup(projectIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectCustomReportGroup>>;
    public createProjectCustomReportGroup(projectIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectCustomReportGroup.');
        }
        if (projectCustomReportGroupNew === null || projectCustomReportGroupNew === undefined) {
            throw new Error('Required parameter projectCustomReportGroupNew was null or undefined when calling createProjectCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectCustomReportGroup>(`${this.configuration.basePath}/projectcustomreportgroups/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectCustomReportGroupNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectCustomReportGroup(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectCustomReportGroup(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectDocumentImageRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project document image
     * Add new project document image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param imageFileBytes Image file bytes (supported content types are png, jpeg, gif, bmp and tif. Max file size allowed is 15MB. All images will be converted to .png.)
     * @param documentReference Document reference
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectDocumentImage(projectIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDocumentImage>;
    public createProjectDocumentImage(projectIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDocumentImage>>;
    public createProjectDocumentImage(projectIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDocumentImage>>;
    public createProjectDocumentImage(projectIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectDocumentImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (imageFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('image_file_bytes', <any>imageFileBytes) as any || localVarFormParams;
        }
        if (documentReference !== undefined) {
            localVarFormParams = localVarFormParams.append('document_reference', <any>documentReference) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDocumentImage>(`${this.configuration.basePath}/projectdocumentimages/${encodeURIComponent(String(projectIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDocumentImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDocumentImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectFactorProgressReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project factor progress report
     * Add new progress report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressReportNew New progress report for Project factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressReport>;
    public createProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressReport>>;
    public createProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressReport>>;
    public createProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectFactorProgressReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createProjectFactorProgressReport.');
        }
        if (progressReportNew === null || progressReportNew === undefined) {
            throw new Error('Required parameter progressReportNew was null or undefined when calling createProjectFactorProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgressReport>(`${this.configuration.basePath}/projectfactorprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(progressReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectFactorResultReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project factor result report
     * Add new result report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param resultReportNew New result report for Project factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportNew: ResultReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ResultReport>;
    public createProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportNew: ResultReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ResultReport>>;
    public createProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportNew: ResultReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ResultReport>>;
    public createProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportNew: ResultReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectFactorResultReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createProjectFactorResultReport.');
        }
        if (resultReportNew === null || resultReportNew === undefined) {
            throw new Error('Required parameter resultReportNew was null or undefined when calling createProjectFactorResultReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ResultReport>(`${this.configuration.basePath}/projectfactorresultreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(resultReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ResultReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ResultReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectProgressReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project progress report
     * Add new project progress report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param progressReportNew New progress report for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectProgressReport(projectIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressReport>;
    public createProjectProgressReport(projectIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressReport>>;
    public createProjectProgressReport(projectIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressReport>>;
    public createProjectProgressReport(projectIdentifier: string, progressReportNew: ProgressReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectProgressReport.');
        }
        if (progressReportNew === null || progressReportNew === undefined) {
            throw new Error('Required parameter progressReportNew was null or undefined when calling createProjectProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgressReport>(`${this.configuration.basePath}/projectprogressreports/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(progressReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project resource
     * Add new project resource for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectResourceNew New resource for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectResource(projectIdentifier: string, projectResourceNew: ProjectResourceNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResource>;
    public createProjectResource(projectIdentifier: string, projectResourceNew: ProjectResourceNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResource>>;
    public createProjectResource(projectIdentifier: string, projectResourceNew: ProjectResourceNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResource>>;
    public createProjectResource(projectIdentifier: string, projectResourceNew: ProjectResourceNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectResource.');
        }
        if (projectResourceNew === null || projectResourceNew === undefined) {
            throw new Error('Required parameter projectResourceNew was null or undefined when calling createProjectResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectResource>(`${this.configuration.basePath}/projectresources/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectResourceNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectSlideshowImageRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project slideshow image
     * Add new project slideshow image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param imageFileBytes Image file bytes (supported content types are png, jpeg, gif, bmp and tif. Max file size allowed is 3MB. All images will be converted to .png.)
     * @param caption Image caption
     * @param imageOrder Image order - defaults to next in sequence if not supplied
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectSlideshowImage(projectIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SlideShowImage>;
    public createProjectSlideshowImage(projectIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SlideShowImage>>;
    public createProjectSlideshowImage(projectIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SlideShowImage>>;
    public createProjectSlideshowImage(projectIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (imageFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('image_file_bytes', <any>imageFileBytes) as any || localVarFormParams;
        }
        if (caption !== undefined) {
            localVarFormParams = localVarFormParams.append('caption', <any>caption) as any || localVarFormParams;
        }
        if (imageOrder !== undefined) {
            localVarFormParams = localVarFormParams.append('image_order', <any>imageOrder) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SlideShowImage>(`${this.configuration.basePath}/projectslideshowimages/${encodeURIComponent(String(projectIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SlideShowImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SlideShowImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectSnapshotRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Project snapshot
     * Add new snapshot for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectSnapshotNew Snapshot info for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectSnapshot(projectIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectSnapshot>;
    public createProjectSnapshot(projectIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectSnapshot>>;
    public createProjectSnapshot(projectIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectSnapshot>>;
    public createProjectSnapshot(projectIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectSnapshot.');
        }
        if (projectSnapshotNew === null || projectSnapshotNew === undefined) {
            throw new Error('Required parameter projectSnapshotNew was null or undefined when calling createProjectSnapshot.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectSnapshot>(`${this.configuration.basePath}/projectsnapshots/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectSnapshotNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectSnapshot(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectSnapshot(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectTeamMemberRequiredPermission() {
        return 'AddTeamMember' || '';
    }

    /**
     * Create Project team member
     * Add new team member for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectTeamMemberNew New team member for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectTeamMember(projectIdentifier: string, projectTeamMemberNew: ProjectTeamMemberNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectTeamMember>;
    public createProjectTeamMember(projectIdentifier: string, projectTeamMemberNew: ProjectTeamMemberNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectTeamMember>>;
    public createProjectTeamMember(projectIdentifier: string, projectTeamMemberNew: ProjectTeamMemberNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectTeamMember>>;
    public createProjectTeamMember(projectIdentifier: string, projectTeamMemberNew: ProjectTeamMemberNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectTeamMember.');
        }
        if (projectTeamMemberNew === null || projectTeamMemberNew === undefined) {
            throw new Error('Required parameter projectTeamMemberNew was null or undefined when calling createProjectTeamMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectTeamMember>(`${this.configuration.basePath}/projectteammembers/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectTeamMemberNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTeamMember(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTeamMember(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createSmartGoalRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create SMART goal
     * Add new SMART goal for specified factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param factorResourceIdentifier Factor Unique identifier
     * @param smartGoalNew New SMART goal for specific factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSmartGoal(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartGoalNew: SmartGoalNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SmartGoal>;
    public createSmartGoal(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartGoalNew: SmartGoalNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SmartGoal>>;
    public createSmartGoal(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartGoalNew: SmartGoalNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SmartGoal>>;
    public createSmartGoal(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartGoalNew: SmartGoalNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createSmartGoal.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling createSmartGoal.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling createSmartGoal.');
        }
        if (smartGoalNew === null || smartGoalNew === undefined) {
            throw new Error('Required parameter smartGoalNew was null or undefined when calling createSmartGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SmartGoal>(`${this.configuration.basePath}/smartgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}/${encodeURIComponent(String(factorResourceIdentifier))}`,
            CaseUtils.toSnake(smartGoalNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SmartGoal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SmartGoal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createSmartObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create SMART objective
     * Add new SMART objective for specified factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param factorResourceIdentifier Factor Unique identifier
     * @param smartObjectiveNew New SMART objective for specific factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSmartObjective(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartObjectiveNew: SmartObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SmartObjective>;
    public createSmartObjective(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartObjectiveNew: SmartObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SmartObjective>>;
    public createSmartObjective(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartObjectiveNew: SmartObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SmartObjective>>;
    public createSmartObjective(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, smartObjectiveNew: SmartObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createSmartObjective.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling createSmartObjective.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling createSmartObjective.');
        }
        if (smartObjectiveNew === null || smartObjectiveNew === undefined) {
            throw new Error('Required parameter smartObjectiveNew was null or undefined when calling createSmartObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SmartObjective>(`${this.configuration.basePath}/smartobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}/${encodeURIComponent(String(factorResourceIdentifier))}`,
            CaseUtils.toSnake(smartObjectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SmartObjective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SmartObjective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyActivityRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Strategy activity
     * Add new activity for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param strategyActivityNew New activity for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyActivity(projectIdentifier: string, resourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StrategyActivity>;
    public createStrategyActivity(projectIdentifier: string, resourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StrategyActivity>>;
    public createStrategyActivity(projectIdentifier: string, resourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StrategyActivity>>;
    public createStrategyActivity(projectIdentifier: string, resourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyActivity.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyActivity.');
        }
        if (strategyActivityNew === null || strategyActivityNew === undefined) {
            throw new Error('Required parameter strategyActivityNew was null or undefined when calling createStrategyActivity.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<StrategyActivity>(`${this.configuration.basePath}/strategyactivities/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(strategyActivityNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyActivity(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyActivity(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyActivityExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Activity expense assignment
     * Add new expense to Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param factorExpenseNew New expense assignment for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpense>;
    public createStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpense>>;
    public createStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpense>>;
    public createStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyActivityExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyActivityExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createStrategyActivityExpense.');
        }
        if (factorExpenseNew === null || factorExpenseNew === undefined) {
            throw new Error('Required parameter factorExpenseNew was null or undefined when calling createStrategyActivityExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorExpense>(`${this.configuration.basePath}/strategyactivityexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(factorExpenseNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyActivityExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Activity expense assignment detail
     * Add new expense to Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param factorExpenseDetailNew New expense detail for Activity expense assignment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpenseDetail>;
    public createStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpenseDetail>>;
    public createStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpenseDetail>>;
    public createStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyActivityExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyActivityExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createStrategyActivityExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling createStrategyActivityExpenseDetail.');
        }
        if (factorExpenseDetailNew === null || factorExpenseDetailNew === undefined) {
            throw new Error('Required parameter factorExpenseDetailNew was null or undefined when calling createStrategyActivityExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorExpenseDetail>(`${this.configuration.basePath}/strategyactivityexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            CaseUtils.toSnake(factorExpenseDetailNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyActivityResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Activity project resource assignment
     * Assign new project resource to Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param factorResourceIdentifier New project resource assignment for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorProjectResource>;
    public createStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorProjectResource>>;
    public createStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorProjectResource>>;
    public createStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyActivityResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyActivityResource.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createStrategyActivityResource.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling createStrategyActivityResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorProjectResource>(`${this.configuration.basePath}/strategyactivityresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(factorResourceIdentifier),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Strategy expense assignment
     * Add new expense to Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param factorExpenseNew New expense assignment for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyExpense(projectIdentifier: string, resourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpense>;
    public createStrategyExpense(projectIdentifier: string, resourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpense>>;
    public createStrategyExpense(projectIdentifier: string, resourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpense>>;
    public createStrategyExpense(projectIdentifier: string, resourceIdentifier: string, factorExpenseNew: FactorExpenseNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyExpense.');
        }
        if (factorExpenseNew === null || factorExpenseNew === undefined) {
            throw new Error('Required parameter factorExpenseNew was null or undefined when calling createStrategyExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorExpense>(`${this.configuration.basePath}/strategyexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(factorExpenseNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Strategy expense assignment detail
     * Add new expense details to Strategy expense
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param factorExpenseDetailNew New expense assignment detail for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpenseDetail>;
    public createStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpenseDetail>>;
    public createStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpenseDetail>>;
    public createStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseDetailNew: FactorExpenseDetailNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling createStrategyExpenseDetail.');
        }
        if (factorExpenseDetailNew === null || factorExpenseDetailNew === undefined) {
            throw new Error('Required parameter factorExpenseDetailNew was null or undefined when calling createStrategyExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorExpenseDetail>(`${this.configuration.basePath}/strategyexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            CaseUtils.toSnake(factorExpenseDetailNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Strategy indicator
     * Add new indicator for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New indicator for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createStrategyIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/strategyindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Strategy objective
     * Add new objective for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew New objective for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public createStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public createStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public createStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling createStrategyObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Objective>(`${this.configuration.basePath}/strategyobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Strategy output
     * Add new output for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param outputNew New output for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputNew: OutputNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Output>;
    public createStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputNew: OutputNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Output>>;
    public createStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputNew: OutputNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Output>>;
    public createStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputNew: OutputNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyOutput.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyOutput.');
        }
        if (outputNew === null || outputNew === undefined) {
            throw new Error('Required parameter outputNew was null or undefined when calling createStrategyOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Output>(`${this.configuration.basePath}/strategyoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(outputNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Strategy project resource assignment
     * Assign new project resource to Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param factorResourceIdentifier New project resource assignment for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyResource(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorProjectResource>;
    public createStrategyResource(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorProjectResource>>;
    public createStrategyResource(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorProjectResource>>;
    public createStrategyResource(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyResource.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling createStrategyResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FactorProjectResource>(`${this.configuration.basePath}/strategyresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(factorResourceIdentifier),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createThreatReductionResultIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Threat Reduction Result indicator
     * Add new indicator for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew New indicator for Threat Reduction Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public createThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public createThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public createThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createThreatReductionResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createThreatReductionResultIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling createThreatReductionResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Indicator>(`${this.configuration.basePath}/threatreductionresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createThreatReductionResultObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Threat Reduction Result objective
     * Add new objective for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew New objective for Threat Reduction Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public createThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public createThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public createThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createThreatReductionResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createThreatReductionResultObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling createThreatReductionResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Objective>(`${this.configuration.basePath}/threatreductionresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Activity output
     * Delete specific output for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityOutput.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteActivityOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling deleteActivityOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activityoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityTaskRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Activity task
     * Delete specific task for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityTask.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteActivityTask.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling deleteActivityTask.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activitytasks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityTaskExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Task expense assignment
     * Remove specific expense assignment for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityTaskExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteActivityTaskExpense.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling deleteActivityTaskExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling deleteActivityTaskExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activitytaskexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityTaskExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Task expense assignment detail
     * Remove specific expense detail from a Task expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityTaskExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteActivityTaskExpenseDetail.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling deleteActivityTaskExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling deleteActivityTaskExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling deleteActivityTaskExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activitytaskexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityTaskLeaderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Activity task leader (project resource)
     * Delete leader (project resource) for a specific Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityTaskLeader.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteActivityTaskLeader.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling deleteActivityTaskLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activitytaskleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityTaskOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Task output
     * Delete specific output for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityTaskOutput.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling deleteActivityTaskOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling deleteActivityTaskOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activitytaskoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityTaskResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Task project resource assignment
     * Remove project resource assigned to a specific Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param projectResourceIdentifier Project Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityTaskResource.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteActivityTaskResource.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling deleteActivityTaskResource.');
        }
        if (projectResourceIdentifier === null || projectResourceIdentifier === undefined) {
            throw new Error('Required parameter projectResourceIdentifier was null or undefined when calling deleteActivityTaskResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activitytaskresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(projectResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteActivityTaskTimeframeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Activity task timeframe
     * Delete timeframe for a specific Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteActivityTaskTimeframe.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteActivityTaskTimeframe.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling deleteActivityTaskTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activitytasktimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteBiodiversityTargetKeyEcologicalAttributeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Biodiversity Target Key Ecological Attribute
     * Delete specific Stress for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeResourceIdentifier Key Ecological Attribute Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeResourceIdentifier === null || keyEcologicalAttributeResourceIdentifier === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeResourceIdentifier was null or undefined when calling deleteBiodiversityTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/biodiversitytargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(keyEcologicalAttributeResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteBiodiversityTargetNestedTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Biodiversity Target Nested Target
     * Delete specific Nested Target for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetResourceIdentifier Nested Target Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteBiodiversityTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteBiodiversityTargetNestedTarget.');
        }
        if (nestedTargetResourceIdentifier === null || nestedTargetResourceIdentifier === undefined) {
            throw new Error('Required parameter nestedTargetResourceIdentifier was null or undefined when calling deleteBiodiversityTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/biodiversitytargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(nestedTargetResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteBiodiversityTargetStressRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Biodiversity Target Stress
     * Delete specific Stress for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressResourceIdentifier Stress Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteBiodiversityTargetStress.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteBiodiversityTargetStress.');
        }
        if (stressResourceIdentifier === null || stressResourceIdentifier === undefined) {
            throw new Error('Required parameter stressResourceIdentifier was null or undefined when calling deleteBiodiversityTargetStress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/biodiversitytargetstresses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(stressResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteFactorIndicatorScorecardRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Indicator scorecard for factor
     * Delete specific scorecard
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param scorecardResourceIdentifier Scorecard Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteFactorIndicatorScorecard.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteFactorIndicatorScorecard.');
        }
        if (scorecardResourceIdentifier === null || scorecardResourceIdentifier === undefined) {
            throw new Error('Required parameter scorecardResourceIdentifier was null or undefined when calling deleteFactorIndicatorScorecard.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/indicatorscorecards/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(scorecardResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteFavoriteProjectRequiredPermission() {
        return '' || '';
    }

    /**
     * Delete user favorite for Project
     * Delete user favorite for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFavoriteProject(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteFavoriteProject(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteFavoriteProject(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteFavoriteProject(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteFavoriteProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/favoriteprojects/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteGoalRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete goal
     * Delete specific goal for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGoal(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteGoal(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteGoal(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteGoal(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/goals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteGoalProgressPercentRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Goal progress percent
     * Delete specific progress percent for Goal
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteGoalProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteGoalProgressPercent.');
        }
        if (progressPercentResourceIdentifier === null || progressPercentResourceIdentifier === undefined) {
            throw new Error('Required parameter progressPercentResourceIdentifier was null or undefined when calling deleteGoalProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/goalprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressPercentResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteHumanWellbeingTargetKeyEcologicalAttributeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Human Wellbeing Target Key Ecological Attribute
     * Delete specific Stress for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeResourceIdentifier Key Ecological Attribute Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeResourceIdentifier === null || keyEcologicalAttributeResourceIdentifier === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeResourceIdentifier was null or undefined when calling deleteHumanWellbeingTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/humanwellbeingtargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(keyEcologicalAttributeResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteHumanWellbeingTargetNestedTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Human Wellbeing Target Nested Target
     * Delete specific Nested Target for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetResourceIdentifier Nested Target Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteHumanWellbeingTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteHumanWellbeingTargetNestedTarget.');
        }
        if (nestedTargetResourceIdentifier === null || nestedTargetResourceIdentifier === undefined) {
            throw new Error('Required parameter nestedTargetResourceIdentifier was null or undefined when calling deleteHumanWellbeingTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/humanwellbeingtargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(nestedTargetResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete indicator
     * Delete specific indicator for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIndicator(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteIndicator(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteIndicator(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteIndicator(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/indicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteIndicatorFutureStatusRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Indicator future status
     * Delete specific future status for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param futureStatusResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteIndicatorFutureStatus.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteIndicatorFutureStatus.');
        }
        if (futureStatusResourceIdentifier === null || futureStatusResourceIdentifier === undefined) {
            throw new Error('Required parameter futureStatusResourceIdentifier was null or undefined when calling deleteIndicatorFutureStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/indicatorfuturestatuses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(futureStatusResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteIndicatorMeasurementRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Indicator measurement
     * Delete specific measurement for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param measurementResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteIndicatorMeasurement.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteIndicatorMeasurement.');
        }
        if (measurementResourceIdentifier === null || measurementResourceIdentifier === undefined) {
            throw new Error('Required parameter measurementResourceIdentifier was null or undefined when calling deleteIndicatorMeasurement.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/indicatormeasurements/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(measurementResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteIndicatorMethodRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Indicator method
     * Delete specific method for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param methodResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteIndicatorMethod.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteIndicatorMethod.');
        }
        if (methodResourceIdentifier === null || methodResourceIdentifier === undefined) {
            throw new Error('Required parameter methodResourceIdentifier was null or undefined when calling deleteIndicatorMethod.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/indicatormethods/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(methodResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete objective
     * Delete specific objective for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteObjective(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteObjective(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteObjective(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteObjective(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/objectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteObjectiveProgressPercentRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Objective progress percent
     * Delete specific progress percent for Objective
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteObjectiveProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteObjectiveProgressPercent.');
        }
        if (progressPercentResourceIdentifier === null || progressPercentResourceIdentifier === undefined) {
            throw new Error('Required parameter progressPercentResourceIdentifier was null or undefined when calling deleteObjectiveProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/objectiveprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressPercentResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectRequiredPermission() {
        return 'DeleteProject' || '';
    }

    /**
     * Delete Project
     * Delete specific project (soft delete)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProject(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProject(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProject(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProject(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectAssociatedFileRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project associated file
     * Delete specific associated file for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectAssociatedFile.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectAssociatedFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectassociatedfiles/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectAssociatedLinkRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project associated link
     * Delete specific associated link for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectAssociatedLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectassociatedlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectCustomReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project custom report
     * Delete specific custom report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectCustomReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectcustomreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectCustomReportGroupRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project custom report group
     * Delete specific custom report group for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectCustomReportGroup.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectcustomreportgroups/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectDocumentImageRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project document image
     * Delete specific project document image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectDocumentImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectDocumentImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectdocumentimages/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectFactorProgressReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project factor progress report
     * Delete specific project progress report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressReportResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectFactorProgressReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectFactorProgressReport.');
        }
        if (progressReportResourceIdentifier === null || progressReportResourceIdentifier === undefined) {
            throw new Error('Required parameter progressReportResourceIdentifier was null or undefined when calling deleteProjectFactorProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectfactorprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressReportResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectFactorResultReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project factor result report
     * Delete specific project result report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param resultReportResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectFactorResultReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectFactorResultReport.');
        }
        if (resultReportResourceIdentifier === null || resultReportResourceIdentifier === undefined) {
            throw new Error('Required parameter resultReportResourceIdentifier was null or undefined when calling deleteProjectFactorResultReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectfactorresultreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(resultReportResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectProgressReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project progress report
     * Delete specific project progress report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectProgressReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project resource
     * Delete specific project resource for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectResource(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectResource(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectResource(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectResource(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectSlideshowImageRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project slideshow image
     * Delete specific project slideshow image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectSlideshowImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectslideshowimages/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectSnapshotRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project snapshot
     * Delete specific snapshot for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectSnapshot.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectSnapshot.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectsnapshots/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectTeamMemberRequiredPermission() {
        return 'AddTeamMember' || '';
    }

    /**
     * Delete Project team member
     * Delete specific team member for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectTeamMember.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectTeamMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectteammembers/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyActivityRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy activity
     * Delete specific activity for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyActivity.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyActivity.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteStrategyActivity.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyactivities/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyActivityExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Activity expense assignment
     * Remove specific expense assignment for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyActivityExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyActivityExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteStrategyActivityExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling deleteStrategyActivityExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyactivityexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyActivityExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Activity expense assignment detail
     * Remove specific expense detail from a Activity expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyActivityExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyActivityExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteStrategyActivityExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling deleteStrategyActivityExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling deleteStrategyActivityExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyactivityexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyActivityLeaderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy activity leader (project resource)
     * Delete leader (project resource) for a specific Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyActivityLeader.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyActivityLeader.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteStrategyActivityLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyactivityleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyActivityResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Activity project resource assignment
     * Remove project resource assigned to a specific Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param projectResourceIdentifier Project Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyActivityResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyActivityResource.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteStrategyActivityResource.');
        }
        if (projectResourceIdentifier === null || projectResourceIdentifier === undefined) {
            throw new Error('Required parameter projectResourceIdentifier was null or undefined when calling deleteStrategyActivityResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyactivityresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(projectResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyActivityTimeframeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy activity timeframe
     * Delete timeframe for a specific Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyActivityTimeframe.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyActivityTimeframe.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteStrategyActivityTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyactivitytimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy expense assignment
     * Remove specific expense assignment from a Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling deleteStrategyExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy expense assignment detail
     * Remove specific expense detail from a Strategy expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling deleteStrategyExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling deleteStrategyExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyLeaderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy leader (project resource)
     * Delete leader (project resource) for a specific Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyLeader.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy output
     * Delete specific output for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyOutput.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling deleteStrategyOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy project resource assignment
     * Remove project resource assigned to a specific Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectResourceIdentifier Project Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyResource.');
        }
        if (projectResourceIdentifier === null || projectResourceIdentifier === undefined) {
            throw new Error('Required parameter projectResourceIdentifier was null or undefined when calling deleteStrategyResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(projectResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyTimeframeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Strategy timeframe
     * Delete timeframe for a specific Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyTimeframe.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategytimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public downloadProjectRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Download project file
     * Download Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param versionNumber Project version number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadProject(projectIdentifier: string, versionNumber?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<Blob>;
    public downloadProject(projectIdentifier: string, versionNumber?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public downloadProject(projectIdentifier: string, versionNumber?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public downloadProject(projectIdentifier: string, versionNumber?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling downloadProject.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (versionNumber !== undefined && versionNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>versionNumber, 'version_number');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'multipart/form-data',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.get(`${this.configuration.basePath}/projectdownload/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Blob(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Blob(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity
     * Get specific activity for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivity(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StrategyActivity>;
    public getActivity(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StrategyActivity>>;
    public getActivity(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StrategyActivity>>;
    public getActivity(projectIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivity.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivity.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StrategyActivity>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyActivity(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyActivity(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityOutputRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity output
     * Get specific output for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Output>;
    public getActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Output>>;
    public getActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Output>>;
    public getActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityOutput.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling getActivityOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Output>(`${this.configuration.basePath}/activityoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityOutputsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity outputs
     * Get list of outputs for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityOutputs(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Output>>;
    public getActivityOutputs(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Output>>>;
    public getActivityOutputs(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Output>>>;
    public getActivityOutputs(projectIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityOutputs.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityOutputs.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Output>>(`${this.configuration.basePath}/activityoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity task
     * Get specific task for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ActivityTask>;
    public getActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ActivityTask>>;
    public getActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ActivityTask>>;
    public getActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTask.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTask.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTask.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ActivityTask>(`${this.configuration.basePath}/activitytasks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ActivityTask(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ActivityTask(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskExpenseRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task expense assignment
     * Get specific expense assignment for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpense>;
    public getActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpense>>;
    public getActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpense>>;
    public getActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskExpense.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getActivityTaskExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorExpense>(`${this.configuration.basePath}/activitytaskexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskExpenseDetailRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task expense assignment detail
     * Get specific expense detail of Task expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpenseDetail>;
    public getActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpenseDetail>>;
    public getActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpenseDetail>>;
    public getActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskExpenseDetail.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getActivityTaskExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling getActivityTaskExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorExpenseDetail>(`${this.configuration.basePath}/activitytaskexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskExpenseDetailsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task expense assignment details
     * Get expense details for Task expense
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskExpenseDetails(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorExpenseDetail>>;
    public getActivityTaskExpenseDetails(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorExpenseDetail>>>;
    public getActivityTaskExpenseDetails(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorExpenseDetail>>>;
    public getActivityTaskExpenseDetails(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskExpenseDetails.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskExpenseDetails.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskExpenseDetails.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getActivityTaskExpenseDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorExpenseDetail>>(`${this.configuration.basePath}/activitytaskexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskExpensesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task expense assignments
     * Get list of expenses assignments for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskExpenses(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorExpense>>;
    public getActivityTaskExpenses(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorExpense>>>;
    public getActivityTaskExpenses(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorExpense>>>;
    public getActivityTaskExpenses(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskExpenses.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskExpenses.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskExpenses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorExpense>>(`${this.configuration.basePath}/activitytaskexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskLeaderRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Task leader (project resource) by Identifier
     * Returns leader (project resource) for a specific Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResource>;
    public getActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResource>>;
    public getActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResource>>;
    public getActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskLeader.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskLeader.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectResource>(`${this.configuration.basePath}/activitytaskleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskOrderRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity task order
     * Get order of tasks for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ResourceIdentifierList>;
    public getActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ResourceIdentifierList>>;
    public getActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ResourceIdentifierList>>;
    public getActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskOrder.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ResourceIdentifierList>(`${this.configuration.basePath}/activitytaskorder/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ResourceIdentifierList(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ResourceIdentifierList(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskOutputRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task output
     * Get specific output for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Output>;
    public getActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Output>>;
    public getActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Output>>;
    public getActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskOutput.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling getActivityTaskOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Output>(`${this.configuration.basePath}/activitytaskoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskOutputsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task outputs
     * Get list of outputs for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskOutputs(projectIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Output>>;
    public getActivityTaskOutputs(projectIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Output>>>;
    public getActivityTaskOutputs(projectIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Output>>>;
    public getActivityTaskOutputs(projectIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskOutputs.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskOutputs.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Output>>(`${this.configuration.basePath}/activitytaskoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskResourceRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task resource assignment
     * Get specific resource assigned to Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param projectResourceIdentifier Project Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorProjectResource>;
    public getActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorProjectResource>>;
    public getActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorProjectResource>>;
    public getActivityTaskResource(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, projectResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskResource.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskResource.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskResource.');
        }
        if (projectResourceIdentifier === null || projectResourceIdentifier === undefined) {
            throw new Error('Required parameter projectResourceIdentifier was null or undefined when calling getActivityTaskResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorProjectResource>(`${this.configuration.basePath}/activitytaskresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(projectResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskResourcesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Task project resource assignments
     * Get list of project resources assigned to Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskResources(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorProjectResource>>;
    public getActivityTaskResources(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorProjectResource>>>;
    public getActivityTaskResources(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorProjectResource>>>;
    public getActivityTaskResources(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskResources.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskResources.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskResources.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorProjectResource>>(`${this.configuration.basePath}/activitytaskresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTaskTimeframeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Task timeframe by Identifier
     * Returns timeframe for a specific Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorTimeframeDetail>;
    public getActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorTimeframeDetail>>;
    public getActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorTimeframeDetail>>;
    public getActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTaskTimeframe.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTaskTimeframe.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling getActivityTaskTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorTimeframeDetail>(`${this.configuration.basePath}/activitytasktimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTimeframeDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTimeframeDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getActivityTasksRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity tasks
     * Get list of tasks for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityTasks(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ActivityTask>>;
    public getActivityTasks(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ActivityTask>>>;
    public getActivityTasks(projectIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ActivityTask>>>;
    public getActivityTasks(projectIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getActivityTasks.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getActivityTasks.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ActivityTask>>(`${this.configuration.basePath}/activitytasks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ActivityTask(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ActivityTask(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project biodiversity target
     * Get Project biodiversity target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BiodiversityTarget>;
    public getBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BiodiversityTarget>>;
    public getBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BiodiversityTarget>>;
    public getBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<BiodiversityTarget>(`${this.configuration.basePath}/biodiversitytargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new BiodiversityTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new BiodiversityTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetGoalRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Goal
     * Get specific goal for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalResourceIdentifier Goal Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Goal>;
    public getBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Goal>>;
    public getBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Goal>>;
    public getBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetGoal.');
        }
        if (goalResourceIdentifier === null || goalResourceIdentifier === undefined) {
            throw new Error('Required parameter goalResourceIdentifier was null or undefined when calling getBiodiversityTargetGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Goal>(`${this.configuration.basePath}/biodiversitytargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(goalResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Goal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Goal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetGoalsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Goals
     * Get list of Goals for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Goal>>;
    public getBiodiversityTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Goal>>>;
    public getBiodiversityTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Goal>>>;
    public getBiodiversityTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetGoals.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetGoals.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Goal>>(`${this.configuration.basePath}/biodiversitytargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Goal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Goal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Indicator
     * Get specific indicator for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getBiodiversityTargetIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/biodiversitytargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Indicators
     * Get list of Indicators for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getBiodiversityTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getBiodiversityTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getBiodiversityTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/biodiversitytargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetKeyEcologicalAttributeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Key Ecological Attribute
     * Get specific Key Ecological Attribute for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeResourceIdentifier Key Ecological Attribute Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<KeyEcologicalAttribute>;
    public getBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<KeyEcologicalAttribute>>;
    public getBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<KeyEcologicalAttribute>>;
    public getBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeResourceIdentifier === null || keyEcologicalAttributeResourceIdentifier === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeResourceIdentifier was null or undefined when calling getBiodiversityTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<KeyEcologicalAttribute>(`${this.configuration.basePath}/biodiversitytargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(keyEcologicalAttributeResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new KeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new KeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetKeyEcologicalAttributesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Key Ecological Attibutes
     * Get list of Key Ecological Attibutes for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<KeyEcologicalAttribute>>;
    public getBiodiversityTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<KeyEcologicalAttribute>>>;
    public getBiodiversityTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<KeyEcologicalAttribute>>>;
    public getBiodiversityTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetKeyEcologicalAttributes.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetKeyEcologicalAttributes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<KeyEcologicalAttribute>>(`${this.configuration.basePath}/biodiversitytargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new KeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new KeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetNestedTargetRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Nested Target
     * Get specific Nested Target for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetResourceIdentifier Nested Target Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<NestedTarget>;
    public getBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<NestedTarget>>;
    public getBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<NestedTarget>>;
    public getBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetNestedTarget.');
        }
        if (nestedTargetResourceIdentifier === null || nestedTargetResourceIdentifier === undefined) {
            throw new Error('Required parameter nestedTargetResourceIdentifier was null or undefined when calling getBiodiversityTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<NestedTarget>(`${this.configuration.basePath}/biodiversitytargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(nestedTargetResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new NestedTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new NestedTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetNestedTargetsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Nested Target
     * Get list of Nested Targets for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<NestedTarget>>;
    public getBiodiversityTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<NestedTarget>>>;
    public getBiodiversityTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<NestedTarget>>>;
    public getBiodiversityTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetNestedTargets.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetNestedTargets.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NestedTarget>>(`${this.configuration.basePath}/biodiversitytargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new NestedTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new NestedTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetStressRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Stress
     * Get specific Stress for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressResourceIdentifier Stress Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Stress>;
    public getBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Stress>>;
    public getBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Stress>>;
    public getBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetStress.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetStress.');
        }
        if (stressResourceIdentifier === null || stressResourceIdentifier === undefined) {
            throw new Error('Required parameter stressResourceIdentifier was null or undefined when calling getBiodiversityTargetStress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Stress>(`${this.configuration.basePath}/biodiversitytargetstresses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(stressResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Stress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Stress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiodiversityTargetStressesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biodiversity Target Stresses
     * Get list of Stresses for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiodiversityTargetStresses(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Stress>>;
    public getBiodiversityTargetStresses(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Stress>>>;
    public getBiodiversityTargetStresses(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Stress>>>;
    public getBiodiversityTargetStresses(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiodiversityTargetStresses.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiodiversityTargetStresses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Stress>>(`${this.configuration.basePath}/biodiversitytargetstresses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Stress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Stress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Factor
     * Get specific biophysical factor for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BiophysicalFactor>;
    public getBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BiophysicalFactor>>;
    public getBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BiophysicalFactor>>;
    public getBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<BiophysicalFactor>(`${this.configuration.basePath}/biophysicalfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new BiophysicalFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new BiophysicalFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalFactorIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Factor indicator
     * Get specific indicator for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalFactorIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalFactorIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getBiophysicalFactorIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/biophysicalfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalFactorIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Factor indicators
     * Get list of indicators for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getBiophysicalFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getBiophysicalFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getBiophysicalFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalFactorIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalFactorIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/biophysicalfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalFactorObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Factor objective
     * Get specific objective for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalFactorObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalFactorObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling getBiophysicalFactorObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/biophysicalfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalFactorObjectivesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Factor objectives
     * Get list of objectives for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Objective>>;
    public getBiophysicalFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Objective>>>;
    public getBiophysicalFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Objective>>>;
    public getBiophysicalFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalFactorObjectives.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalFactorObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Objective>>(`${this.configuration.basePath}/biophysicalfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalResultRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Result
     * Get specific biophysical result for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BiophysicalResult>;
    public getBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BiophysicalResult>>;
    public getBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BiophysicalResult>>;
    public getBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalResult.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalResult.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<BiophysicalResult>(`${this.configuration.basePath}/biophysicalresults/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new BiophysicalResult(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new BiophysicalResult(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalResultIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Result indicator
     * Get specific indicator for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalResultIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getBiophysicalResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/biophysicalresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalResultIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysicalresult indicators
     * Get list of indicators for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getBiophysicalResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getBiophysicalResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getBiophysicalResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalResultIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalResultIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/biophysicalresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalResultObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysical Result objective
     * Get specific objective for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalResultObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling getBiophysicalResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/biophysicalresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getBiophysicalResultObjectivesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Biophysicalresult objectives
     * Get list of objectives for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBiophysicalResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Objective>>;
    public getBiophysicalResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Objective>>>;
    public getBiophysicalResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Objective>>>;
    public getBiophysicalResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getBiophysicalResultObjectives.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getBiophysicalResultObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Objective>>(`${this.configuration.basePath}/biophysicalresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getContributingFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get contributing factor
     * Get specific contributing factor for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContributingFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ContributingFactor>;
    public getContributingFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ContributingFactor>>;
    public getContributingFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ContributingFactor>>;
    public getContributingFactor(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getContributingFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getContributingFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ContributingFactor>(`${this.configuration.basePath}/contributingfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ContributingFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ContributingFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getContributingFactorIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Contributing Factor indicator
     * Get specific indicator for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getContributingFactorIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getContributingFactorIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getContributingFactorIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/contributingfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getContributingFactorIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Contributing Factor indicators
     * Get list of indicators for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContributingFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getContributingFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getContributingFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getContributingFactorIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getContributingFactorIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getContributingFactorIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/contributingfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getContributingFactorObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Contributing Factor objective
     * Get specific objective for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getContributingFactorObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getContributingFactorObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling getContributingFactorObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/contributingfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getContributingFactorObjectivesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Contributing Factor objectives
     * Get list of objectives for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContributingFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Objective>>;
    public getContributingFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Objective>>>;
    public getContributingFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Objective>>>;
    public getContributingFactorObjectives(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getContributingFactorObjectives.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getContributingFactorObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Objective>>(`${this.configuration.basePath}/contributingfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getDirectThreatRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get direct threat
     * Get specific direct threat for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDirectThreat(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DirectThreat>;
    public getDirectThreat(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DirectThreat>>;
    public getDirectThreat(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DirectThreat>>;
    public getDirectThreat(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getDirectThreat.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getDirectThreat.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<DirectThreat>(`${this.configuration.basePath}/directthreats/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new DirectThreat(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new DirectThreat(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getDirectThreatIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Direct Threat indicator
     * Get specific indicator for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getDirectThreatIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getDirectThreatIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getDirectThreatIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/directthreatindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getDirectThreatIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Direct Threat indicators
     * Get list of indicators for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDirectThreatIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getDirectThreatIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getDirectThreatIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getDirectThreatIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getDirectThreatIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getDirectThreatIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/directthreatindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getDirectThreatObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Direct Threat objective
     * Get specific objective for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getDirectThreatObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getDirectThreatObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling getDirectThreatObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/directthreatobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getDirectThreatObjectivesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Direct Threat objectives
     * Get list of objectives for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDirectThreatObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Objective>>;
    public getDirectThreatObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Objective>>>;
    public getDirectThreatObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Objective>>>;
    public getDirectThreatObjectives(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getDirectThreatObjectives.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getDirectThreatObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Objective>>(`${this.configuration.basePath}/directthreatobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getFactorIndicatorScorecardRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator scorecard for factor
     * Get specific scorecard
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param scorecardResourceIdentifier Scorecard Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorScorecard>;
    public getFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorScorecard>>;
    public getFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorScorecard>>;
    public getFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getFactorIndicatorScorecard.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getFactorIndicatorScorecard.');
        }
        if (scorecardResourceIdentifier === null || scorecardResourceIdentifier === undefined) {
            throw new Error('Required parameter scorecardResourceIdentifier was null or undefined when calling getFactorIndicatorScorecard.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorScorecard>(`${this.configuration.basePath}/indicatorscorecards/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(scorecardResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorScorecard(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorScorecard(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getFactorIndicatorScorecardsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator scorecards for Factor
     * Get list of indicator scorecards for Factor (Goal / Objective)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFactorIndicatorScorecards(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<IndicatorScorecard>>;
    public getFactorIndicatorScorecards(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<IndicatorScorecard>>>;
    public getFactorIndicatorScorecards(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<IndicatorScorecard>>>;
    public getFactorIndicatorScorecards(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getFactorIndicatorScorecards.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getFactorIndicatorScorecards.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<IndicatorScorecard>>(`${this.configuration.basePath}/indicatorscorecards/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorScorecard(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorScorecard(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getFavoriteProjectsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get list of Projects that end-user has marked as favorite
     * Get list of Projects that end-user has marked as favorite
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFavoriteProjects(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Project>>;
    public getFavoriteProjects(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Project>>>;
    public getFavoriteProjects(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Project>>>;
    public getFavoriteProjects(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Project>>(`${this.configuration.basePath}/favoriteprojects`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Project(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Project(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getGoalRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Goal
     * Get specific goal for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGoal(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Goal>;
    public getGoal(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Goal>>;
    public getGoal(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Goal>>;
    public getGoal(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Goal>(`${this.configuration.basePath}/goals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Goal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Goal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getGoalProgressPercentRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Goal progress percent
     * Get specific progress_percent for Goal
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressPercent>;
    public getGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressPercent>>;
    public getGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressPercent>>;
    public getGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getGoalProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getGoalProgressPercent.');
        }
        if (progressPercentResourceIdentifier === null || progressPercentResourceIdentifier === undefined) {
            throw new Error('Required parameter progressPercentResourceIdentifier was null or undefined when calling getGoalProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgressPercent>(`${this.configuration.basePath}/goalprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressPercentResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressPercent(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressPercent(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getGoalProgressPercentsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Goal progress percents
     * Get list of progress percents for Goal (descending date order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGoalProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgressPercent>>;
    public getGoalProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgressPercent>>>;
    public getGoalProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgressPercent>>>;
    public getGoalProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getGoalProgressPercents.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getGoalProgressPercents.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgressPercent>>(`${this.configuration.basePath}/goalprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressPercent(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressPercent(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getGoalRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Goal
     * Get relevant factors for Goal
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GoalRelevantFactors>;
    public getGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GoalRelevantFactors>>;
    public getGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GoalRelevantFactors>>;
    public getGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getGoalRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getGoalRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<GoalRelevantFactors>(`${this.configuration.basePath}/goalrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new GoalRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new GoalRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project human wellbeing target
     * Get Project human wellbeing target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HumanWellbeingTarget>;
    public getHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<HumanWellbeingTarget>>;
    public getHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<HumanWellbeingTarget>>;
    public getHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<HumanWellbeingTarget>(`${this.configuration.basePath}/humanwellbeingtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new HumanWellbeingTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new HumanWellbeingTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetGoalRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Humanwellbeing Target Goal
     * Get specific goal for Humanwellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalResourceIdentifier Goal Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Goal>;
    public getHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Goal>>;
    public getHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Goal>>;
    public getHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetGoal.');
        }
        if (goalResourceIdentifier === null || goalResourceIdentifier === undefined) {
            throw new Error('Required parameter goalResourceIdentifier was null or undefined when calling getHumanWellbeingTargetGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Goal>(`${this.configuration.basePath}/humanwellbeingtargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(goalResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Goal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Goal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetGoalsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Human wellbeing target Goals
     * Get list of Goals for human wellbeing target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Goal>>;
    public getHumanWellbeingTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Goal>>>;
    public getHumanWellbeingTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Goal>>>;
    public getHumanWellbeingTargetGoals(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetGoals.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetGoals.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Goal>>(`${this.configuration.basePath}/humanwellbeingtargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Goal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Goal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Humanwellbeing Target Indicator
     * Get specific indicator for Humanwellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getHumanWellbeingTargetIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/humanwellbeingtargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Human wellbeing target Indicators
     * Get list of Indicators for human wellbeing target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getHumanWellbeingTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getHumanWellbeingTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getHumanWellbeingTargetIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/humanwellbeingtargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetKeyEcologicalAttributeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Human Wellbeing Target Key Ecological Attribute
     * Get specific Key Ecological Attribute for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeResourceIdentifier Key Ecological Attribute Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<KeyEcologicalAttribute>;
    public getHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<KeyEcologicalAttribute>>;
    public getHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<KeyEcologicalAttribute>>;
    public getHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeResourceIdentifier === null || keyEcologicalAttributeResourceIdentifier === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeResourceIdentifier was null or undefined when calling getHumanWellbeingTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<KeyEcologicalAttribute>(`${this.configuration.basePath}/humanwellbeingtargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(keyEcologicalAttributeResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new KeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new KeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetKeyEcologicalAttributesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Human Wellbeing Target Key Ecological Attibutes
     * Get list of Key Ecological Attibutes for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<KeyEcologicalAttribute>>;
    public getHumanWellbeingTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<KeyEcologicalAttribute>>>;
    public getHumanWellbeingTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<KeyEcologicalAttribute>>>;
    public getHumanWellbeingTargetKeyEcologicalAttributes(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetKeyEcologicalAttributes.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetKeyEcologicalAttributes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<KeyEcologicalAttribute>>(`${this.configuration.basePath}/humanwellbeingtargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new KeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new KeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetNestedTargetRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Human Wellbeing Target Nested Target
     * Get specific Nested Target for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetResourceIdentifier Nested Target Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<NestedTarget>;
    public getHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<NestedTarget>>;
    public getHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<NestedTarget>>;
    public getHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetNestedTarget.');
        }
        if (nestedTargetResourceIdentifier === null || nestedTargetResourceIdentifier === undefined) {
            throw new Error('Required parameter nestedTargetResourceIdentifier was null or undefined when calling getHumanWellbeingTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<NestedTarget>(`${this.configuration.basePath}/humanwellbeingtargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(nestedTargetResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new NestedTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new NestedTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getHumanWellbeingTargetNestedTargetsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Human Wellbeing Target Nested Target
     * Get list of Nested Targets for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHumanWellbeingTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<NestedTarget>>;
    public getHumanWellbeingTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<NestedTarget>>>;
    public getHumanWellbeingTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<NestedTarget>>>;
    public getHumanWellbeingTargetNestedTargets(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getHumanWellbeingTargetNestedTargets.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getHumanWellbeingTargetNestedTargets.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NestedTarget>>(`${this.configuration.basePath}/humanwellbeingtargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new NestedTarget(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new NestedTarget(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator
     * Get specific indicator for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicator(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getIndicator(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getIndicator(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getIndicator(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/indicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorFutureStatusRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator future status
     * Get specific future status for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param futureStatusResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorFutureStatus>;
    public getIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorFutureStatus>>;
    public getIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorFutureStatus>>;
    public getIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorFutureStatus.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorFutureStatus.');
        }
        if (futureStatusResourceIdentifier === null || futureStatusResourceIdentifier === undefined) {
            throw new Error('Required parameter futureStatusResourceIdentifier was null or undefined when calling getIndicatorFutureStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorFutureStatus>(`${this.configuration.basePath}/indicatorfuturestatuses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(futureStatusResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorFutureStatus(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorFutureStatus(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorFutureStatusesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator future statuses
     * Get list of future statuses for Indicator (descending date order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorFutureStatuses(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<IndicatorFutureStatus>>;
    public getIndicatorFutureStatuses(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<IndicatorFutureStatus>>>;
    public getIndicatorFutureStatuses(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<IndicatorFutureStatus>>>;
    public getIndicatorFutureStatuses(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorFutureStatuses.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorFutureStatuses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<IndicatorFutureStatus>>(`${this.configuration.basePath}/indicatorfuturestatuses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorFutureStatus(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorFutureStatus(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorMeasurementRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator measurement
     * Get specific measurement for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param measurementResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorMeasurement>;
    public getIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorMeasurement>>;
    public getIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorMeasurement>>;
    public getIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorMeasurement.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorMeasurement.');
        }
        if (measurementResourceIdentifier === null || measurementResourceIdentifier === undefined) {
            throw new Error('Required parameter measurementResourceIdentifier was null or undefined when calling getIndicatorMeasurement.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorMeasurement>(`${this.configuration.basePath}/indicatormeasurements/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(measurementResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorMeasurement(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorMeasurement(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorMeasurementsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator measurements
     * Get list of measurements for Indicator (descending date order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorMeasurements(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<IndicatorMeasurement>>;
    public getIndicatorMeasurements(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<IndicatorMeasurement>>>;
    public getIndicatorMeasurements(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<IndicatorMeasurement>>>;
    public getIndicatorMeasurements(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorMeasurements.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorMeasurements.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<IndicatorMeasurement>>(`${this.configuration.basePath}/indicatormeasurements/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorMeasurement(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorMeasurement(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorMethodRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator method
     * Get specific method for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param methodResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorMethod>;
    public getIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorMethod>>;
    public getIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorMethod>>;
    public getIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorMethod.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorMethod.');
        }
        if (methodResourceIdentifier === null || methodResourceIdentifier === undefined) {
            throw new Error('Required parameter methodResourceIdentifier was null or undefined when calling getIndicatorMethod.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorMethod>(`${this.configuration.basePath}/indicatormethods/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(methodResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorMethod(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorMethod(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorMethodsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator methods
     * Get list of methods for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorMethods(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<IndicatorMethod>>;
    public getIndicatorMethods(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<IndicatorMethod>>>;
    public getIndicatorMethods(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<IndicatorMethod>>>;
    public getIndicatorMethods(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorMethods.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorMethods.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<IndicatorMethod>>(`${this.configuration.basePath}/indicatormethods/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorMethod(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorMethod(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Indicator
     * Get relevant factors for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorRelevantFactors>;
    public getIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorRelevantFactors>>;
    public getIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorRelevantFactors>>;
    public getIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorRelevantFactors>(`${this.configuration.basePath}/indicatorrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorViabilityRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator viability
     * Get Indicator viability (measurements, future statuses, etc.)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorViability(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorViability>;
    public getIndicatorViability(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorViability>>;
    public getIndicatorViability(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorViability>>;
    public getIndicatorViability(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorViability.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorViability.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorViability>(`${this.configuration.basePath}/indicatorviability/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorViability(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorViability(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIndicatorViabilityRatingsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator viability ratings
     * Get the viability ratings for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorViabilityRatings>;
    public getIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorViabilityRatings>>;
    public getIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorViabilityRatings>>;
    public getIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIndicatorViabilityRatings.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIndicatorViabilityRatings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorViabilityRatings>(`${this.configuration.basePath}/indicatorviabilityratings/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorViabilityRatings(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorViabilityRatings(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIntermediateResultRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Intermediate Result
     * Get specific intermediate result for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntermediateResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IntermediateResult>;
    public getIntermediateResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IntermediateResult>>;
    public getIntermediateResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IntermediateResult>>;
    public getIntermediateResult(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIntermediateResult.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIntermediateResult.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IntermediateResult>(`${this.configuration.basePath}/intermediateresults/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IntermediateResult(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IntermediateResult(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIntermediateResultIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Intermediate Result indicator
     * Get specific indicator for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIntermediateResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIntermediateResultIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getIntermediateResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/intermediateresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIntermediateResultIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Intermediateresult indicators
     * Get list of indicators for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntermediateResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getIntermediateResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getIntermediateResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getIntermediateResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIntermediateResultIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIntermediateResultIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/intermediateresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIntermediateResultObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Intermediate Result objective
     * Get specific objective for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIntermediateResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIntermediateResultObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling getIntermediateResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/intermediateresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getIntermediateResultObjectivesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Intermediateresult objectives
     * Get list of objectives for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntermediateResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Objective>>;
    public getIntermediateResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Objective>>>;
    public getIntermediateResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Objective>>>;
    public getIntermediateResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getIntermediateResultObjectives.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getIntermediateResultObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Objective>>(`${this.configuration.basePath}/intermediateresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getKeyEcologicalAttributeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project key ecological attribute
     * Get Project key ecological attribute
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<KeyEcologicalAttribute>;
    public getKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<KeyEcologicalAttribute>>;
    public getKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<KeyEcologicalAttribute>>;
    public getKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<KeyEcologicalAttribute>(`${this.configuration.basePath}/keyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new KeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new KeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getKeyEcologicalAttributeIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get key ecological attribute Indicator
     * Get specific indicator for key ecological attribute
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getKeyEcologicalAttributeIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getKeyEcologicalAttributeIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getKeyEcologicalAttributeIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/keyecologicalattributeindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getKeyEcologicalAttributeIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get key ecological attribute Indicators
     * Get list of Indicators for key ecological attribute
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKeyEcologicalAttributeIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getKeyEcologicalAttributeIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getKeyEcologicalAttributeIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getKeyEcologicalAttributeIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getKeyEcologicalAttributeIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getKeyEcologicalAttributeIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/keyecologicalattributeindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getMonitoringPlanRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project monitoring plan
     * Get monitoring plan hierarchy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMonitoringPlan(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectMonitoringPlan>;
    public getMonitoringPlan(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectMonitoringPlan>>;
    public getMonitoringPlan(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectMonitoringPlan>>;
    public getMonitoringPlan(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getMonitoringPlan.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectMonitoringPlan>(`${this.configuration.basePath}/monitoringplan/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectMonitoringPlan(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectMonitoringPlan(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getMyProjectsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get list of Projects where end-user is on project team
     * Get list of Projects where end-user is on project team
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyProjects(programIdentifier?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Project>>;
    public getMyProjects(programIdentifier?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Project>>>;
    public getMyProjects(programIdentifier?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Project>>>;
    public getMyProjects(programIdentifier?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (programIdentifier !== undefined && programIdentifier !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>programIdentifier, 'program_identifier');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Project>>(`${this.configuration.basePath}/myprojects`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Project(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Project(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Objective
     * Get specific objective for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getObjective(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getObjective(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getObjective(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getObjective(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/objectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getObjectiveProgressPercentRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Objective progress percent
     * Get specific progress percent for Objective
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressPercent>;
    public getObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressPercent>>;
    public getObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressPercent>>;
    public getObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getObjectiveProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getObjectiveProgressPercent.');
        }
        if (progressPercentResourceIdentifier === null || progressPercentResourceIdentifier === undefined) {
            throw new Error('Required parameter progressPercentResourceIdentifier was null or undefined when calling getObjectiveProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgressPercent>(`${this.configuration.basePath}/objectiveprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressPercentResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressPercent(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressPercent(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getObjectiveProgressPercentsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Objective progress percents
     * Get list of progress percents for Objective (descending date order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getObjectiveProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgressPercent>>;
    public getObjectiveProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgressPercent>>>;
    public getObjectiveProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgressPercent>>>;
    public getObjectiveProgressPercents(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getObjectiveProgressPercents.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getObjectiveProgressPercents.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgressPercent>>(`${this.configuration.basePath}/objectiveprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressPercent(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressPercent(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getObjectiveRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Objective
     * Get relevant factors for Objective
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ObjectiveRelevantFactors>;
    public getObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ObjectiveRelevantFactors>>;
    public getObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ObjectiveRelevantFactors>>;
    public getObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getObjectiveRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getObjectiveRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ObjectiveRelevantFactors>(`${this.configuration.basePath}/objectiverelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ObjectiveRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ObjectiveRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getOutputRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Output
     * Get relevant factors for Output
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OutputRelevantFactors>;
    public getOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OutputRelevantFactors>>;
    public getOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OutputRelevantFactors>>;
    public getOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getOutputRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getOutputRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<OutputRelevantFactors>(`${this.configuration.basePath}/outputrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new OutputRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new OutputRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Find Project by Identifier
     * Returns a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProject(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Project>;
    public getProject(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Project>>;
    public getProject(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Project>>;
    public getProject(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Project>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Project(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Project(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAccessStateRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Find Project access state by Identifier
     * Returns access state for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAccessState(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectAccessState>;
    public getProjectAccessState(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectAccessState>>;
    public getProjectAccessState(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectAccessState>>;
    public getProjectAccessState(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAccessState.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectAccessState>(`${this.configuration.basePath}/projectaccessstate/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectAccessState(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectAccessState(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectActionTrackingTimeframesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get timeframe hierarchy for action tracking for Project
     * Get timeframe hierarchy for action tracking for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectActionTrackingTimeframes(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectActionTimeframes>;
    public getProjectActionTrackingTimeframes(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectActionTimeframes>>;
    public getProjectActionTrackingTimeframes(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectActionTimeframes>>;
    public getProjectActionTrackingTimeframes(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectActionTrackingTimeframes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectActionTimeframes>(`${this.configuration.basePath}/actiontrackingtimeframes/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectActionTimeframes(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectActionTimeframes(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssignmentTaxonomyClassificationsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Resource Assignment Taxonomy Classifications by Project Identifier
     * Returns Resource Assignment Taxonomy Classifications for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssignmentTaxonomyClassifications(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssignmentTaxonomyClassification>>;
    public getProjectAssignmentTaxonomyClassifications(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssignmentTaxonomyClassification>>>;
    public getProjectAssignmentTaxonomyClassifications(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssignmentTaxonomyClassification>>>;
    public getProjectAssignmentTaxonomyClassifications(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssignmentTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AssignmentTaxonomyClassification>>(`${this.configuration.basePath}/projectassignmenttaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssignmentTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssignmentTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssignmentTaxonomyClassificationsForAssignmentRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Resource Assignment Taxonomy Classifications for specific Assignment
     * Returns Resource Assignment Taxonomy Classifications for Project for specific Assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssignmentTaxonomyClassificationsForAssignment(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssignmentTaxonomyClassification>>;
    public getProjectAssignmentTaxonomyClassificationsForAssignment(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssignmentTaxonomyClassification>>>;
    public getProjectAssignmentTaxonomyClassificationsForAssignment(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssignmentTaxonomyClassification>>>;
    public getProjectAssignmentTaxonomyClassificationsForAssignment(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssignmentTaxonomyClassificationsForAssignment.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectAssignmentTaxonomyClassificationsForAssignment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AssignmentTaxonomyClassification>>(`${this.configuration.basePath}/projectassignmenttaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssignmentTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssignmentTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomyRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Resource Assignment Taxonomy Classifications for specific Assignment and Taxonomy Version
     * Returns Resource Assignment Taxonomy Classifications for Project for specific Assignment for specific Taxonomy Version
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param taxonomyVersionResourceIdentifier Taxonomy Version Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssignmentTaxonomyClassification>;
    public getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssignmentTaxonomyClassification>>;
    public getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssignmentTaxonomyClassification>>;
    public getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomy.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomy.');
        }
        if (taxonomyVersionResourceIdentifier === null || taxonomyVersionResourceIdentifier === undefined) {
            throw new Error('Required parameter taxonomyVersionResourceIdentifier was null or undefined when calling getProjectAssignmentTaxonomyClassificationsForAssignmentAndTaxonomy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AssignmentTaxonomyClassification>(`${this.configuration.basePath}/projectassignmenttaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(taxonomyVersionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssignmentTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssignmentTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssociatedFileRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project associated file
     * Get specific associated files for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssociatedFile>;
    public getProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssociatedFile>>;
    public getProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssociatedFile>>;
    public getProjectAssociatedFile(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssociatedFile.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectAssociatedFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AssociatedFile>(`${this.configuration.basePath}/projectassociatedfiles/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedFile(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedFile(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssociatedFilesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project associated files
     * Get list of associated files for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssociatedFiles(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssociatedFile>>;
    public getProjectAssociatedFiles(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssociatedFile>>>;
    public getProjectAssociatedFiles(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssociatedFile>>>;
    public getProjectAssociatedFiles(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssociatedFiles.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AssociatedFile>>(`${this.configuration.basePath}/projectassociatedfiles/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedFile(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedFile(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssociatedLinkRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project associated link
     * Get specific associated links for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssociatedLink>;
    public getProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssociatedLink>>;
    public getProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssociatedLink>>;
    public getProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssociatedLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AssociatedLink>(`${this.configuration.basePath}/projectassociatedlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssociatedLinksRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project associated links
     * Get list of associated links for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssociatedLinks(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssociatedLink>>;
    public getProjectAssociatedLinks(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssociatedLink>>>;
    public getProjectAssociatedLinks(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssociatedLink>>>;
    public getProjectAssociatedLinks(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssociatedLinks.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AssociatedLink>>(`${this.configuration.basePath}/projectassociatedlinks/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectAssumptionSummaryReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project assumption summary report by Identifier
     * Returns assumption summary report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectAssumptionSummaryReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectAssumptionSummaryReport>;
    public getProjectAssumptionSummaryReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectAssumptionSummaryReport>>;
    public getProjectAssumptionSummaryReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectAssumptionSummaryReport>>;
    public getProjectAssumptionSummaryReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectAssumptionSummaryReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectAssumptionSummaryReport>(`${this.configuration.basePath}/projectassumptionsummaryreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectAssumptionSummaryReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectAssumptionSummaryReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectChartsRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Find Project charts by Identifier
     * Returns chart data for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCharts(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ChartData>>;
    public getProjectCharts(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ChartData>>>;
    public getProjectCharts(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ChartData>>>;
    public getProjectCharts(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectCharts.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ChartData>>(`${this.configuration.basePath}/projectcharts/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ChartData(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ChartData(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectCustomReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project custom report
     * Get specific custom report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CustomReport>;
    public getProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CustomReport>>;
    public getProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CustomReport>>;
    public getProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectCustomReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CustomReport>(`${this.configuration.basePath}/projectcustomreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new CustomReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new CustomReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectCustomReportGroupRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project custom report group
     * Get specific custom report group for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectCustomReportGroup>;
    public getProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectCustomReportGroup>>;
    public getProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectCustomReportGroup>>;
    public getProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectCustomReportGroup.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectCustomReportGroup>(`${this.configuration.basePath}/projectcustomreportgroups/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectCustomReportGroup(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectCustomReportGroup(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectCustomReportGroupsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project custom report groups
     * Get list of custom report groups for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCustomReportGroups(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectCustomReportGroup>>;
    public getProjectCustomReportGroups(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectCustomReportGroup>>>;
    public getProjectCustomReportGroups(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectCustomReportGroup>>>;
    public getProjectCustomReportGroups(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectCustomReportGroups.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectCustomReportGroup>>(`${this.configuration.basePath}/projectcustomreportgroups/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectCustomReportGroup(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectCustomReportGroup(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectCustomReportsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project custom reports
     * Get list of custom reports for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCustomReports(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<CustomReport>>;
    public getProjectCustomReports(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<CustomReport>>>;
    public getProjectCustomReports(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<CustomReport>>>;
    public getProjectCustomReports(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectCustomReports.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<CustomReport>>(`${this.configuration.basePath}/projectcustomreports/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new CustomReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new CustomReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramProgressRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram progress
     * Get progress report hierarchy for Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramProgress(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorProgress>;
    public getProjectDiagramProgress(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorProgress>>;
    public getProjectDiagramProgress(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorProgress>>;
    public getProjectDiagramProgress(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramProgress.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorProgress>(`${this.configuration.basePath}/projectdiagramprogress/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProgress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProgress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramResultsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram results
     * Get result report hierarchy for Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramResults(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DiagramResult>;
    public getProjectDiagramResults(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DiagramResult>>;
    public getProjectDiagramResults(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DiagramResult>>;
    public getProjectDiagramResults(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramResults.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramResults.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<DiagramResult>(`${this.configuration.basePath}/projectresults/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new DiagramResult(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new DiagramResult(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramResultsTrackingRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram results tracking
     * Get results tracking hierarchy for Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramResultsTracking(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DiagramResultsTracking>;
    public getProjectDiagramResultsTracking(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DiagramResultsTracking>>;
    public getProjectDiagramResultsTracking(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DiagramResultsTracking>>;
    public getProjectDiagramResultsTracking(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramResultsTracking.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramResultsTracking.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<DiagramResultsTracking>(`${this.configuration.basePath}/projectresultstracking/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new DiagramResultsTracking(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new DiagramResultsTracking(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDocumentImageRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project document image
     * Get specific project document image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDocumentImage>;
    public getProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDocumentImage>>;
    public getProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDocumentImage>>;
    public getProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDocumentImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDocumentImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDocumentImage>(`${this.configuration.basePath}/projectdocumentimages/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDocumentImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDocumentImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDocumentImagesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project document images
     * Get list of project document images for Project (in image order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDocumentImages(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectDocumentImage>>;
    public getProjectDocumentImages(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectDocumentImage>>>;
    public getProjectDocumentImages(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectDocumentImage>>>;
    public getProjectDocumentImages(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDocumentImages.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectDocumentImage>>(`${this.configuration.basePath}/projectdocumentimages/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDocumentImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDocumentImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectEditStatusRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project (file) edit status by Identifier
     * Returns edit status for a specific Project (file)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectEditStatus(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectEditStatus>;
    public getProjectEditStatus(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectEditStatus>>;
    public getProjectEditStatus(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectEditStatus>>;
    public getProjectEditStatus(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectEditStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectEditStatus>(`${this.configuration.basePath}/projecteditstatus/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectEditStatus(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectEditStatus(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectExpenseTaxonomyClassificationsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Expense Assignment Taxonomy Classifications by Project Identifier
     * Returns Expense Assignment Taxonomy Classifications for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectExpenseTaxonomyClassifications(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ExpenseTaxonomyClassification>>;
    public getProjectExpenseTaxonomyClassifications(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ExpenseTaxonomyClassification>>>;
    public getProjectExpenseTaxonomyClassifications(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ExpenseTaxonomyClassification>>>;
    public getProjectExpenseTaxonomyClassifications(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectExpenseTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ExpenseTaxonomyClassification>>(`${this.configuration.basePath}/projectexpensetaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ExpenseTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ExpenseTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectExpenseTaxonomyClassificationsForExpenseRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Expense Assignment Taxonomy Classifications for specific Assignment
     * Returns Expense Assignment Taxonomy Classifications for Project for specific Assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectExpenseTaxonomyClassificationsForExpense(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ExpenseTaxonomyClassification>>;
    public getProjectExpenseTaxonomyClassificationsForExpense(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ExpenseTaxonomyClassification>>>;
    public getProjectExpenseTaxonomyClassificationsForExpense(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ExpenseTaxonomyClassification>>>;
    public getProjectExpenseTaxonomyClassificationsForExpense(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectExpenseTaxonomyClassificationsForExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectExpenseTaxonomyClassificationsForExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ExpenseTaxonomyClassification>>(`${this.configuration.basePath}/projectexpensetaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ExpenseTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ExpenseTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomyRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Expense Assignment Taxonomy Classifications for specific Assignment and Taxonomy Version
     * Returns Expense Taxonomy Classifications for Project for specific Assignment for specific Taxonomy Version
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param taxonomyVersionResourceIdentifier Taxonomy Version Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ExpenseTaxonomyClassification>;
    public getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ExpenseTaxonomyClassification>>;
    public getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ExpenseTaxonomyClassification>>;
    public getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomy.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomy.');
        }
        if (taxonomyVersionResourceIdentifier === null || taxonomyVersionResourceIdentifier === undefined) {
            throw new Error('Required parameter taxonomyVersionResourceIdentifier was null or undefined when calling getProjectExpenseTaxonomyClassificationsForExpenseAndTaxonomy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ExpenseTaxonomyClassification>(`${this.configuration.basePath}/projectexpensetaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(taxonomyVersionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ExpenseTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ExpenseTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Summary by Identifier
     * Returns Factor Summary for specific Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorSummary>;
    public getProjectFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorSummary>>;
    public getProjectFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorSummary>>;
    public getProjectFactor(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorSummary>(`${this.configuration.basePath}/projectfactorsummary/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorSummary(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorSummary(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorDiagramInfoRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Factor Diagrams
     * Returns diagrams for specific factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param factorResourceIdentifier Factor Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorDiagramInfo(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorDiagramInfo>;
    public getProjectFactorDiagramInfo(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorDiagramInfo>>;
    public getProjectFactorDiagramInfo(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorDiagramInfo>>;
    public getProjectFactorDiagramInfo(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorDiagramInfo.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling getProjectFactorDiagramInfo.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling getProjectFactorDiagramInfo.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorDiagramInfo>(`${this.configuration.basePath}/projectfactordiagrams/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}/${encodeURIComponent(String(factorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorDiagramInfo(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorDiagramInfo(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorEvidenceReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Factor Evidence report by Project Identifier
     * Returns Project Factor Evidence (report)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorEvidenceReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorEvidenceReport>;
    public getProjectFactorEvidenceReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorEvidenceReport>>;
    public getProjectFactorEvidenceReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorEvidenceReport>>;
    public getProjectFactorEvidenceReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorEvidenceReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorEvidenceReport>(`${this.configuration.basePath}/projectfactorevidencereport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorEvidenceReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorEvidenceReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorFootprintsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Factor Footprints by Project Identifier
     * Returns Factor Footprints for specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorFootprints(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorTypeFootprint>>;
    public getProjectFactorFootprints(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorTypeFootprint>>>;
    public getProjectFactorFootprints(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorTypeFootprint>>>;
    public getProjectFactorFootprints(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorFootprints.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorTypeFootprint>>(`${this.configuration.basePath}/projectfactorfootprints/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTypeFootprint(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTypeFootprint(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorFootprintsForFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Footprints for specific Factor
     * Returns Factor Footprints for Project for specific Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorFootprintsForFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorFootprint>;
    public getProjectFactorFootprintsForFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorFootprint>>;
    public getProjectFactorFootprintsForFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorFootprint>>;
    public getProjectFactorFootprintsForFactor(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorFootprintsForFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorFootprintsForFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorFootprint>(`${this.configuration.basePath}/projectfactorfootprints/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorFootprint(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorFootprint(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorGoalProgressRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Factor goal progress
     * Get list of Progress Percents for Factor Goals for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorGoalProgress(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorGoalProgress>;
    public getProjectFactorGoalProgress(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorGoalProgress>>;
    public getProjectFactorGoalProgress(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorGoalProgress>>;
    public getProjectFactorGoalProgress(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorGoalProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorGoalProgress>(`${this.configuration.basePath}/projectfactorgoalprogress/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorGoalProgress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorGoalProgress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorGoalsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Factor goals
     * Get list of Goals for Factors for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorGoals(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorGoal>;
    public getProjectFactorGoals(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorGoal>>;
    public getProjectFactorGoals(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorGoal>>;
    public getProjectFactorGoals(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorGoals.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorGoal>(`${this.configuration.basePath}/projectfactorgoals/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorGoal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorGoal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorIndicatorMeasurementsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Indicator measurements
     * Get list of Measures for Indicators for Factors for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorIndicatorMeasurements(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectWithFactorIndicatorMeasurements>;
    public getProjectFactorIndicatorMeasurements(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectWithFactorIndicatorMeasurements>>;
    public getProjectFactorIndicatorMeasurements(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectWithFactorIndicatorMeasurements>>;
    public getProjectFactorIndicatorMeasurements(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorIndicatorMeasurements.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectWithFactorIndicatorMeasurements>(`${this.configuration.basePath}/projectfactorindicatormeasurements/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectWithFactorIndicatorMeasurements(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectWithFactorIndicatorMeasurements(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorIndicatorTaxonomyClassificationsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Indicator Taxonomy Classifications
     * Get list of Taxonomy Classifications for Indicators for Factors for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectWithFactorIndicatorTaxonomyClassifications>;
    public getProjectFactorIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectWithFactorIndicatorTaxonomyClassifications>>;
    public getProjectFactorIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectWithFactorIndicatorTaxonomyClassifications>>;
    public getProjectFactorIndicatorTaxonomyClassifications(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorIndicatorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectWithFactorIndicatorTaxonomyClassifications>(`${this.configuration.basePath}/projectfactorindicatortaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorIndicatorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Factor indicators
     * Get list of Indicators for Factors for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorIndicators(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorIndicator>;
    public getProjectFactorIndicators(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorIndicator>>;
    public getProjectFactorIndicators(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorIndicator>>;
    public getProjectFactorIndicators(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorIndicator>(`${this.configuration.basePath}/projectfactorindicators/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorIndicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorIndicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorObjectiveProgressRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Factor objective progress
     * Get list of Progress Percents for Factor Objectives fpr Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorObjectiveProgress(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorObjectiveProgress>;
    public getProjectFactorObjectiveProgress(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorObjectiveProgress>>;
    public getProjectFactorObjectiveProgress(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorObjectiveProgress>>;
    public getProjectFactorObjectiveProgress(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorObjectiveProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorObjectiveProgress>(`${this.configuration.basePath}/projectfactorobjectiveprogress/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorObjectiveProgress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorObjectiveProgress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorObjectivesRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Factor objectives
     * Get list of Objectives for Factors for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorObjectives(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorObjective>;
    public getProjectFactorObjectives(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorObjective>>;
    public getProjectFactorObjectives(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorObjective>>;
    public getProjectFactorObjectives(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorObjective>(`${this.configuration.basePath}/projectfactorobjectives/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorObjective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorObjective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorProgressReportRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project factor progress report
     * Get specific project progress report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressReportResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressReport>;
    public getProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressReport>>;
    public getProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressReport>>;
    public getProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorProgressReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorProgressReport.');
        }
        if (progressReportResourceIdentifier === null || progressReportResourceIdentifier === undefined) {
            throw new Error('Required parameter progressReportResourceIdentifier was null or undefined when calling getProjectFactorProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgressReport>(`${this.configuration.basePath}/projectfactorprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressReportResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorProgressReportsRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project factor progress reports
     * Get list of progress reports for Project factor (descending date order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorProgressReports(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgressReport>>;
    public getProjectFactorProgressReports(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgressReport>>>;
    public getProjectFactorProgressReports(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgressReport>>>;
    public getProjectFactorProgressReports(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorProgressReports.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorProgressReports.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgressReport>>(`${this.configuration.basePath}/projectfactorprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorResultReportRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project factor result report
     * Get specific project result report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param resultReportResourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ResultReport>;
    public getProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ResultReport>>;
    public getProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ResultReport>>;
    public getProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorResultReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorResultReport.');
        }
        if (resultReportResourceIdentifier === null || resultReportResourceIdentifier === undefined) {
            throw new Error('Required parameter resultReportResourceIdentifier was null or undefined when calling getProjectFactorResultReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ResultReport>(`${this.configuration.basePath}/projectfactorresultreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(resultReportResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ResultReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ResultReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorResultReportsRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project factor result reports
     * Get list of result reports for Project factor (descending date order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorResultReports(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ResultReport>>;
    public getProjectFactorResultReports(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ResultReport>>>;
    public getProjectFactorResultReports(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ResultReport>>>;
    public getProjectFactorResultReports(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorResultReports.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorResultReports.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ResultReport>>(`${this.configuration.basePath}/projectfactorresultreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ResultReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ResultReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorSummaryRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Factor Summary by Project Identifier
     * Returns Project Factor Summary
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorSummary(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorSummary>>;
    public getProjectFactorSummary(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorSummary>>>;
    public getProjectFactorSummary(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorSummary>>>;
    public getProjectFactorSummary(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorSummary>>(`${this.configuration.basePath}/projectfactorsummary/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorSummary(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorSummary(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorSummaryForFactorTypeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Factor Summary by Project Identifier, Factor Type
     * Returns Project Factor Summary (for specified factor type)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorSummaryForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorSummary>>;
    public getProjectFactorSummaryForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorSummary>>>;
    public getProjectFactorSummaryForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorSummary>>>;
    public getProjectFactorSummaryForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorSummaryForFactorType.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling getProjectFactorSummaryForFactorType.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorSummary>>(`${this.configuration.basePath}/projectfactorsummarybytype/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorSummary(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorSummary(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorSummaryReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Factor Summary report by Project Identifier
     * Returns Project Factor Summary (report)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorSummaryReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorSummaryReport>;
    public getProjectFactorSummaryReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorSummaryReport>>;
    public getProjectFactorSummaryReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorSummaryReport>>;
    public getProjectFactorSummaryReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorSummaryReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorSummaryReport>(`${this.configuration.basePath}/projectfactorsummaryreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorSummaryReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorSummaryReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorTaxonomiesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Taxonomies by Factor Identifier
     * Returns Factor Taxonomies for specific Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorTaxonomies(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorTaxonomy>>;
    public getProjectFactorTaxonomies(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorTaxonomy>>>;
    public getProjectFactorTaxonomies(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorTaxonomy>>>;
    public getProjectFactorTaxonomies(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorTaxonomies.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorTaxonomies.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorTaxonomy>>(`${this.configuration.basePath}/projectfactortaxonomies/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTaxonomy(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTaxonomy(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorTaxonomiesForFactorTypeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Taxonomies by Factor Type
     * Returns Factor Taxonomies for specific Factor Type
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorTaxonomiesForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorTaxonomy>>;
    public getProjectFactorTaxonomiesForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorTaxonomy>>>;
    public getProjectFactorTaxonomiesForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorTaxonomy>>>;
    public getProjectFactorTaxonomiesForFactorType(projectIdentifier: string, factorTypePathParam: FactorType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorTaxonomiesForFactorType.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling getProjectFactorTaxonomiesForFactorType.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorTaxonomy>>(`${this.configuration.basePath}/projectfactortaxonomiesbytype/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTaxonomy(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTaxonomy(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorTaxonomyRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Taxonomy by Factor Identifier / Taxonomy Version Identifier
     * Returns Factor Taxonomy for specific Factor &amp; Taxonomy Version
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param taxonomyVersionResourceIdentifier Taxonomy Version Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorTaxonomy>;
    public getProjectFactorTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorTaxonomy>>;
    public getProjectFactorTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorTaxonomy>>;
    public getProjectFactorTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorTaxonomy.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorTaxonomy.');
        }
        if (taxonomyVersionResourceIdentifier === null || taxonomyVersionResourceIdentifier === undefined) {
            throw new Error('Required parameter taxonomyVersionResourceIdentifier was null or undefined when calling getProjectFactorTaxonomy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorTaxonomy>(`${this.configuration.basePath}/projectfactortaxonomies/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(taxonomyVersionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTaxonomy(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTaxonomy(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorTaxonomyClassificationsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Taxonomy Classifications by Project Identifier
     * Returns Factor Taxonomy Classifications for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorTaxonomyClassifications(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorTaxonomyClassification>>;
    public getProjectFactorTaxonomyClassifications(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorTaxonomyClassification>>>;
    public getProjectFactorTaxonomyClassifications(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorTaxonomyClassification>>>;
    public getProjectFactorTaxonomyClassifications(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorTaxonomyClassification>>(`${this.configuration.basePath}/projectfactortaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorTaxonomyClassificationsForFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Taxonomy Classifications for specific Factor
     * Returns Factor Taxonomy Classifications for Project for specific Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorTaxonomyClassificationsForFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorTaxonomyClassification>>;
    public getProjectFactorTaxonomyClassificationsForFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorTaxonomyClassification>>>;
    public getProjectFactorTaxonomyClassificationsForFactor(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorTaxonomyClassification>>>;
    public getProjectFactorTaxonomyClassificationsForFactor(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorTaxonomyClassificationsForFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorTaxonomyClassificationsForFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorTaxonomyClassification>>(`${this.configuration.basePath}/projectfactortaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorTaxonomyClassificationsForFactorAndTaxonomyRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Taxonomy Classifications for specific Factor and Taxonomy Version
     * Returns Factor Taxonomy Classifications for Project for specific Factor for specific Taxonomy Version
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param taxonomyVersionResourceIdentifier Taxonomy Version Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorTaxonomyClassificationsForFactorAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorTaxonomyClassification>;
    public getProjectFactorTaxonomyClassificationsForFactorAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorTaxonomyClassification>>;
    public getProjectFactorTaxonomyClassificationsForFactorAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorTaxonomyClassification>>;
    public getProjectFactorTaxonomyClassificationsForFactorAndTaxonomy(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorTaxonomyClassificationsForFactorAndTaxonomy.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectFactorTaxonomyClassificationsForFactorAndTaxonomy.');
        }
        if (taxonomyVersionResourceIdentifier === null || taxonomyVersionResourceIdentifier === undefined) {
            throw new Error('Required parameter taxonomyVersionResourceIdentifier was null or undefined when calling getProjectFactorTaxonomyClassificationsForFactorAndTaxonomy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorTaxonomyClassification>(`${this.configuration.basePath}/projectfactortaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(taxonomyVersionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTaxonomyClassification(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTaxonomyClassification(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFactorTaxonomyClassificationsReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Factor Taxonomy Classifications by Project Identifier (report)
     * Returns Factor Taxonomy Classifications for Project (report)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFactorTaxonomyClassificationsReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorTaxonomyClassificationsReport>;
    public getProjectFactorTaxonomyClassificationsReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorTaxonomyClassificationsReport>>;
    public getProjectFactorTaxonomyClassificationsReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorTaxonomyClassificationsReport>>;
    public getProjectFactorTaxonomyClassificationsReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectFactorTaxonomyClassificationsReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorTaxonomyClassificationsReport>(`${this.configuration.basePath}/projectfactortaxonomyclassificationsreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorTaxonomyClassificationsReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorTaxonomyClassificationsReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectGoalObjectiveAssessmentReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project goal / objective assessment report by Identifier
     * Returns goal / objective assessment report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param allMeasurements Flag indicating whether all measurements / future statuses should be returned or not (default is latest only)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectGoalObjectiveAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectGoalObjectiveAssessmentReport>;
    public getProjectGoalObjectiveAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectGoalObjectiveAssessmentReport>>;
    public getProjectGoalObjectiveAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectGoalObjectiveAssessmentReport>>;
    public getProjectGoalObjectiveAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectGoalObjectiveAssessmentReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (allMeasurements !== undefined && allMeasurements !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>allMeasurements, 'all_measurements');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectGoalObjectiveAssessmentReport>(`${this.configuration.basePath}/projectgoalobjectiveassessmentreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectGoalObjectiveAssessmentReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectGoalObjectiveAssessmentReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectGoalObjectiveFactorProgressReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project goal / objective factor progress report by Identifier
     * Returns goal / objective factor progress report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectGoalObjectiveFactorProgressReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectGoalObjectiveFactorProgressReport>;
    public getProjectGoalObjectiveFactorProgressReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectGoalObjectiveFactorProgressReport>>;
    public getProjectGoalObjectiveFactorProgressReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectGoalObjectiveFactorProgressReport>>;
    public getProjectGoalObjectiveFactorProgressReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectGoalObjectiveFactorProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectGoalObjectiveFactorProgressReport>(`${this.configuration.basePath}/projectgoalobjectivefactorprogressreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectGoalObjectiveFactorProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectGoalObjectiveFactorProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectImportStatusRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project (file) import status by Identifier
     * Returns import status for a specific Project (file)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectImportStatus(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectImportStatus>;
    public getProjectImportStatus(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectImportStatus>>;
    public getProjectImportStatus(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectImportStatus>>;
    public getProjectImportStatus(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectImportStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectImportStatus>(`${this.configuration.basePath}/projectimportstatus/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectImportStatus(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectImportStatus(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectIndicatorScorecardFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get factors for indicator scorecard
     * Get factors for indicator scorecard
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectIndicatorScorecardFactors(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorScorecardFactors>;
    public getProjectIndicatorScorecardFactors(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorScorecardFactors>>;
    public getProjectIndicatorScorecardFactors(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorScorecardFactors>>;
    public getProjectIndicatorScorecardFactors(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectIndicatorScorecardFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorScorecardFactors>(`${this.configuration.basePath}/indicatorscorecardfactors/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorScorecardFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorScorecardFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectIndicatorScorecardIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicators for scorecard
     * Get Indicators for scorecard
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param scorecardResourceIdentifier Scorecard Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectIndicatorScorecardIndicators(projectIdentifier: string, scorecardResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IndicatorScorecardDetails>;
    public getProjectIndicatorScorecardIndicators(projectIdentifier: string, scorecardResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IndicatorScorecardDetails>>;
    public getProjectIndicatorScorecardIndicators(projectIdentifier: string, scorecardResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IndicatorScorecardDetails>>;
    public getProjectIndicatorScorecardIndicators(projectIdentifier: string, scorecardResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectIndicatorScorecardIndicators.');
        }
        if (scorecardResourceIdentifier === null || scorecardResourceIdentifier === undefined) {
            throw new Error('Required parameter scorecardResourceIdentifier was null or undefined when calling getProjectIndicatorScorecardIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<IndicatorScorecardDetails>(`${this.configuration.basePath}/indicatorscorecardindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(scorecardResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorScorecardDetails(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorScorecardDetails(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectIndicatorScorecardsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Indicator scorecards
     * Get list of indicator scorecards for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectIndicatorScorecards(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<IndicatorScorecard>>;
    public getProjectIndicatorScorecards(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<IndicatorScorecard>>>;
    public getProjectIndicatorScorecards(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<IndicatorScorecard>>>;
    public getProjectIndicatorScorecards(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectIndicatorScorecards.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<IndicatorScorecard>>(`${this.configuration.basePath}/indicatorscorecards/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new IndicatorScorecard(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new IndicatorScorecard(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectLocationRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project location by Identifier
     * Returns location for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectLocation(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectLocation>;
    public getProjectLocation(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectLocation>>;
    public getProjectLocation(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectLocation>>;
    public getProjectLocation(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectLocation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectLocation>(`${this.configuration.basePath}/projectlocation/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectLocation(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectLocation(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectMapRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Find Project map data by Identifier
     * Returns map data for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectMap(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectMap>;
    public getProjectMap(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectMap>>;
    public getProjectMap(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectMap>>;
    public getProjectMap(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectMap.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectMap>(`${this.configuration.basePath}/projectmap/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectMap(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectMap(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectOwnerRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get current Project owner
     * Returns current Project owner
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectOwner(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectOwner>;
    public getProjectOwner(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectOwner>>;
    public getProjectOwner(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectOwner>>;
    public getProjectOwner(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectOwner.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectOwner>(`${this.configuration.basePath}/projectowner/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectOwner(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectOwner(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectProgressRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project progress
     * Get progress report hierarchy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectProgress(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectProgress>;
    public getProjectProgress(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectProgress>>;
    public getProjectProgress(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectProgress>>;
    public getProjectProgress(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectProgress>(`${this.configuration.basePath}/projectprogress/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectProgress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectProgress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectProgressReportRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project progress report
     * Get specific project progress report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgressReport>;
    public getProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgressReport>>;
    public getProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgressReport>>;
    public getProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectProgressReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgressReport>(`${this.configuration.basePath}/projectprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectProgressReportsRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project progress reports
     * Get list of project progress reports for Project (descending date order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectProgressReports(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgressReport>>;
    public getProjectProgressReports(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgressReport>>>;
    public getProjectProgressReports(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgressReport>>>;
    public getProjectProgressReports(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectProgressReports.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgressReport>>(`${this.configuration.basePath}/projectprogressreports/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectPublishStatusRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Find Project publication status by Identifier
     * Returns publication status for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectPublishStatus(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectPublishStatus>;
    public getProjectPublishStatus(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectPublishStatus>>;
    public getProjectPublishStatus(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectPublishStatus>>;
    public getProjectPublishStatus(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectPublishStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectPublishStatus>(`${this.configuration.basePath}/projectpublishstatus/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectPublishStatus(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectPublishStatus(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectReportDocumentRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project report document
     * Get rendered project report document
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectReportDocument(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<Blob>;
    public getProjectReportDocument(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getProjectReportDocument(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getProjectReportDocument(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectReportDocument.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectReportDocument.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'multipart/form-data',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.get(`${this.configuration.basePath}/projectreportdocuments/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Blob(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Blob(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectReportDocumentsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project report documents
     * Get list of project report documents for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectReportDocuments(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectReportDocument>>;
    public getProjectReportDocuments(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectReportDocument>>>;
    public getProjectReportDocuments(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectReportDocument>>>;
    public getProjectReportDocuments(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectReportDocuments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectReportDocument>>(`${this.configuration.basePath}/projectreportdocuments/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectReportDocument(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectReportDocument(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectResourceRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project resource
     * Get specific project resource for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectResource(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResource>;
    public getProjectResource(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResource>>;
    public getProjectResource(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResource>>;
    public getProjectResource(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectResource>(`${this.configuration.basePath}/projectresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectResourcesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project resources
     * Get list of project resources for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectResources(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectResource>>;
    public getProjectResources(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectResource>>>;
    public getProjectResources(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectResource>>>;
    public getProjectResources(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectResources.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectResource>>(`${this.configuration.basePath}/projectresources/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectResultsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project results
     * Get result report hierarchy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectResults(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResults>;
    public getProjectResults(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResults>>;
    public getProjectResults(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResults>>;
    public getProjectResults(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectResults.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectResults>(`${this.configuration.basePath}/projectresults/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResults(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResults(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectResultsProgressReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project results progress report by Identifier
     * Returns results progress report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param allResults Flag indicating whether all result reports should be returned or not (default is latest only)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectResultsProgressReport(projectIdentifier: string, allResults?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResultsProgressReport>;
    public getProjectResultsProgressReport(projectIdentifier: string, allResults?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResultsProgressReport>>;
    public getProjectResultsProgressReport(projectIdentifier: string, allResults?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResultsProgressReport>>;
    public getProjectResultsProgressReport(projectIdentifier: string, allResults?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectResultsProgressReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (allResults !== undefined && allResults !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>allResults, 'all_results');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectResultsProgressReport>(`${this.configuration.basePath}/projectresultsprogressreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResultsProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResultsProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectResultsTrackingRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project results tracking
     * Get results tracking hierarchy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectResultsTracking(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResultsTracking>;
    public getProjectResultsTracking(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResultsTracking>>;
    public getProjectResultsTracking(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResultsTracking>>;
    public getProjectResultsTracking(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectResultsTracking.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectResultsTracking>(`${this.configuration.basePath}/projectresultstracking/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResultsTracking(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResultsTracking(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectScopeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project scope / vision by Identifier
     * Returns scope / vision for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectScope(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectScope>;
    public getProjectScope(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectScope>>;
    public getProjectScope(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectScope>>;
    public getProjectScope(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectScope.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectScope>(`${this.configuration.basePath}/projectscope/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectScope(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectScope(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectScopeBiodiversityFeaturesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project scope biodiversity features by Identifier
     * Returns scope biodiversity features for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectScopeBiodiversityFeatures(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectScopeBiodiversityFeatures>;
    public getProjectScopeBiodiversityFeatures(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectScopeBiodiversityFeatures>>;
    public getProjectScopeBiodiversityFeatures(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectScopeBiodiversityFeatures>>;
    public getProjectScopeBiodiversityFeatures(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectScopeBiodiversityFeatures.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectScopeBiodiversityFeatures>(`${this.configuration.basePath}/projectscopebiodiversityfeatures/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectScopeBiodiversityFeatures(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectScopeBiodiversityFeatures(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectScopeHumanStakeholdersRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project scope human stakeholders by Identifier
     * Returns scope human stakeholders for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectScopeHumanStakeholders(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectScopeHumanStakeholders>;
    public getProjectScopeHumanStakeholders(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectScopeHumanStakeholders>>;
    public getProjectScopeHumanStakeholders(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectScopeHumanStakeholders>>;
    public getProjectScopeHumanStakeholders(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectScopeHumanStakeholders.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectScopeHumanStakeholders>(`${this.configuration.basePath}/projectscopehumanstakeholders/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectScopeHumanStakeholders(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectScopeHumanStakeholders(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectScopeProtectedAreaInformationRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project scope protected area information by Identifier
     * Returns scope protected area information for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectScopeProtectedAreaInformation(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectScopeProtectedAreaInformation>;
    public getProjectScopeProtectedAreaInformation(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectScopeProtectedAreaInformation>>;
    public getProjectScopeProtectedAreaInformation(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectScopeProtectedAreaInformation>>;
    public getProjectScopeProtectedAreaInformation(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectScopeProtectedAreaInformation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectScopeProtectedAreaInformation>(`${this.configuration.basePath}/projectscopeprotectedareainformation/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectScopeProtectedAreaInformation(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectScopeProtectedAreaInformation(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectShareableFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Shareable Factors by Project Identifier
     * Returns Project Shareable Factors
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectShareableFactors(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorShareable>>;
    public getProjectShareableFactors(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorShareable>>>;
    public getProjectShareableFactors(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorShareable>>>;
    public getProjectShareableFactors(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectShareableFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorShareable>>(`${this.configuration.basePath}/projectshareablefactors/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorShareable(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorShareable(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectSlideshowImageRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project slideshow image
     * Get specific project slideshow image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SlideShowImage>;
    public getProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SlideShowImage>>;
    public getProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SlideShowImage>>;
    public getProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectSlideshowImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SlideShowImage>(`${this.configuration.basePath}/projectslideshowimages/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SlideShowImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SlideShowImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectSlideshowImagesRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project slideshow images
     * Get list of project slideshow images for Project (in image order)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectSlideshowImages(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<SlideShowImage>>;
    public getProjectSlideshowImages(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<SlideShowImage>>>;
    public getProjectSlideshowImages(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<SlideShowImage>>>;
    public getProjectSlideshowImages(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectSlideshowImages.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SlideShowImage>>(`${this.configuration.basePath}/projectslideshowimages/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SlideShowImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SlideShowImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectSnapshotRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project snapshot
     * Get specific snapshot for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectSnapshot>;
    public getProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectSnapshot>>;
    public getProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectSnapshot>>;
    public getProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectSnapshot.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectSnapshot.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectSnapshot>(`${this.configuration.basePath}/projectsnapshots/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectSnapshot(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectSnapshot(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectSnapshotsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get snapshots by Project Identifier
     * Returns snapshots for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectSnapshots(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectSnapshot>>;
    public getProjectSnapshots(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectSnapshot>>>;
    public getProjectSnapshots(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectSnapshot>>>;
    public getProjectSnapshots(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectSnapshots.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectSnapshot>>(`${this.configuration.basePath}/projectsnapshots/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectSnapshot(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectSnapshot(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectStrategyActivitiesRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Strategy activities
     * Get list of Activities for Strategies for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param latestProgressReport Flag indicating whether latest progres report should be returned or not (default is false)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectStrategyActivities(projectIdentifier: string, latestProgressReport?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectStrategyActivity>;
    public getProjectStrategyActivities(projectIdentifier: string, latestProgressReport?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectStrategyActivity>>;
    public getProjectStrategyActivities(projectIdentifier: string, latestProgressReport?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectStrategyActivity>>;
    public getProjectStrategyActivities(projectIdentifier: string, latestProgressReport?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectStrategyActivities.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (latestProgressReport !== undefined && latestProgressReport !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>latestProgressReport, 'latest_progress_report');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectStrategyActivity>(`${this.configuration.basePath}/projectstrategyactivities/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectStrategyActivity(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectStrategyActivity(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectStrategyActivityProgressReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project strategy / activity progress report by Identifier
     * Returns strategy / activity progress report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectStrategyActivityProgressReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectStrategyActivityProgressReport>;
    public getProjectStrategyActivityProgressReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectStrategyActivityProgressReport>>;
    public getProjectStrategyActivityProgressReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectStrategyActivityProgressReport>>;
    public getProjectStrategyActivityProgressReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectStrategyActivityProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectStrategyActivityProgressReport>(`${this.configuration.basePath}/projectstrategyactivityprogressreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectStrategyActivityProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectStrategyActivityProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectStrategyExpensesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project strategy expense assignments
     * Get list of Expense Assignments for Strategies across Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectStrategyExpenses(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectStrategyExpense>;
    public getProjectStrategyExpenses(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectStrategyExpense>>;
    public getProjectStrategyExpenses(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectStrategyExpense>>;
    public getProjectStrategyExpenses(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectStrategyExpenses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectStrategyExpense>(`${this.configuration.basePath}/projectstrategyexpenses/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectStrategyExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectStrategyExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectStrategyObjectivesRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Strategy objectives
     * Get list of Objectives for Strategy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectStrategyObjectives(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectStrategyObjective>;
    public getProjectStrategyObjectives(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectStrategyObjective>>;
    public getProjectStrategyObjectives(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectStrategyObjective>>;
    public getProjectStrategyObjectives(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectStrategyObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectStrategyObjective>(`${this.configuration.basePath}/projectstrategyobjectives/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectStrategyObjective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectStrategyObjective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectSummaryRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Find Project summary by Identifier
     * Returns summary for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectSummary(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectSummary>;
    public getProjectSummary(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectSummary>>;
    public getProjectSummary(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectSummary>>;
    public getProjectSummary(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectSummary>(`${this.configuration.basePath}/projectsummary/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectSummary(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectSummary(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectTargetKeaIndicatorTaxonomyClassificationsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get KEA Indicator Taxonomy Classifications
     * Get list of Taxonomy Classifications for Indicators for KEAs for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectTargetKeaIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>;
    public getProjectTargetKeaIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>>;
    public getProjectTargetKeaIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>>;
    public getProjectTargetKeaIndicatorTaxonomyClassifications(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectTargetKeaIndicatorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>(`${this.configuration.basePath}/projecttargetkeaindicatortaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectTargetKeaIndicatorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Target KEA indicators
     * Get list of Key Ecological Attribute Indicators for Targets for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectTargetKeaIndicators(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectTargetKeyEcologicalAttribute>;
    public getProjectTargetKeaIndicators(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectTargetKeyEcologicalAttribute>>;
    public getProjectTargetKeaIndicators(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectTargetKeyEcologicalAttribute>>;
    public getProjectTargetKeaIndicators(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectTargetKeaIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectTargetKeyEcologicalAttribute>(`${this.configuration.basePath}/projecttargetkeaindicators/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTargetKeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTargetKeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectTargetThreatRatingsReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project target / threat ratings report by Identifier
     * Returns target / threat ratings report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectTargetThreatRatingsReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectTargetThreatRatingsReport>;
    public getProjectTargetThreatRatingsReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectTargetThreatRatingsReport>>;
    public getProjectTargetThreatRatingsReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectTargetThreatRatingsReport>>;
    public getProjectTargetThreatRatingsReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectTargetThreatRatingsReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectTargetThreatRatingsReport>(`${this.configuration.basePath}/projecttargetthreatratingsreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTargetThreatRatingsReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTargetThreatRatingsReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectTeamMemberRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get Project team member
     * Get specific team member for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectTeamMember>;
    public getProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectTeamMember>>;
    public getProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectTeamMember>>;
    public getProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectTeamMember.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectTeamMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectTeamMember>(`${this.configuration.basePath}/projectteammembers/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTeamMember(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTeamMember(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectTeamMembersRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Get team members by Project Identifier
     * Returns team members for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectTeamMembers(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectTeamMember>>;
    public getProjectTeamMembers(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectTeamMember>>>;
    public getProjectTeamMembers(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectTeamMember>>>;
    public getProjectTeamMembers(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectTeamMembers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectTeamMember>>(`${this.configuration.basePath}/projectteammembers/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTeamMember(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTeamMember(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectThreatIndicatorTaxonomyClassificationsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Indicator Taxonomy Classifications
     * Get list of Taxonomy Classifications for Indicators for Threats for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectThreatIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectWithFactorIndicatorTaxonomyClassifications>;
    public getProjectThreatIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectWithFactorIndicatorTaxonomyClassifications>>;
    public getProjectThreatIndicatorTaxonomyClassifications(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectWithFactorIndicatorTaxonomyClassifications>>;
    public getProjectThreatIndicatorTaxonomyClassifications(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectThreatIndicatorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectWithFactorIndicatorTaxonomyClassifications>(`${this.configuration.basePath}/projectthreatindicatortaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectThreatIndicatorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Threat indicators
     * Get list of Indicators for Threats for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectThreatIndicators(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFactorIndicator>;
    public getProjectThreatIndicators(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFactorIndicator>>;
    public getProjectThreatIndicators(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFactorIndicator>>;
    public getProjectThreatIndicators(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectThreatIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectFactorIndicator>(`${this.configuration.basePath}/projectthreatindicators/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorIndicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorIndicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectThreatRatingsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project threat ratings
     * Get threat ratings for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectThreatRatings(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ThreatRatings>;
    public getProjectThreatRatings(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ThreatRatings>>;
    public getProjectThreatRatings(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ThreatRatings>>;
    public getProjectThreatRatings(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectThreatRatings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ThreatRatings>(`${this.configuration.basePath}/projectthreatratings/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ThreatRatings(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ThreatRatings(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectThreatTargetThreatRatingRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Threat Target threat ratings
     * Get threat ratings for Project Threat Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param threatResourceIdentifier Threat Unique identifier
     * @param targetResourceIdentifier Target Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TargetSimpleThreatRating>;
    public getProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TargetSimpleThreatRating>>;
    public getProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TargetSimpleThreatRating>>;
    public getProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectThreatTargetThreatRating.');
        }
        if (threatResourceIdentifier === null || threatResourceIdentifier === undefined) {
            throw new Error('Required parameter threatResourceIdentifier was null or undefined when calling getProjectThreatTargetThreatRating.');
        }
        if (targetResourceIdentifier === null || targetResourceIdentifier === undefined) {
            throw new Error('Required parameter targetResourceIdentifier was null or undefined when calling getProjectThreatTargetThreatRating.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TargetSimpleThreatRating>(`${this.configuration.basePath}/projectthreatratings/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(threatResourceIdentifier))}/${encodeURIComponent(String(targetResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new TargetSimpleThreatRating(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new TargetSimpleThreatRating(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectTimeframeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project timeframe by Identifier
     * Returns timeframe for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectTimeframe(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectTimeframe>;
    public getProjectTimeframe(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectTimeframe>>;
    public getProjectTimeframe(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectTimeframe>>;
    public getProjectTimeframe(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectTimeframe>(`${this.configuration.basePath}/projecttimeframe/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTimeframe(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTimeframe(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectTimelineRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project timeline by Identifier
     * Returns timeline for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectTimeline(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectTimeline>;
    public getProjectTimeline(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectTimeline>>;
    public getProjectTimeline(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectTimeline>>;
    public getProjectTimeline(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectTimeline.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectTimeline>(`${this.configuration.basePath}/projecttimeline/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTimeline(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTimeline(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectViabilityAssessmentReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project viability assessment report by Identifier
     * Returns viability assessment report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param allMeasurements Flag indicating whether all measurements / future statuses should be returned or not (default is latest only)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectViabilityAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectViabilityAssessmentReport>;
    public getProjectViabilityAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectViabilityAssessmentReport>>;
    public getProjectViabilityAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectViabilityAssessmentReport>>;
    public getProjectViabilityAssessmentReport(projectIdentifier: string, allMeasurements?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectViabilityAssessmentReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (allMeasurements !== undefined && allMeasurements !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>allMeasurements, 'all_measurements');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectViabilityAssessmentReport>(`${this.configuration.basePath}/projectviabilityassessmentreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectViabilityAssessmentReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectViabilityAssessmentReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectViabilityMonitoringRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project viability monitoring
     * Get viability monitoring hierarchy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectViabilityMonitoring(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectViabilityMonitoring>;
    public getProjectViabilityMonitoring(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectViabilityMonitoring>>;
    public getProjectViabilityMonitoring(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectViabilityMonitoring>>;
    public getProjectViabilityMonitoring(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectViabilityMonitoring.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectViabilityMonitoring>(`${this.configuration.basePath}/projectviabilitymonitoring/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectViabilityMonitoring(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectViabilityMonitoring(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectWorkPlanReportRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Project work plan report by Identifier
     * Returns work plan report for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectWorkPlanReport(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectWorkPlanReport>;
    public getProjectWorkPlanReport(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectWorkPlanReport>>;
    public getProjectWorkPlanReport(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectWorkPlanReport>>;
    public getProjectWorkPlanReport(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectWorkPlanReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectWorkPlanReport>(`${this.configuration.basePath}/projectworkplanreport/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectWorkPlanReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectWorkPlanReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get list of Projects
     * Get list of Projects accessible to end-user
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param projectFeaturePermission Feature permission for project (defaults to ViewProjectBasic if not supplied)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjects(programIdentifier?: string, projectFeaturePermission?: ProjectFeature, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Project>>;
    public getProjects(programIdentifier?: string, projectFeaturePermission?: ProjectFeature, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Project>>>;
    public getProjects(programIdentifier?: string, projectFeaturePermission?: ProjectFeature, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Project>>>;
    public getProjects(programIdentifier?: string, projectFeaturePermission?: ProjectFeature, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (programIdentifier !== undefined && programIdentifier !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>programIdentifier, 'program_identifier');
        }
        if (projectFeaturePermission !== undefined && projectFeaturePermission !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectFeaturePermission, 'project_feature_permission');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Project>>(`${this.configuration.basePath}/projects`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Project(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Project(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategiesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategies
     * Get strategies for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategies(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Strategy>>;
    public getStrategies(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Strategy>>>;
    public getStrategies(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Strategy>>>;
    public getStrategies(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategies.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Strategy>>(`${this.configuration.basePath}/strategies/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Strategy(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Strategy(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy
     * Get specific strategy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategy(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Strategy>;
    public getStrategy(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Strategy>>;
    public getStrategy(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Strategy>>;
    public getStrategy(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategy.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Strategy>(`${this.configuration.basePath}/strategies/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Strategy(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Strategy(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivitiesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy activities
     * Get list of activities for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivities(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<StrategyActivity>>;
    public getStrategyActivities(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<StrategyActivity>>>;
    public getStrategyActivities(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<StrategyActivity>>>;
    public getStrategyActivities(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivities.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivities.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<StrategyActivity>>(`${this.configuration.basePath}/strategyactivities/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyActivity(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyActivity(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy activity
     * Get specific activity for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StrategyActivity>;
    public getStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StrategyActivity>>;
    public getStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StrategyActivity>>;
    public getStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivity.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivity.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivity.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StrategyActivity>(`${this.configuration.basePath}/strategyactivities/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyActivity(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyActivity(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityExpenseRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity expense assignment
     * Get specific expense assignment for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpense>;
    public getStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpense>>;
    public getStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpense>>;
    public getStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getStrategyActivityExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorExpense>(`${this.configuration.basePath}/strategyactivityexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityExpenseDetailRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity expense assignment detail
     * Get specific expense detail of Activity expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpenseDetail>;
    public getStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpenseDetail>>;
    public getStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpenseDetail>>;
    public getStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getStrategyActivityExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling getStrategyActivityExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorExpenseDetail>(`${this.configuration.basePath}/strategyactivityexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityExpenseDetailsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity expense assignment details
     * Get expense details for Activity expense
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityExpenseDetails(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorExpenseDetail>>;
    public getStrategyActivityExpenseDetails(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorExpenseDetail>>>;
    public getStrategyActivityExpenseDetails(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorExpenseDetail>>>;
    public getStrategyActivityExpenseDetails(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityExpenseDetails.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityExpenseDetails.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityExpenseDetails.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getStrategyActivityExpenseDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorExpenseDetail>>(`${this.configuration.basePath}/strategyactivityexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityExpensesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity expense assignments
     * Get list of expenses assignments for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityExpenses(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorExpense>>;
    public getStrategyActivityExpenses(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorExpense>>>;
    public getStrategyActivityExpenses(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorExpense>>>;
    public getStrategyActivityExpenses(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityExpenses.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityExpenses.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityExpenses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorExpense>>(`${this.configuration.basePath}/strategyactivityexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityLeaderRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Activity leader (project resource) by Identifier
     * Returns leader (project resource) for a specific Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResource>;
    public getStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResource>>;
    public getStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResource>>;
    public getStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityLeader.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityLeader.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectResource>(`${this.configuration.basePath}/strategyactivityleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityOrderRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy activity order
     * Get order of activities for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ResourceIdentifierList>;
    public getStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ResourceIdentifierList>>;
    public getStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ResourceIdentifierList>>;
    public getStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityOrder.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ResourceIdentifierList>(`${this.configuration.basePath}/strategyactivityorder/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ResourceIdentifierList(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ResourceIdentifierList(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Activity
     * Get relevant factors for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StrategyActivityRelevantFactors>;
    public getStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StrategyActivityRelevantFactors>>;
    public getStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StrategyActivityRelevantFactors>>;
    public getStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityRelevantFactors.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StrategyActivityRelevantFactors>(`${this.configuration.basePath}/strategyactivityrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyActivityRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyActivityRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityResourceRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity resource assignment
     * Get specific resource assigned to Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param projectResourceIdentifier Project Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorProjectResource>;
    public getStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorProjectResource>>;
    public getStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorProjectResource>>;
    public getStrategyActivityResource(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityResource.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityResource.');
        }
        if (projectResourceIdentifier === null || projectResourceIdentifier === undefined) {
            throw new Error('Required parameter projectResourceIdentifier was null or undefined when calling getStrategyActivityResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorProjectResource>(`${this.configuration.basePath}/strategyactivityresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(projectResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityResourcesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Activity project resource assignments
     * Get list of project resources assigned to Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityResources(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorProjectResource>>;
    public getStrategyActivityResources(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorProjectResource>>>;
    public getStrategyActivityResources(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorProjectResource>>>;
    public getStrategyActivityResources(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityResources.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityResources.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityResources.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorProjectResource>>(`${this.configuration.basePath}/strategyactivityresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityTimeframeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Activity timeframe by Identifier
     * Returns timeframe for a specific Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorTimeframeDetail>;
    public getStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorTimeframeDetail>>;
    public getStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorTimeframeDetail>>;
    public getStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityTimeframe.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityTimeframe.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorTimeframeDetail>(`${this.configuration.basePath}/strategyactivitytimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTimeframeDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTimeframeDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyExpenseRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy expense assignment
     * Get specific expense assignment for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpense>;
    public getStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpense>>;
    public getStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpense>>;
    public getStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getStrategyExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorExpense>(`${this.configuration.basePath}/strategyexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyExpenseDetailRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy expense assignment detail
     * Get specific expense detail of Strategy expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorExpenseDetail>;
    public getStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorExpenseDetail>>;
    public getStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorExpenseDetail>>;
    public getStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getStrategyExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling getStrategyExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorExpenseDetail>(`${this.configuration.basePath}/strategyexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyExpenseDetailsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy expense assignment details
     * Get expense details for Strategy expense
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyExpenseDetails(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorExpenseDetail>>;
    public getStrategyExpenseDetails(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorExpenseDetail>>>;
    public getStrategyExpenseDetails(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorExpenseDetail>>>;
    public getStrategyExpenseDetails(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyExpenseDetails.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyExpenseDetails.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling getStrategyExpenseDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorExpenseDetail>>(`${this.configuration.basePath}/strategyexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpenseDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpenseDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyExpensesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy expense assignments
     * Get list of expenses assigned to Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyExpenses(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorExpense>>;
    public getStrategyExpenses(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorExpense>>>;
    public getStrategyExpenses(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorExpense>>>;
    public getStrategyExpenses(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyExpenses.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyExpenses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorExpense>>(`${this.configuration.basePath}/strategyexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy indicator
     * Get specific indicator for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getStrategyIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/strategyindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy indicators
     * Get list of indicators for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getStrategyIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getStrategyIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getStrategyIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/strategyindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyLeaderRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Strategy leader (project resource) by Identifier
     * Returns leader (project resource) for a specific Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectResource>;
    public getStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectResource>>;
    public getStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectResource>>;
    public getStrategyLeader(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyLeader.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectResource>(`${this.configuration.basePath}/strategyleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy objective
     * Get specific objective for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling getStrategyObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/strategyobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyObjectivesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy objectives
     * Get list of objectives for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Objective>>;
    public getStrategyObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Objective>>>;
    public getStrategyObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Objective>>>;
    public getStrategyObjectives(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyObjectives.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Objective>>(`${this.configuration.basePath}/strategyobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyOutputRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy output
     * Get specific output for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Output>;
    public getStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Output>>;
    public getStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Output>>;
    public getStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyOutput.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling getStrategyOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Output>(`${this.configuration.basePath}/strategyoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyOutputsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy outputs
     * Get list of outputs for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyOutputs(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Output>>;
    public getStrategyOutputs(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Output>>>;
    public getStrategyOutputs(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Output>>>;
    public getStrategyOutputs(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyOutputs.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyOutputs.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Output>>(`${this.configuration.basePath}/strategyoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Output(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Output(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyRatingRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Calculate Strategy rating
     * Calculate overall rating for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param strategyImpactRating Strategy impact rating
     * @param strategyFeasibilityRating Strategy feasibility rating
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyRating(projectIdentifier: string, strategyImpactRating: StrategyImpactRatingEnum, strategyFeasibilityRating: StrategyFeasibilityRatingEnum, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StrategyRating>;
    public getStrategyRating(projectIdentifier: string, strategyImpactRating: StrategyImpactRatingEnum, strategyFeasibilityRating: StrategyFeasibilityRatingEnum, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StrategyRating>>;
    public getStrategyRating(projectIdentifier: string, strategyImpactRating: StrategyImpactRatingEnum, strategyFeasibilityRating: StrategyFeasibilityRatingEnum, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StrategyRating>>;
    public getStrategyRating(projectIdentifier: string, strategyImpactRating: StrategyImpactRatingEnum, strategyFeasibilityRating: StrategyFeasibilityRatingEnum, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyRating.');
        }
        if (strategyImpactRating === null || strategyImpactRating === undefined) {
            throw new Error('Required parameter strategyImpactRating was null or undefined when calling getStrategyRating.');
        }
        if (strategyFeasibilityRating === null || strategyFeasibilityRating === undefined) {
            throw new Error('Required parameter strategyFeasibilityRating was null or undefined when calling getStrategyRating.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (strategyImpactRating !== undefined && strategyImpactRating !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>strategyImpactRating, 'strategy_impact_rating');
        }
        if (strategyFeasibilityRating !== undefined && strategyFeasibilityRating !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>strategyFeasibilityRating, 'strategy_feasibility_rating');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StrategyRating>(`${this.configuration.basePath}/strategyrating/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyRating(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyRating(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Strategy
     * Get relevant factors for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StrategyRelevantFactors>;
    public getStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StrategyRelevantFactors>>;
    public getStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StrategyRelevantFactors>>;
    public getStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StrategyRelevantFactors>(`${this.configuration.basePath}/strategyrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyResourceRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy resource assignment
     * Get specific resource assigned to Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectResourceIdentifier Project Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorProjectResource>;
    public getStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorProjectResource>>;
    public getStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorProjectResource>>;
    public getStrategyResource(projectIdentifier: string, resourceIdentifier: string, projectResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyResource.');
        }
        if (projectResourceIdentifier === null || projectResourceIdentifier === undefined) {
            throw new Error('Required parameter projectResourceIdentifier was null or undefined when calling getStrategyResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorProjectResource>(`${this.configuration.basePath}/strategyresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(projectResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyResourcesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Strategy project resource assignments
     * Get list of project resources assigned to Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyResources(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FactorProjectResource>>;
    public getStrategyResources(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FactorProjectResource>>>;
    public getStrategyResources(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FactorProjectResource>>>;
    public getStrategyResources(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyResources.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyResources.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FactorProjectResource>>(`${this.configuration.basePath}/strategyresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorProjectResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorProjectResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyTimeframeRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Find Strategy timeframe by Identifier
     * Returns timeframe for a specific Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorTimeframeDetail>;
    public getStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorTimeframeDetail>>;
    public getStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorTimeframeDetail>>;
    public getStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyTimeframe.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorTimeframeDetail>(`${this.configuration.basePath}/strategytimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorTimeframeDetail(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorTimeframeDetail(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStressRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get stress
     * Get specific stress for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStress(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Stress>;
    public getStress(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Stress>>;
    public getStress(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Stress>>;
    public getStress(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStress.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Stress>(`${this.configuration.basePath}/stresses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Stress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Stress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStressRatingRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Calculate Stress rating
     * Calculate overall rating for Stress
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param stressScopeRating Stress scope rating
     * @param stressSeverityRating Stress severity rating
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStressRating(projectIdentifier: string, stressScopeRating: StressScopeRatingEnum, stressSeverityRating: StressSeverityRatingEnum, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StressRating>;
    public getStressRating(projectIdentifier: string, stressScopeRating: StressScopeRatingEnum, stressSeverityRating: StressSeverityRatingEnum, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StressRating>>;
    public getStressRating(projectIdentifier: string, stressScopeRating: StressScopeRatingEnum, stressSeverityRating: StressSeverityRatingEnum, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StressRating>>;
    public getStressRating(projectIdentifier: string, stressScopeRating: StressScopeRatingEnum, stressSeverityRating: StressSeverityRatingEnum, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStressRating.');
        }
        if (stressScopeRating === null || stressScopeRating === undefined) {
            throw new Error('Required parameter stressScopeRating was null or undefined when calling getStressRating.');
        }
        if (stressSeverityRating === null || stressSeverityRating === undefined) {
            throw new Error('Required parameter stressSeverityRating was null or undefined when calling getStressRating.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (stressScopeRating !== undefined && stressScopeRating !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>stressScopeRating, 'stress_scope_rating');
        }
        if (stressSeverityRating !== undefined && stressSeverityRating !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>stressSeverityRating, 'stress_severity_rating');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StressRating>(`${this.configuration.basePath}/stressrating/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StressRating(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StressRating(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getThreatReductionResultRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Threat Reduction Result
     * Get specific threat reduction result for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ThreatReductionResult>;
    public getThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ThreatReductionResult>>;
    public getThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ThreatReductionResult>>;
    public getThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getThreatReductionResult.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getThreatReductionResult.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ThreatReductionResult>(`${this.configuration.basePath}/threatreductionresults/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ThreatReductionResult(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ThreatReductionResult(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getThreatReductionResultIndicatorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Threat Reduction Result indicator
     * Get specific indicator for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Indicator>;
    public getThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Indicator>>;
    public getThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Indicator>>;
    public getThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getThreatReductionResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getThreatReductionResultIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling getThreatReductionResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Indicator>(`${this.configuration.basePath}/threatreductionresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getThreatReductionResultIndicatorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Threat Reductionresult indicators
     * Get list of indicators for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThreatReductionResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Indicator>>;
    public getThreatReductionResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Indicator>>>;
    public getThreatReductionResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Indicator>>>;
    public getThreatReductionResultIndicators(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getThreatReductionResultIndicators.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getThreatReductionResultIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Indicator>>(`${this.configuration.basePath}/threatreductionresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Indicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Indicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getThreatReductionResultObjectiveRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Threat Reduction Result objective
     * Get specific objective for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Objective>;
    public getThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Objective>>;
    public getThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Objective>>;
    public getThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getThreatReductionResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getThreatReductionResultObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling getThreatReductionResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Objective>(`${this.configuration.basePath}/threatreductionresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getThreatReductionResultObjectivesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Threat Reductionresult objectives
     * Get list of objectives for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThreatReductionResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Objective>>;
    public getThreatReductionResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Objective>>>;
    public getThreatReductionResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Objective>>>;
    public getThreatReductionResultObjectives(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getThreatReductionResultObjectives.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getThreatReductionResultObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Objective>>(`${this.configuration.basePath}/threatreductionresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Objective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Objective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public moveProjectFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Move project factor
     * Move (child) factor to new parent
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param factorResourceIdentifier Factor Unique identifier
     * @param parentFactorInfo New parent info for child factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public moveProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public moveProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public moveProjectFactor(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, parentFactorInfo: ParentFactorInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling moveProjectFactor.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling moveProjectFactor.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling moveProjectFactor.');
        }
        if (parentFactorInfo === null || parentFactorInfo === undefined) {
            throw new Error('Required parameter parentFactorInfo was null or undefined when calling moveProjectFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectfactormove/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}/${encodeURIComponent(String(factorResourceIdentifier))}`,
            CaseUtils.toSnake(parentFactorInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public parseProjectFileRequiredPermission() {
        return '' || 'CreateProject';
    }

    /**
     * Parse Project file
     * Parse Project file
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param projectFileBytes Project file bytes (.xmpz2 format)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parseProjectFile(programIdentifier: string, projectFileBytes?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFileInfo>;
    public parseProjectFile(programIdentifier: string, projectFileBytes?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFileInfo>>;
    public parseProjectFile(programIdentifier: string, projectFileBytes?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFileInfo>>;
    public parseProjectFile(programIdentifier: string, projectFileBytes?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling parseProjectFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (projectFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('project_file_bytes', <any>projectFileBytes) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectFileInfo>(`${this.configuration.basePath}/projectfile/${encodeURIComponent(String(programIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFileInfo(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFileInfo(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public parseShapefileForProjectMapRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Parse shapefile for Project map
     * Parse shapefile for Project map
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param shapeFileBytes GIS shapefile (zip archive containing a .shp or .gdp file (not larger than 10MB))
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parseShapefileForProjectMap(projectIdentifier: string, shapeFileBytes?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FeatureCollection>;
    public parseShapefileForProjectMap(projectIdentifier: string, shapeFileBytes?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FeatureCollection>>;
    public parseShapefileForProjectMap(projectIdentifier: string, shapeFileBytes?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FeatureCollection>>;
    public parseShapefileForProjectMap(projectIdentifier: string, shapeFileBytes?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling parseShapefileForProjectMap.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (shapeFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('shape_file_bytes', <any>shapeFileBytes) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FeatureCollection>(`${this.configuration.basePath}/projectmap/${encodeURIComponent(String(projectIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FeatureCollection(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FeatureCollection(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public restoreProjectRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Restore project file
     * Restore Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectRestoreInfo Restore info for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreProject(projectIdentifier: string, projectRestoreInfo: ProjectRestoreInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public restoreProject(projectIdentifier: string, projectRestoreInfo: ProjectRestoreInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public restoreProject(projectIdentifier: string, projectRestoreInfo: ProjectRestoreInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public restoreProject(projectIdentifier: string, projectRestoreInfo: ProjectRestoreInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling restoreProject.');
        }
        if (projectRestoreInfo === null || projectRestoreInfo === undefined) {
            throw new Error('Required parameter projectRestoreInfo was null or undefined when calling restoreProject.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/projectrestore/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectRestoreInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Activity output
     * Update specific output for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param outputNew Updated output for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateActivityOutput(projectIdentifier: string, activityResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityOutput.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateActivityOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling updateActivityOutput.');
        }
        if (outputNew === null || outputNew === undefined) {
            throw new Error('Required parameter outputNew was null or undefined when calling updateActivityOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activityoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            CaseUtils.toSnake(outputNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityTaskRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Activity task
     * Update specific task for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param activityTaskNew Updated task for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateActivityTask(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, activityTaskNew: ActivityTaskNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityTask.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateActivityTask.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling updateActivityTask.');
        }
        if (activityTaskNew === null || activityTaskNew === undefined) {
            throw new Error('Required parameter activityTaskNew was null or undefined when calling updateActivityTask.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activitytasks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            CaseUtils.toSnake(activityTaskNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityTaskExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Task expense
     * Update specific expense assignment for a Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param factorExpenseNew New expense for Task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateActivityTaskExpense(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityTaskExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateActivityTaskExpense.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling updateActivityTaskExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling updateActivityTaskExpense.');
        }
        if (factorExpenseNew === null || factorExpenseNew === undefined) {
            throw new Error('Required parameter factorExpenseNew was null or undefined when calling updateActivityTaskExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activitytaskexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            CaseUtils.toSnake(factorExpenseNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityTaskExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Task expense detail
     * Update specific expense detail of Task expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param factorExpenseDetailUpdate New expense detail for Task expense assignment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateActivityTaskExpenseDetail(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityTaskExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateActivityTaskExpenseDetail.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling updateActivityTaskExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling updateActivityTaskExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling updateActivityTaskExpenseDetail.');
        }
        if (factorExpenseDetailUpdate === null || factorExpenseDetailUpdate === undefined) {
            throw new Error('Required parameter factorExpenseDetailUpdate was null or undefined when calling updateActivityTaskExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activitytaskexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            CaseUtils.toSnake(factorExpenseDetailUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityTaskLeaderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Task leader (project resource)
     * Update leader (project resource) for a specific Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param factorResourceIdentifier New leader (project resource) for Task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateActivityTaskLeader(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityTaskLeader.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateActivityTaskLeader.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling updateActivityTaskLeader.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling updateActivityTaskLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activitytaskleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            CaseUtils.toSnake(factorResourceIdentifier),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityTaskOrderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Activity task order
     * Update order of tasks for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param resourceIdentifierList Task resource identifiers for Activity (in desired order)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ActivityTask>;
    public updateActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ActivityTask>>;
    public updateActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ActivityTask>>;
    public updateActivityTaskOrder(projectIdentifier: string, activityResourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityTaskOrder.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateActivityTaskOrder.');
        }
        if (resourceIdentifierList === null || resourceIdentifierList === undefined) {
            throw new Error('Required parameter resourceIdentifierList was null or undefined when calling updateActivityTaskOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<ActivityTask>(`${this.configuration.basePath}/activitytaskorder/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(resourceIdentifierList),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ActivityTask(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ActivityTask(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityTaskOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Task output
     * Update specific output for Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param outputNew Updated output for Task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateActivityTaskOutput(projectIdentifier: string, taskResourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityTaskOutput.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling updateActivityTaskOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling updateActivityTaskOutput.');
        }
        if (outputNew === null || outputNew === undefined) {
            throw new Error('Required parameter outputNew was null or undefined when calling updateActivityTaskOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activitytaskoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            CaseUtils.toSnake(outputNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateActivityTaskTimeframeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Task timeframe
     * Update timeframe for a specific Task
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param taskResourceIdentifier Task Unique identifier
     * @param factorTimeframeDetail New timeframe for Task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateActivityTaskTimeframe(projectIdentifier: string, activityResourceIdentifier: string, taskResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateActivityTaskTimeframe.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateActivityTaskTimeframe.');
        }
        if (taskResourceIdentifier === null || taskResourceIdentifier === undefined) {
            throw new Error('Required parameter taskResourceIdentifier was null or undefined when calling updateActivityTaskTimeframe.');
        }
        if (factorTimeframeDetail === null || factorTimeframeDetail === undefined) {
            throw new Error('Required parameter factorTimeframeDetail was null or undefined when calling updateActivityTaskTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activitytasktimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(taskResourceIdentifier))}`,
            CaseUtils.toSnake(factorTimeframeDetail),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiodiversityTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project biodiversity target
     * Update specific biodiversity target for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param biodiversityTargetNew Updated project biodiversity target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, biodiversityTargetNew: BiodiversityTargetNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, biodiversityTargetNew: BiodiversityTargetNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, biodiversityTargetNew: BiodiversityTargetNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiodiversityTarget(projectIdentifier: string, resourceIdentifier: string, biodiversityTargetNew: BiodiversityTargetNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiodiversityTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiodiversityTarget.');
        }
        if (biodiversityTargetNew === null || biodiversityTargetNew === undefined) {
            throw new Error('Required parameter biodiversityTargetNew was null or undefined when calling updateBiodiversityTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biodiversitytargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(biodiversityTargetNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiodiversityTargetGoalRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biodiversity Target Goal
     * Update specific Goal for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalResourceIdentifier Goal Unique identifier
     * @param goalNew Updated Goal for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiodiversityTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiodiversityTargetGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiodiversityTargetGoal.');
        }
        if (goalResourceIdentifier === null || goalResourceIdentifier === undefined) {
            throw new Error('Required parameter goalResourceIdentifier was null or undefined when calling updateBiodiversityTargetGoal.');
        }
        if (goalNew === null || goalNew === undefined) {
            throw new Error('Required parameter goalNew was null or undefined when calling updateBiodiversityTargetGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biodiversitytargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(goalResourceIdentifier))}`,
            CaseUtils.toSnake(goalNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiodiversityTargetIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biodiversity Target Indicator
     * Update specific Indicator for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated Indicator for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiodiversityTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiodiversityTargetIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiodiversityTargetIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateBiodiversityTargetIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateBiodiversityTargetIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biodiversitytargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiodiversityTargetKeyEcologicalAttributeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biodiversity Target Key Ecological Attribute
     * Update specific Key Ecological Attribute for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeResourceIdentifier Key Ecological Attribute Unique identifier
     * @param keyEcologicalAttributeNew Updated Key Ecological Attribute for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiodiversityTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeResourceIdentifier === null || keyEcologicalAttributeResourceIdentifier === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeResourceIdentifier was null or undefined when calling updateBiodiversityTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeNew === null || keyEcologicalAttributeNew === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeNew was null or undefined when calling updateBiodiversityTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biodiversitytargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(keyEcologicalAttributeResourceIdentifier))}`,
            CaseUtils.toSnake(keyEcologicalAttributeNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiodiversityTargetNestedTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biodiversity Target Nested Target
     * Update specific Nested Target for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetResourceIdentifier Nested Target Unique identifier
     * @param nestedTargetNew Updated Nested Target for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiodiversityTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiodiversityTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiodiversityTargetNestedTarget.');
        }
        if (nestedTargetResourceIdentifier === null || nestedTargetResourceIdentifier === undefined) {
            throw new Error('Required parameter nestedTargetResourceIdentifier was null or undefined when calling updateBiodiversityTargetNestedTarget.');
        }
        if (nestedTargetNew === null || nestedTargetNew === undefined) {
            throw new Error('Required parameter nestedTargetNew was null or undefined when calling updateBiodiversityTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biodiversitytargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(nestedTargetResourceIdentifier))}`,
            CaseUtils.toSnake(nestedTargetNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiodiversityTargetStressRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biodiversity Target Stress
     * Update specific Stress for Biodiversity Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressResourceIdentifier Stress Unique identifier
     * @param stressNew Updated Stress for Biodiversity Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, stressNew: StressNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, stressNew: StressNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, stressNew: StressNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiodiversityTargetStress(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, stressNew: StressNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiodiversityTargetStress.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiodiversityTargetStress.');
        }
        if (stressResourceIdentifier === null || stressResourceIdentifier === undefined) {
            throw new Error('Required parameter stressResourceIdentifier was null or undefined when calling updateBiodiversityTargetStress.');
        }
        if (stressNew === null || stressNew === undefined) {
            throw new Error('Required parameter stressNew was null or undefined when calling updateBiodiversityTargetStress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biodiversitytargetstresses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(stressResourceIdentifier))}`,
            CaseUtils.toSnake(stressNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiophysicalFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biophysical Factor
     * Update specific biophysical factor for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param biophysicalFactorNew Updated biophysical factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, biophysicalFactorNew: BiophysicalFactorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, biophysicalFactorNew: BiophysicalFactorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, biophysicalFactorNew: BiophysicalFactorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiophysicalFactor(projectIdentifier: string, resourceIdentifier: string, biophysicalFactorNew: BiophysicalFactorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiophysicalFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiophysicalFactor.');
        }
        if (biophysicalFactorNew === null || biophysicalFactorNew === undefined) {
            throw new Error('Required parameter biophysicalFactorNew was null or undefined when calling updateBiophysicalFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biophysicalfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(biophysicalFactorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiophysicalFactorIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biophysical Factor indicator
     * Update specific indicator for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated indicator for Biophysical Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiophysicalFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiophysicalFactorIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiophysicalFactorIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateBiophysicalFactorIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateBiophysicalFactorIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biophysicalfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiophysicalFactorObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biophysical Factor objective
     * Update specific objective for Biophysical Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param objectiveNew Updated objective for Biophysical Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiophysicalFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiophysicalFactorObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiophysicalFactorObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling updateBiophysicalFactorObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateBiophysicalFactorObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biophysicalfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiophysicalResultRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biophysical Result
     * Update specific biophysical result for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param biophysicalResultNew Updated biophysical result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, biophysicalResultNew: BiophysicalResultNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, biophysicalResultNew: BiophysicalResultNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, biophysicalResultNew: BiophysicalResultNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiophysicalResult(projectIdentifier: string, resourceIdentifier: string, biophysicalResultNew: BiophysicalResultNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiophysicalResult.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiophysicalResult.');
        }
        if (biophysicalResultNew === null || biophysicalResultNew === undefined) {
            throw new Error('Required parameter biophysicalResultNew was null or undefined when calling updateBiophysicalResult.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biophysicalresults/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(biophysicalResultNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiophysicalResultIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biophysical Result indicator
     * Update specific indicator for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated indicator for Biophysical Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiophysicalResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiophysicalResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiophysicalResultIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateBiophysicalResultIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateBiophysicalResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biophysicalresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateBiophysicalResultObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Biophysical Result objective
     * Update specific objective for Biophysical Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param objectiveNew Updated objective for Biophysical Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateBiophysicalResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateBiophysicalResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateBiophysicalResultObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling updateBiophysicalResultObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateBiophysicalResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/biophysicalresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateContributingFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update contributing factor
     * Update specific contributing factor for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param contributingFactorNew Updated contributing factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContributingFactor(projectIdentifier: string, resourceIdentifier: string, contributingFactorNew: ContributingFactorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateContributingFactor(projectIdentifier: string, resourceIdentifier: string, contributingFactorNew: ContributingFactorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateContributingFactor(projectIdentifier: string, resourceIdentifier: string, contributingFactorNew: ContributingFactorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateContributingFactor(projectIdentifier: string, resourceIdentifier: string, contributingFactorNew: ContributingFactorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateContributingFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateContributingFactor.');
        }
        if (contributingFactorNew === null || contributingFactorNew === undefined) {
            throw new Error('Required parameter contributingFactorNew was null or undefined when calling updateContributingFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/contributingfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(contributingFactorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateContributingFactorIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Contributing Factor indicator
     * Update specific indicator for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated indicator for Contributing Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateContributingFactorIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateContributingFactorIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateContributingFactorIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateContributingFactorIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateContributingFactorIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/contributingfactorindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateContributingFactorObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Contributing Factor objective
     * Update specific objective for Contributing Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param objectiveNew Updated objective for Contributing Factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateContributingFactorObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateContributingFactorObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateContributingFactorObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling updateContributingFactorObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateContributingFactorObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/contributingfactorobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateDirectThreatRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update direct threat
     * Update specific direct threat for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param directThreatNew Updated direct threat
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDirectThreat(projectIdentifier: string, resourceIdentifier: string, directThreatNew: DirectThreatNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateDirectThreat(projectIdentifier: string, resourceIdentifier: string, directThreatNew: DirectThreatNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateDirectThreat(projectIdentifier: string, resourceIdentifier: string, directThreatNew: DirectThreatNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateDirectThreat(projectIdentifier: string, resourceIdentifier: string, directThreatNew: DirectThreatNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateDirectThreat.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateDirectThreat.');
        }
        if (directThreatNew === null || directThreatNew === undefined) {
            throw new Error('Required parameter directThreatNew was null or undefined when calling updateDirectThreat.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/directthreats/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(directThreatNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateDirectThreatIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Direct Threat indicator
     * Update specific indicator for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated indicator for Direct Threat
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateDirectThreatIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateDirectThreatIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateDirectThreatIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateDirectThreatIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateDirectThreatIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/directthreatindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateDirectThreatObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Direct Threat objective
     * Update specific objective for Direct Threat
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param objectiveNew Updated objective for Direct Threat
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateDirectThreatObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateDirectThreatObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateDirectThreatObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling updateDirectThreatObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateDirectThreatObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/directthreatobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateFactorIndicatorScorecardRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Indicator scorecard for factor
     * Update specific scorecard
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param scorecardResourceIdentifier Scorecard Unique identifier
     * @param indicatorScorecardNew Updated scorecard for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateFactorIndicatorScorecard(projectIdentifier: string, resourceIdentifier: string, scorecardResourceIdentifier: string, indicatorScorecardNew: IndicatorScorecardNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateFactorIndicatorScorecard.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateFactorIndicatorScorecard.');
        }
        if (scorecardResourceIdentifier === null || scorecardResourceIdentifier === undefined) {
            throw new Error('Required parameter scorecardResourceIdentifier was null or undefined when calling updateFactorIndicatorScorecard.');
        }
        if (indicatorScorecardNew === null || indicatorScorecardNew === undefined) {
            throw new Error('Required parameter indicatorScorecardNew was null or undefined when calling updateFactorIndicatorScorecard.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/indicatorscorecards/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(scorecardResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorScorecardNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateGoalRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Goal
     * Update specific goal for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalNew Updated goal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateGoal(projectIdentifier: string, resourceIdentifier: string, goalNew: GoalNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateGoal.');
        }
        if (goalNew === null || goalNew === undefined) {
            throw new Error('Required parameter goalNew was null or undefined when calling updateGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/goals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(goalNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateGoalProgressPercentRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Goal progresspercent
     * Update specific progress percent for Goal
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentResourceIdentifier Resource Unique identifier
     * @param progressPercentNew Updated progress_percent for Goal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateGoalProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateGoalProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateGoalProgressPercent.');
        }
        if (progressPercentResourceIdentifier === null || progressPercentResourceIdentifier === undefined) {
            throw new Error('Required parameter progressPercentResourceIdentifier was null or undefined when calling updateGoalProgressPercent.');
        }
        if (progressPercentNew === null || progressPercentNew === undefined) {
            throw new Error('Required parameter progressPercentNew was null or undefined when calling updateGoalProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/goalprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressPercentResourceIdentifier))}`,
            CaseUtils.toSnake(progressPercentNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateGoalRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Goal
     * Update relevant factors for Goal
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalRelevantFactors Updated relevant factors for Goal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, goalRelevantFactors: GoalRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, goalRelevantFactors: GoalRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, goalRelevantFactors: GoalRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateGoalRelevantFactors(projectIdentifier: string, resourceIdentifier: string, goalRelevantFactors: GoalRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateGoalRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateGoalRelevantFactors.');
        }
        if (goalRelevantFactors === null || goalRelevantFactors === undefined) {
            throw new Error('Required parameter goalRelevantFactors was null or undefined when calling updateGoalRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/goalrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(goalRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateHumanWellbeingTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project human wellbeing target
     * Update specific human wellbeing target for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param humanWellbeingTargetNew Updated project human wellbeing target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, humanWellbeingTargetNew: HumanWellbeingTargetNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, humanWellbeingTargetNew: HumanWellbeingTargetNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, humanWellbeingTargetNew: HumanWellbeingTargetNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateHumanWellbeingTarget(projectIdentifier: string, resourceIdentifier: string, humanWellbeingTargetNew: HumanWellbeingTargetNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateHumanWellbeingTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateHumanWellbeingTarget.');
        }
        if (humanWellbeingTargetNew === null || humanWellbeingTargetNew === undefined) {
            throw new Error('Required parameter humanWellbeingTargetNew was null or undefined when calling updateHumanWellbeingTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/humanwellbeingtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(humanWellbeingTargetNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateHumanWellbeingTargetGoalRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Humanwellbeing Target Goal
     * Update specific Goal for Humanwellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param goalResourceIdentifier Goal Unique identifier
     * @param goalNew Updated Goal for Humanwellbeing Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateHumanWellbeingTargetGoal(projectIdentifier: string, resourceIdentifier: string, goalResourceIdentifier: string, goalNew: GoalNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateHumanWellbeingTargetGoal.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateHumanWellbeingTargetGoal.');
        }
        if (goalResourceIdentifier === null || goalResourceIdentifier === undefined) {
            throw new Error('Required parameter goalResourceIdentifier was null or undefined when calling updateHumanWellbeingTargetGoal.');
        }
        if (goalNew === null || goalNew === undefined) {
            throw new Error('Required parameter goalNew was null or undefined when calling updateHumanWellbeingTargetGoal.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/humanwellbeingtargetgoals/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(goalResourceIdentifier))}`,
            CaseUtils.toSnake(goalNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateHumanWellbeingTargetIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Humanwellbeing Target Indicator
     * Update specific Indicator for Humanwellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated Indicator for Humanwellbeing Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateHumanWellbeingTargetIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateHumanWellbeingTargetIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateHumanWellbeingTargetIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateHumanWellbeingTargetIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateHumanWellbeingTargetIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/humanwellbeingtargetindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateHumanWellbeingTargetKeyEcologicalAttributeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Human Wellbeing Target Key Ecological Attribute
     * Update specific Key Ecological Attribute for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeResourceIdentifier Key Ecological Attribute Unique identifier
     * @param keyEcologicalAttributeNew Updated Key Ecological Attribute for Human Wellbeing Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateHumanWellbeingTargetKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeResourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeResourceIdentifier === null || keyEcologicalAttributeResourceIdentifier === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeResourceIdentifier was null or undefined when calling updateHumanWellbeingTargetKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeNew === null || keyEcologicalAttributeNew === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeNew was null or undefined when calling updateHumanWellbeingTargetKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/humanwellbeingtargetkeyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(keyEcologicalAttributeResourceIdentifier))}`,
            CaseUtils.toSnake(keyEcologicalAttributeNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateHumanWellbeingTargetNestedTargetRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Human Wellbeing Target Nested Target
     * Update specific Nested Target for Human Wellbeing Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param nestedTargetResourceIdentifier Nested Target Unique identifier
     * @param nestedTargetNew Updated Nested Target for Human Wellbeing Target
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateHumanWellbeingTargetNestedTarget(projectIdentifier: string, resourceIdentifier: string, nestedTargetResourceIdentifier: string, nestedTargetNew: NestedTargetNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateHumanWellbeingTargetNestedTarget.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateHumanWellbeingTargetNestedTarget.');
        }
        if (nestedTargetResourceIdentifier === null || nestedTargetResourceIdentifier === undefined) {
            throw new Error('Required parameter nestedTargetResourceIdentifier was null or undefined when calling updateHumanWellbeingTargetNestedTarget.');
        }
        if (nestedTargetNew === null || nestedTargetNew === undefined) {
            throw new Error('Required parameter nestedTargetNew was null or undefined when calling updateHumanWellbeingTargetNestedTarget.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/humanwellbeingtargetnestedtargets/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(nestedTargetResourceIdentifier))}`,
            CaseUtils.toSnake(nestedTargetNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Indicator
     * Update specific indicator for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorNew Updated indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/indicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIndicatorFutureStatusRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Indicator future status
     * Update specific future status for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param futureStatusResourceIdentifier Resource Unique identifier
     * @param indicatorFutureStatusNew Updated future status for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIndicatorFutureStatus(projectIdentifier: string, resourceIdentifier: string, futureStatusResourceIdentifier: string, indicatorFutureStatusNew: IndicatorFutureStatusNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIndicatorFutureStatus.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIndicatorFutureStatus.');
        }
        if (futureStatusResourceIdentifier === null || futureStatusResourceIdentifier === undefined) {
            throw new Error('Required parameter futureStatusResourceIdentifier was null or undefined when calling updateIndicatorFutureStatus.');
        }
        if (indicatorFutureStatusNew === null || indicatorFutureStatusNew === undefined) {
            throw new Error('Required parameter indicatorFutureStatusNew was null or undefined when calling updateIndicatorFutureStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/indicatorfuturestatuses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(futureStatusResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorFutureStatusNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIndicatorMeasurementRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Indicator measurement
     * Update specific measurement for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param measurementResourceIdentifier Resource Unique identifier
     * @param indicatorMeasurementNew Updated measurement for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIndicatorMeasurement(projectIdentifier: string, resourceIdentifier: string, measurementResourceIdentifier: string, indicatorMeasurementNew: IndicatorMeasurementNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIndicatorMeasurement.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIndicatorMeasurement.');
        }
        if (measurementResourceIdentifier === null || measurementResourceIdentifier === undefined) {
            throw new Error('Required parameter measurementResourceIdentifier was null or undefined when calling updateIndicatorMeasurement.');
        }
        if (indicatorMeasurementNew === null || indicatorMeasurementNew === undefined) {
            throw new Error('Required parameter indicatorMeasurementNew was null or undefined when calling updateIndicatorMeasurement.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/indicatormeasurements/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(measurementResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorMeasurementNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIndicatorMethodRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Indicator method
     * Update specific method for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param methodResourceIdentifier Resource Unique identifier
     * @param indicatorMethodNew Updated method for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIndicatorMethod(projectIdentifier: string, resourceIdentifier: string, methodResourceIdentifier: string, indicatorMethodNew: IndicatorMethodNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIndicatorMethod.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIndicatorMethod.');
        }
        if (methodResourceIdentifier === null || methodResourceIdentifier === undefined) {
            throw new Error('Required parameter methodResourceIdentifier was null or undefined when calling updateIndicatorMethod.');
        }
        if (indicatorMethodNew === null || indicatorMethodNew === undefined) {
            throw new Error('Required parameter indicatorMethodNew was null or undefined when calling updateIndicatorMethod.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/indicatormethods/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(methodResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorMethodNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIndicatorRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Indicator
     * Update relevant factors for Indicator
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorRelevantFactors Updated relevant factors for Indicator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, indicatorRelevantFactors: IndicatorRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, indicatorRelevantFactors: IndicatorRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, indicatorRelevantFactors: IndicatorRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIndicatorRelevantFactors(projectIdentifier: string, resourceIdentifier: string, indicatorRelevantFactors: IndicatorRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIndicatorRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIndicatorRelevantFactors.');
        }
        if (indicatorRelevantFactors === null || indicatorRelevantFactors === undefined) {
            throw new Error('Required parameter indicatorRelevantFactors was null or undefined when calling updateIndicatorRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/indicatorrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIndicatorViabilityRatingsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Indicator Viability Ratings
     * Update Indicator Viability Ratings
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorViabilityRatings Updated Indicator Viability Ratings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, indicatorViabilityRatings: IndicatorViabilityRatings, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, indicatorViabilityRatings: IndicatorViabilityRatings, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, indicatorViabilityRatings: IndicatorViabilityRatings, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIndicatorViabilityRatings(projectIdentifier: string, resourceIdentifier: string, indicatorViabilityRatings: IndicatorViabilityRatings, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIndicatorViabilityRatings.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIndicatorViabilityRatings.');
        }
        if (indicatorViabilityRatings === null || indicatorViabilityRatings === undefined) {
            throw new Error('Required parameter indicatorViabilityRatings was null or undefined when calling updateIndicatorViabilityRatings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/indicatorviabilityratings/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(indicatorViabilityRatings),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIntermediateResultRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Intermediate Result
     * Update specific intermediate result for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param intermediateResultNew Updated intermediate result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIntermediateResult(projectIdentifier: string, resourceIdentifier: string, intermediateResultNew: IntermediateResultNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIntermediateResult(projectIdentifier: string, resourceIdentifier: string, intermediateResultNew: IntermediateResultNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIntermediateResult(projectIdentifier: string, resourceIdentifier: string, intermediateResultNew: IntermediateResultNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIntermediateResult(projectIdentifier: string, resourceIdentifier: string, intermediateResultNew: IntermediateResultNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIntermediateResult.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIntermediateResult.');
        }
        if (intermediateResultNew === null || intermediateResultNew === undefined) {
            throw new Error('Required parameter intermediateResultNew was null or undefined when calling updateIntermediateResult.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/intermediateresults/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(intermediateResultNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIntermediateResultIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Intermediate Result indicator
     * Update specific indicator for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated indicator for Intermediate Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIntermediateResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIntermediateResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIntermediateResultIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateIntermediateResultIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateIntermediateResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/intermediateresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateIntermediateResultObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Intermediate Result objective
     * Update specific objective for Intermediate Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param objectiveNew Updated objective for Intermediate Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateIntermediateResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateIntermediateResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateIntermediateResultObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling updateIntermediateResultObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateIntermediateResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/intermediateresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateKeyEcologicalAttributeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project key ecological attribute
     * Update specific key ecological attribute for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param keyEcologicalAttributeNew Updated project key ecological attribute
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateKeyEcologicalAttribute(projectIdentifier: string, resourceIdentifier: string, keyEcologicalAttributeNew: KeyEcologicalAttributeNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateKeyEcologicalAttribute.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateKeyEcologicalAttribute.');
        }
        if (keyEcologicalAttributeNew === null || keyEcologicalAttributeNew === undefined) {
            throw new Error('Required parameter keyEcologicalAttributeNew was null or undefined when calling updateKeyEcologicalAttribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/keyecologicalattributes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(keyEcologicalAttributeNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateKeyEcologicalAttributeIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update key ecological attribute Indicator
     * Update specific Indicator for key ecological attribute
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated Indicator for key ecological attribute
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateKeyEcologicalAttributeIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateKeyEcologicalAttributeIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateKeyEcologicalAttributeIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateKeyEcologicalAttributeIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateKeyEcologicalAttributeIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/keyecologicalattributeindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Objective
     * Update specific objective for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveNew Updated objective
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateObjective(projectIdentifier: string, resourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/objectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateObjectiveProgressPercentRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Objective progress percent
     * Update specific progress percent for Objective
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressPercentResourceIdentifier Resource Unique identifier
     * @param progressPercentNew Updated progress_percent for Objective
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateObjectiveProgressPercent(projectIdentifier: string, resourceIdentifier: string, progressPercentResourceIdentifier: string, progressPercentNew: ProgressPercentNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateObjectiveProgressPercent.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateObjectiveProgressPercent.');
        }
        if (progressPercentResourceIdentifier === null || progressPercentResourceIdentifier === undefined) {
            throw new Error('Required parameter progressPercentResourceIdentifier was null or undefined when calling updateObjectiveProgressPercent.');
        }
        if (progressPercentNew === null || progressPercentNew === undefined) {
            throw new Error('Required parameter progressPercentNew was null or undefined when calling updateObjectiveProgressPercent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/objectiveprogresspercents/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressPercentResourceIdentifier))}`,
            CaseUtils.toSnake(progressPercentNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateObjectiveRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Objective
     * Update relevant factors for Objective
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveRelevantFactors Updated relevant factors for Objective
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, objectiveRelevantFactors: ObjectiveRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, objectiveRelevantFactors: ObjectiveRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, objectiveRelevantFactors: ObjectiveRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateObjectiveRelevantFactors(projectIdentifier: string, resourceIdentifier: string, objectiveRelevantFactors: ObjectiveRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateObjectiveRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateObjectiveRelevantFactors.');
        }
        if (objectiveRelevantFactors === null || objectiveRelevantFactors === undefined) {
            throw new Error('Required parameter objectiveRelevantFactors was null or undefined when calling updateObjectiveRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/objectiverelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(objectiveRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateOutputRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Output
     * Update relevant factors for Output
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param outputRelevantFactors Updated relevant factors for Output
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, outputRelevantFactors: OutputRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, outputRelevantFactors: OutputRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, outputRelevantFactors: OutputRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateOutputRelevantFactors(projectIdentifier: string, resourceIdentifier: string, outputRelevantFactors: OutputRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateOutputRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateOutputRelevantFactors.');
        }
        if (outputRelevantFactors === null || outputRelevantFactors === undefined) {
            throw new Error('Required parameter outputRelevantFactors was null or undefined when calling updateOutputRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/outputrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(outputRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectAccessStateRequiredPermission() {
        return 'AssignProjectAccess' || '';
    }

    /**
     * Update Project access state
     * Update access state for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectAccessState New access state for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectAccessState(projectIdentifier: string, projectAccessState: ProjectAccessState, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectAccessState(projectIdentifier: string, projectAccessState: ProjectAccessState, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectAccessState(projectIdentifier: string, projectAccessState: ProjectAccessState, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectAccessState(projectIdentifier: string, projectAccessState: ProjectAccessState, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectAccessState.');
        }
        if (projectAccessState === null || projectAccessState === undefined) {
            throw new Error('Required parameter projectAccessState was null or undefined when calling updateProjectAccessState.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectaccessstate/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectAccessState),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectAssignmentTaxonomyClassificationsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Assignment Taxonomy Classifications
     * Update Taxonomy Classifications for Assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param taxonomyVersionResourceIdentifier Taxonomy Version Resource Unique identifier
     * @param requestBody Updated Resource Assignment Taxonomy Classifications (array of taxonomy_element_resource_identifier)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectAssignmentTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectAssignmentTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectAssignmentTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectAssignmentTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectAssignmentTaxonomyClassifications.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectAssignmentTaxonomyClassifications.');
        }
        if (taxonomyVersionResourceIdentifier === null || taxonomyVersionResourceIdentifier === undefined) {
            throw new Error('Required parameter taxonomyVersionResourceIdentifier was null or undefined when calling updateProjectAssignmentTaxonomyClassifications.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling updateProjectAssignmentTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectassignmenttaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(taxonomyVersionResourceIdentifier))}`,
            CaseUtils.toSnake(requestBody),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectAssociatedLinkRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project associated link
     * Update specific associated link for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param associatedLinkNew Updated associated link for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectAssociatedLink(projectIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectAssociatedLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectAssociatedLink.');
        }
        if (associatedLinkNew === null || associatedLinkNew === undefined) {
            throw new Error('Required parameter associatedLinkNew was null or undefined when calling updateProjectAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectassociatedlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(associatedLinkNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectCustomReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project custom report
     * Update specific custom report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param customReportNew Updated custom report for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectCustomReport(projectIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectCustomReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectCustomReport.');
        }
        if (customReportNew === null || customReportNew === undefined) {
            throw new Error('Required parameter customReportNew was null or undefined when calling updateProjectCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectcustomreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(customReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectCustomReportGroupRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project custom report group
     * Update specific custom report group for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectCustomReportGroupNew Updated custom report group for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectCustomReportGroup(projectIdentifier: string, resourceIdentifier: string, projectCustomReportGroupNew: ProjectCustomReportGroupNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectCustomReportGroup.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectCustomReportGroup.');
        }
        if (projectCustomReportGroupNew === null || projectCustomReportGroupNew === undefined) {
            throw new Error('Required parameter projectCustomReportGroupNew was null or undefined when calling updateProjectCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectcustomreportgroups/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectCustomReportGroupNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDocumentImageRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project document image
     * Update specific project document image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param imageFileBytes Image file bytes (supported content types are png, jpeg, gif, bmp and tif. Max file size allowed is 15MB. All images will be converted to .png.)
     * @param documentReference Document reference
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDocumentImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, documentReference?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDocumentImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDocumentImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (imageFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('image_file_bytes', <any>imageFileBytes) as any || localVarFormParams;
        }
        if (documentReference !== undefined) {
            localVarFormParams = localVarFormParams.append('document_reference', <any>documentReference) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdocumentimages/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectExpenseTaxonomyClassificationsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Expense Taxonomy Classifications
     * Update Taxonomy Classifications for Expense
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param taxonomyVersionResourceIdentifier Taxonomy Version Resource Unique identifier
     * @param requestBody Updated Expense Assignment Taxonomy Classifications (array of taxonomy_element_resource_identifier)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectExpenseTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectExpenseTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectExpenseTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectExpenseTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectExpenseTaxonomyClassifications.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectExpenseTaxonomyClassifications.');
        }
        if (taxonomyVersionResourceIdentifier === null || taxonomyVersionResourceIdentifier === undefined) {
            throw new Error('Required parameter taxonomyVersionResourceIdentifier was null or undefined when calling updateProjectExpenseTaxonomyClassifications.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling updateProjectExpenseTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectexpensetaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(taxonomyVersionResourceIdentifier))}`,
            CaseUtils.toSnake(requestBody),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Factor Summary
     * Update specific Factor Summary for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param factorSummaryNew Updated Factor Summary
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectFactor(projectIdentifier: string, resourceIdentifier: string, factorSummaryNew: FactorSummaryNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectFactor(projectIdentifier: string, resourceIdentifier: string, factorSummaryNew: FactorSummaryNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectFactor(projectIdentifier: string, resourceIdentifier: string, factorSummaryNew: FactorSummaryNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectFactor(projectIdentifier: string, resourceIdentifier: string, factorSummaryNew: FactorSummaryNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectFactor.');
        }
        if (factorSummaryNew === null || factorSummaryNew === undefined) {
            throw new Error('Required parameter factorSummaryNew was null or undefined when calling updateProjectFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectfactorsummary/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(factorSummaryNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectFactorFootprintRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Factor Footprint
     * Update Factor Footprint for specific Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param featureCollection Factor footprint (GeoJSON)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectFactorFootprint(projectIdentifier: string, resourceIdentifier: string, featureCollection: FeatureCollection, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorFootprint>;
    public updateProjectFactorFootprint(projectIdentifier: string, resourceIdentifier: string, featureCollection: FeatureCollection, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorFootprint>>;
    public updateProjectFactorFootprint(projectIdentifier: string, resourceIdentifier: string, featureCollection: FeatureCollection, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorFootprint>>;
    public updateProjectFactorFootprint(projectIdentifier: string, resourceIdentifier: string, featureCollection: FeatureCollection, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectFactorFootprint.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectFactorFootprint.');
        }
        if (featureCollection === null || featureCollection === undefined) {
            throw new Error('Required parameter featureCollection was null or undefined when calling updateProjectFactorFootprint.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<FactorFootprint>(`${this.configuration.basePath}/projectfactorfootprints/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(featureCollection),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorFootprint(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorFootprint(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectFactorProgressReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project factor progress report
     * Update specific project progress report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressReportResourceIdentifier Resource Unique identifier
     * @param progressReportNew Updated progress report for Project factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectFactorProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportResourceIdentifier: string, progressReportNew: ProgressReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectFactorProgressReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectFactorProgressReport.');
        }
        if (progressReportResourceIdentifier === null || progressReportResourceIdentifier === undefined) {
            throw new Error('Required parameter progressReportResourceIdentifier was null or undefined when calling updateProjectFactorProgressReport.');
        }
        if (progressReportNew === null || progressReportNew === undefined) {
            throw new Error('Required parameter progressReportNew was null or undefined when calling updateProjectFactorProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectfactorprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(progressReportResourceIdentifier))}`,
            CaseUtils.toSnake(progressReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectFactorResultReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project factor result report
     * Update specific project result report for Project factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param resultReportResourceIdentifier Resource Unique identifier
     * @param resultReportNew Updated result report for Project factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, resultReportNew: ResultReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, resultReportNew: ResultReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, resultReportNew: ResultReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectFactorResultReport(projectIdentifier: string, resourceIdentifier: string, resultReportResourceIdentifier: string, resultReportNew: ResultReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectFactorResultReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectFactorResultReport.');
        }
        if (resultReportResourceIdentifier === null || resultReportResourceIdentifier === undefined) {
            throw new Error('Required parameter resultReportResourceIdentifier was null or undefined when calling updateProjectFactorResultReport.');
        }
        if (resultReportNew === null || resultReportNew === undefined) {
            throw new Error('Required parameter resultReportNew was null or undefined when calling updateProjectFactorResultReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectfactorresultreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(resultReportResourceIdentifier))}`,
            CaseUtils.toSnake(resultReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectFactorTaxonomyClassificationsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Factor Taxonomy Classifications
     * Update Taxonomy Classifications for Factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param taxonomyVersionResourceIdentifier Taxonomy Version Resource Unique identifier
     * @param requestBody Updated Factor Taxonomy Classifications (array of taxonomy_element_resource_identifier)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectFactorTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectFactorTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectFactorTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectFactorTaxonomyClassifications(projectIdentifier: string, resourceIdentifier: string, taxonomyVersionResourceIdentifier: string, requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectFactorTaxonomyClassifications.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectFactorTaxonomyClassifications.');
        }
        if (taxonomyVersionResourceIdentifier === null || taxonomyVersionResourceIdentifier === undefined) {
            throw new Error('Required parameter taxonomyVersionResourceIdentifier was null or undefined when calling updateProjectFactorTaxonomyClassifications.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling updateProjectFactorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectfactortaxonomyclassifications/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(taxonomyVersionResourceIdentifier))}`,
            CaseUtils.toSnake(requestBody),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectFootprintRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project Footprint
     * Update Project Footprint
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param featureCollection Project footprint (GeoJSON)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectFootprint(projectIdentifier: string, featureCollection: FeatureCollection, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectMap>;
    public updateProjectFootprint(projectIdentifier: string, featureCollection: FeatureCollection, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectMap>>;
    public updateProjectFootprint(projectIdentifier: string, featureCollection: FeatureCollection, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectMap>>;
    public updateProjectFootprint(projectIdentifier: string, featureCollection: FeatureCollection, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectFootprint.');
        }
        if (featureCollection === null || featureCollection === undefined) {
            throw new Error('Required parameter featureCollection was null or undefined when calling updateProjectFootprint.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<ProjectMap>(`${this.configuration.basePath}/projectmap/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(featureCollection),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectMap(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectMap(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectLocationRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project location
     * Update location for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectLocation New location for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectLocation(projectIdentifier: string, projectLocation: ProjectLocation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectLocation(projectIdentifier: string, projectLocation: ProjectLocation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectLocation(projectIdentifier: string, projectLocation: ProjectLocation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectLocation(projectIdentifier: string, projectLocation: ProjectLocation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectLocation.');
        }
        if (projectLocation === null || projectLocation === undefined) {
            throw new Error('Required parameter projectLocation was null or undefined when calling updateProjectLocation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectlocation/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectLocation),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectOwnerRequiredPermission() {
        return 'AssignDeleteProjectRoles' || '';
    }

    /**
     * Update Project owner
     * Update Project owner
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectOwnerUpdate New owner for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectOwner(projectIdentifier: string, projectOwnerUpdate: ProjectOwnerUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectOwner(projectIdentifier: string, projectOwnerUpdate: ProjectOwnerUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectOwner(projectIdentifier: string, projectOwnerUpdate: ProjectOwnerUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectOwner(projectIdentifier: string, projectOwnerUpdate: ProjectOwnerUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectOwner.');
        }
        if (projectOwnerUpdate === null || projectOwnerUpdate === undefined) {
            throw new Error('Required parameter projectOwnerUpdate was null or undefined when calling updateProjectOwner.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectowner/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectOwnerUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectProgressReportRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project progress report
     * Update specific project progress report for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param progressReportNew Updated progress report for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectProgressReport(projectIdentifier: string, resourceIdentifier: string, progressReportNew: ProgressReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectProgressReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectProgressReport.');
        }
        if (progressReportNew === null || progressReportNew === undefined) {
            throw new Error('Required parameter progressReportNew was null or undefined when calling updateProjectProgressReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectprogressreports/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(progressReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectPublishStatusRequiredPermission() {
        return 'AssignProjectState' || '';
    }

    /**
     * Update Project publication status
     * Update publication status for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectPublishStatus New publication status for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectPublishStatus(projectIdentifier: string, projectPublishStatus: ProjectPublishStatus, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectPublishStatus(projectIdentifier: string, projectPublishStatus: ProjectPublishStatus, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectPublishStatus(projectIdentifier: string, projectPublishStatus: ProjectPublishStatus, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectPublishStatus(projectIdentifier: string, projectPublishStatus: ProjectPublishStatus, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectPublishStatus.');
        }
        if (projectPublishStatus === null || projectPublishStatus === undefined) {
            throw new Error('Required parameter projectPublishStatus was null or undefined when calling updateProjectPublishStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectpublishstatus/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectPublishStatus),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectResourceRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project resource
     * Update specific project resource for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectResourceNew Updated resource for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectResource(projectIdentifier: string, resourceIdentifier: string, projectResourceNew: ProjectResourceNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectResource(projectIdentifier: string, resourceIdentifier: string, projectResourceNew: ProjectResourceNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectResource(projectIdentifier: string, resourceIdentifier: string, projectResourceNew: ProjectResourceNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectResource(projectIdentifier: string, resourceIdentifier: string, projectResourceNew: ProjectResourceNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectResource.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectResource.');
        }
        if (projectResourceNew === null || projectResourceNew === undefined) {
            throw new Error('Required parameter projectResourceNew was null or undefined when calling updateProjectResource.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectresources/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectResourceNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectScopeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project scope / vision
     * Update scope / vision for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectScope New scope / vision for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectScope(projectIdentifier: string, projectScope: ProjectScope, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectScope(projectIdentifier: string, projectScope: ProjectScope, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectScope(projectIdentifier: string, projectScope: ProjectScope, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectScope(projectIdentifier: string, projectScope: ProjectScope, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectScope.');
        }
        if (projectScope === null || projectScope === undefined) {
            throw new Error('Required parameter projectScope was null or undefined when calling updateProjectScope.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectscope/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectScope),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectScopeBiodiversityFeaturesRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project scope biodiversity features
     * Update scope biodiversity features for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectScopeBiodiversityFeatures Updated scope biodiversity features for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectScopeBiodiversityFeatures(projectIdentifier: string, projectScopeBiodiversityFeatures: ProjectScopeBiodiversityFeatures, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectScopeBiodiversityFeatures(projectIdentifier: string, projectScopeBiodiversityFeatures: ProjectScopeBiodiversityFeatures, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectScopeBiodiversityFeatures(projectIdentifier: string, projectScopeBiodiversityFeatures: ProjectScopeBiodiversityFeatures, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectScopeBiodiversityFeatures(projectIdentifier: string, projectScopeBiodiversityFeatures: ProjectScopeBiodiversityFeatures, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectScopeBiodiversityFeatures.');
        }
        if (projectScopeBiodiversityFeatures === null || projectScopeBiodiversityFeatures === undefined) {
            throw new Error('Required parameter projectScopeBiodiversityFeatures was null or undefined when calling updateProjectScopeBiodiversityFeatures.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectscopebiodiversityfeatures/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectScopeBiodiversityFeatures),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectScopeHumanStakeholdersRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project scope human stakeholders
     * Update scope human stakeholders for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectScopeHumanStakeholders Updated scope human stakeholders for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectScopeHumanStakeholders(projectIdentifier: string, projectScopeHumanStakeholders: ProjectScopeHumanStakeholders, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectScopeHumanStakeholders(projectIdentifier: string, projectScopeHumanStakeholders: ProjectScopeHumanStakeholders, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectScopeHumanStakeholders(projectIdentifier: string, projectScopeHumanStakeholders: ProjectScopeHumanStakeholders, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectScopeHumanStakeholders(projectIdentifier: string, projectScopeHumanStakeholders: ProjectScopeHumanStakeholders, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectScopeHumanStakeholders.');
        }
        if (projectScopeHumanStakeholders === null || projectScopeHumanStakeholders === undefined) {
            throw new Error('Required parameter projectScopeHumanStakeholders was null or undefined when calling updateProjectScopeHumanStakeholders.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectscopehumanstakeholders/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectScopeHumanStakeholders),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectScopeProtectedAreaInformationRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project scope protected area information
     * Update scope protected area information for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectScopeProtectedAreaInformation Updated scope protected area information for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectScopeProtectedAreaInformation(projectIdentifier: string, projectScopeProtectedAreaInformation: ProjectScopeProtectedAreaInformation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectScopeProtectedAreaInformation(projectIdentifier: string, projectScopeProtectedAreaInformation: ProjectScopeProtectedAreaInformation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectScopeProtectedAreaInformation(projectIdentifier: string, projectScopeProtectedAreaInformation: ProjectScopeProtectedAreaInformation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectScopeProtectedAreaInformation(projectIdentifier: string, projectScopeProtectedAreaInformation: ProjectScopeProtectedAreaInformation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectScopeProtectedAreaInformation.');
        }
        if (projectScopeProtectedAreaInformation === null || projectScopeProtectedAreaInformation === undefined) {
            throw new Error('Required parameter projectScopeProtectedAreaInformation was null or undefined when calling updateProjectScopeProtectedAreaInformation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectscopeprotectedareainformation/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectScopeProtectedAreaInformation),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectSlideshowImageRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project slideshow image
     * Update specific project slideshow image for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param imageFileBytes Image file bytes (supported content types are png, jpeg, gif, bmp and tif. Max file size allowed is 3MB. All images will be converted to .png.)
     * @param caption Image caption
     * @param imageOrder Image order - defaults to next in sequence if not supplied
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectSlideshowImage(projectIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectSlideshowImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (imageFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('image_file_bytes', <any>imageFileBytes) as any || localVarFormParams;
        }
        if (caption !== undefined) {
            localVarFormParams = localVarFormParams.append('caption', <any>caption) as any || localVarFormParams;
        }
        if (imageOrder !== undefined) {
            localVarFormParams = localVarFormParams.append('image_order', <any>imageOrder) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectslideshowimages/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectSnapshotRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update project snapshot
     * Update specific snapshot for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectSnapshotNew Updated snapshot info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectSnapshot(projectIdentifier: string, resourceIdentifier: string, projectSnapshotNew: ProjectSnapshotNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectSnapshot.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectSnapshot.');
        }
        if (projectSnapshotNew === null || projectSnapshotNew === undefined) {
            throw new Error('Required parameter projectSnapshotNew was null or undefined when calling updateProjectSnapshot.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectsnapshots/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectSnapshotNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectSummaryRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project summary
     * Update summary for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectSummaryUpdate New summary for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectSummary(projectIdentifier: string, projectSummaryUpdate: ProjectSummaryUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectSummary(projectIdentifier: string, projectSummaryUpdate: ProjectSummaryUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectSummary(projectIdentifier: string, projectSummaryUpdate: ProjectSummaryUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectSummary(projectIdentifier: string, projectSummaryUpdate: ProjectSummaryUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectSummary.');
        }
        if (projectSummaryUpdate === null || projectSummaryUpdate === undefined) {
            throw new Error('Required parameter projectSummaryUpdate was null or undefined when calling updateProjectSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectsummary/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectSummaryUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectTeamMemberRequiredPermission() {
        return 'AssignDeleteProjectRoles' || '';
    }

    /**
     * Update Project team member
     * Update specific team member for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectTeamMemberUpdate Updated team member for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, projectTeamMemberUpdate: ProjectTeamMemberUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, projectTeamMemberUpdate: ProjectTeamMemberUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, projectTeamMemberUpdate: ProjectTeamMemberUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectTeamMember(projectIdentifier: string, resourceIdentifier: string, projectTeamMemberUpdate: ProjectTeamMemberUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectTeamMember.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectTeamMember.');
        }
        if (projectTeamMemberUpdate === null || projectTeamMemberUpdate === undefined) {
            throw new Error('Required parameter projectTeamMemberUpdate was null or undefined when calling updateProjectTeamMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectteammembers/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectTeamMemberUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectThreatRatingsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project threat rating
     * Update threat ratings for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param threatRatingMode New threat rating mode for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectThreatRatings(projectIdentifier: string, threatRatingMode: ThreatRatingMode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectThreatRatings(projectIdentifier: string, threatRatingMode: ThreatRatingMode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectThreatRatings(projectIdentifier: string, threatRatingMode: ThreatRatingMode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectThreatRatings(projectIdentifier: string, threatRatingMode: ThreatRatingMode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectThreatRatings.');
        }
        if (threatRatingMode === null || threatRatingMode === undefined) {
            throw new Error('Required parameter threatRatingMode was null or undefined when calling updateProjectThreatRatings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectthreatratings/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(threatRatingMode),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectThreatTargetThreatRatingRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project Threat Target threat rating
     * Update threat ratings for Project Threat Target
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param threatResourceIdentifier Threat Unique identifier
     * @param targetResourceIdentifier Target Unique identifier
     * @param targetSimpleThreatRating New threat rating for Threat-Target pair
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, targetSimpleThreatRating: TargetSimpleThreatRating, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, targetSimpleThreatRating: TargetSimpleThreatRating, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, targetSimpleThreatRating: TargetSimpleThreatRating, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectThreatTargetThreatRating(projectIdentifier: string, threatResourceIdentifier: string, targetResourceIdentifier: string, targetSimpleThreatRating: TargetSimpleThreatRating, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectThreatTargetThreatRating.');
        }
        if (threatResourceIdentifier === null || threatResourceIdentifier === undefined) {
            throw new Error('Required parameter threatResourceIdentifier was null or undefined when calling updateProjectThreatTargetThreatRating.');
        }
        if (targetResourceIdentifier === null || targetResourceIdentifier === undefined) {
            throw new Error('Required parameter targetResourceIdentifier was null or undefined when calling updateProjectThreatTargetThreatRating.');
        }
        if (targetSimpleThreatRating === null || targetSimpleThreatRating === undefined) {
            throw new Error('Required parameter targetSimpleThreatRating was null or undefined when calling updateProjectThreatTargetThreatRating.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectthreatratings/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(threatResourceIdentifier))}/${encodeURIComponent(String(targetResourceIdentifier))}`,
            CaseUtils.toSnake(targetSimpleThreatRating),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectTimeframeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project timeframe
     * Update timeframe for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectTimeframeNew New timeframe for Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectTimeframe(projectIdentifier: string, projectTimeframeNew: ProjectTimeframeNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectTimeframe(projectIdentifier: string, projectTimeframeNew: ProjectTimeframeNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectTimeframe(projectIdentifier: string, projectTimeframeNew: ProjectTimeframeNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectTimeframe(projectIdentifier: string, projectTimeframeNew: ProjectTimeframeNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectTimeframe.');
        }
        if (projectTimeframeNew === null || projectTimeframeNew === undefined) {
            throw new Error('Required parameter projectTimeframeNew was null or undefined when calling updateProjectTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projecttimeframe/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectTimeframeNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectVersionNumberRequiredPermission() {
        return 'AssignProjectVersion' || '';
    }

    /**
     * Update Project version number (major)
     * Update version number (major) for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectVersionNumber(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectVersionNumber(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectVersionNumber(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectVersionNumber(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectVersionNumber.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectversionnumber/${encodeURIComponent(String(projectIdentifier))}`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy
     * Update specific strategy for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param strategyNew Updated strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategy(projectIdentifier: string, resourceIdentifier: string, strategyNew: StrategyNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategy(projectIdentifier: string, resourceIdentifier: string, strategyNew: StrategyNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategy(projectIdentifier: string, resourceIdentifier: string, strategyNew: StrategyNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategy(projectIdentifier: string, resourceIdentifier: string, strategyNew: StrategyNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategy.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategy.');
        }
        if (strategyNew === null || strategyNew === undefined) {
            throw new Error('Required parameter strategyNew was null or undefined when calling updateStrategy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategies/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(strategyNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyActivityRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy activity
     * Update specific activity for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param strategyActivityNew Updated activity for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyActivity(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityNew: StrategyActivityNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyActivity.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyActivity.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateStrategyActivity.');
        }
        if (strategyActivityNew === null || strategyActivityNew === undefined) {
            throw new Error('Required parameter strategyActivityNew was null or undefined when calling updateStrategyActivity.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyactivities/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(strategyActivityNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyActivityExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Activity expense
     * Update specific expense assignment for a Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param factorExpenseNew New expense for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyActivityExpense(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyActivityExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyActivityExpense.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateStrategyActivityExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling updateStrategyActivityExpense.');
        }
        if (factorExpenseNew === null || factorExpenseNew === undefined) {
            throw new Error('Required parameter factorExpenseNew was null or undefined when calling updateStrategyActivityExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyactivityexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            CaseUtils.toSnake(factorExpenseNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyActivityExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Activity expense detail
     * Update specific expense detail of Activity expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param factorExpenseDetailUpdate New expense detail for Activity expense assignment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyActivityExpenseDetail(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyActivityExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyActivityExpenseDetail.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateStrategyActivityExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling updateStrategyActivityExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling updateStrategyActivityExpenseDetail.');
        }
        if (factorExpenseDetailUpdate === null || factorExpenseDetailUpdate === undefined) {
            throw new Error('Required parameter factorExpenseDetailUpdate was null or undefined when calling updateStrategyActivityExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyactivityexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            CaseUtils.toSnake(factorExpenseDetailUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyActivityLeaderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Activity leader (project resource)
     * Update leader (project resource) for a specific Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param factorResourceIdentifier New leader (project resource) for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyActivityLeader(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyActivityLeader.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyActivityLeader.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateStrategyActivityLeader.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling updateStrategyActivityLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyactivityleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(factorResourceIdentifier),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyActivityOrderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy activity order
     * Update order of activities for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param resourceIdentifierList Activity resource identifiers for Strategy (in desired order)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StrategyActivity>;
    public updateStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StrategyActivity>>;
    public updateStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StrategyActivity>>;
    public updateStrategyActivityOrder(projectIdentifier: string, resourceIdentifier: string, resourceIdentifierList: ResourceIdentifierList, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyActivityOrder.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyActivityOrder.');
        }
        if (resourceIdentifierList === null || resourceIdentifierList === undefined) {
            throw new Error('Required parameter resourceIdentifierList was null or undefined when calling updateStrategyActivityOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<StrategyActivity>(`${this.configuration.basePath}/strategyactivityorder/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(resourceIdentifierList),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new StrategyActivity(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new StrategyActivity(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyActivityRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Activity
     * Update relevant factors for Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param strategyActivityRelevantFactors Updated relevant factors for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityRelevantFactors: StrategyActivityRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityRelevantFactors: StrategyActivityRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityRelevantFactors: StrategyActivityRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyActivityRelevantFactors(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, strategyActivityRelevantFactors: StrategyActivityRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyActivityRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyActivityRelevantFactors.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateStrategyActivityRelevantFactors.');
        }
        if (strategyActivityRelevantFactors === null || strategyActivityRelevantFactors === undefined) {
            throw new Error('Required parameter strategyActivityRelevantFactors was null or undefined when calling updateStrategyActivityRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyactivityrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(strategyActivityRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyActivityTimeframeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Activity timeframe
     * Update timeframe for a specific Activity
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param factorTimeframeDetail New timeframe for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyActivityTimeframe(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyActivityTimeframe.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyActivityTimeframe.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling updateStrategyActivityTimeframe.');
        }
        if (factorTimeframeDetail === null || factorTimeframeDetail === undefined) {
            throw new Error('Required parameter factorTimeframeDetail was null or undefined when calling updateStrategyActivityTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyactivitytimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(factorTimeframeDetail),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyExpenseRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy expense assignment
     * Update specific expense assignment for a Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param factorExpenseNew Updated expense assignment for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyExpense(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, factorExpenseNew: FactorExpenseNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyExpense.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyExpense.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling updateStrategyExpense.');
        }
        if (factorExpenseNew === null || factorExpenseNew === undefined) {
            throw new Error('Required parameter factorExpenseNew was null or undefined when calling updateStrategyExpense.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyexpenses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}`,
            CaseUtils.toSnake(factorExpenseNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyExpenseDetailRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy expense assignment detail
     * Update specific expense detail of Strategy expense assignment
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param expenseIdentifier Expense Unique identifier
     * @param expenseDetailIdentifier Expense Unique identifier
     * @param factorExpenseDetailUpdate Updated expense assignment detail for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyExpenseDetail(projectIdentifier: string, resourceIdentifier: string, expenseIdentifier: string, expenseDetailIdentifier: string, factorExpenseDetailUpdate: FactorExpenseDetailUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyExpenseDetail.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyExpenseDetail.');
        }
        if (expenseIdentifier === null || expenseIdentifier === undefined) {
            throw new Error('Required parameter expenseIdentifier was null or undefined when calling updateStrategyExpenseDetail.');
        }
        if (expenseDetailIdentifier === null || expenseDetailIdentifier === undefined) {
            throw new Error('Required parameter expenseDetailIdentifier was null or undefined when calling updateStrategyExpenseDetail.');
        }
        if (factorExpenseDetailUpdate === null || factorExpenseDetailUpdate === undefined) {
            throw new Error('Required parameter factorExpenseDetailUpdate was null or undefined when calling updateStrategyExpenseDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyexpensedetails/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(expenseIdentifier))}/${encodeURIComponent(String(expenseDetailIdentifier))}`,
            CaseUtils.toSnake(factorExpenseDetailUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy indicator
     * Update specific indicator for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated indicator for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateStrategyIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateStrategyIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyLeaderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy leader (project resource)
     * Update leader (project resource) for a specific Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param factorResourceIdentifier New leader (project resource) for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyLeader(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyLeader(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyLeader(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyLeader(projectIdentifier: string, resourceIdentifier: string, factorResourceIdentifier: FactorResourceIdentifier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyLeader.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyLeader.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling updateStrategyLeader.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyleader/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(factorResourceIdentifier),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy objective
     * Update specific objective for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param objectiveNew Updated objective for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling updateStrategyObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateStrategyObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyOutputRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy output
     * Update specific output for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param outputResourceIdentifier Output Unique identifier
     * @param outputNew Updated output for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyOutput(projectIdentifier: string, resourceIdentifier: string, outputResourceIdentifier: string, outputNew: OutputNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyOutput.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyOutput.');
        }
        if (outputResourceIdentifier === null || outputResourceIdentifier === undefined) {
            throw new Error('Required parameter outputResourceIdentifier was null or undefined when calling updateStrategyOutput.');
        }
        if (outputNew === null || outputNew === undefined) {
            throw new Error('Required parameter outputNew was null or undefined when calling updateStrategyOutput.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyoutputs/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(outputResourceIdentifier))}`,
            CaseUtils.toSnake(outputNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Strategy
     * Update relevant factors for Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param strategyRelevantFactors Updated relevant factors for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, strategyRelevantFactors: StrategyRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, strategyRelevantFactors: StrategyRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, strategyRelevantFactors: StrategyRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyRelevantFactors(projectIdentifier: string, resourceIdentifier: string, strategyRelevantFactors: StrategyRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyRelevantFactors.');
        }
        if (strategyRelevantFactors === null || strategyRelevantFactors === undefined) {
            throw new Error('Required parameter strategyRelevantFactors was null or undefined when calling updateStrategyRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategyrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(strategyRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStrategyTimeframeRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Strategy timeframe
     * Update timeframe for a specific Strategy
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param factorTimeframeDetail New timeframe for Strategy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStrategyTimeframe(projectIdentifier: string, resourceIdentifier: string, factorTimeframeDetail: FactorTimeframeDetail, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStrategyTimeframe.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStrategyTimeframe.');
        }
        if (factorTimeframeDetail === null || factorTimeframeDetail === undefined) {
            throw new Error('Required parameter factorTimeframeDetail was null or undefined when calling updateStrategyTimeframe.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/strategytimeframe/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(factorTimeframeDetail),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateStressRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update stress
     * Update specific stress for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressNew Updated stress
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateStress(projectIdentifier: string, resourceIdentifier: string, stressNew: StressNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateStress.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateStress.');
        }
        if (stressNew === null || stressNew === undefined) {
            throw new Error('Required parameter stressNew was null or undefined when calling updateStress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/stresses/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(stressNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateThreatReductionResultRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Threat Reduction Result
     * Update specific threat reduction result for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param threatReductionResultNew Updated threat reduction result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, threatReductionResultNew: ThreatReductionResultNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, threatReductionResultNew: ThreatReductionResultNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, threatReductionResultNew: ThreatReductionResultNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateThreatReductionResult(projectIdentifier: string, resourceIdentifier: string, threatReductionResultNew: ThreatReductionResultNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateThreatReductionResult.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateThreatReductionResult.');
        }
        if (threatReductionResultNew === null || threatReductionResultNew === undefined) {
            throw new Error('Required parameter threatReductionResultNew was null or undefined when calling updateThreatReductionResult.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/threatreductionresults/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(threatReductionResultNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateThreatReductionResultIndicatorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Threat Reduction Result indicator
     * Update specific indicator for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param indicatorResourceIdentifier Indicator Unique identifier
     * @param indicatorNew Updated indicator for Threat Reduction Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateThreatReductionResultIndicator(projectIdentifier: string, resourceIdentifier: string, indicatorResourceIdentifier: string, indicatorNew: IndicatorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateThreatReductionResultIndicator.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateThreatReductionResultIndicator.');
        }
        if (indicatorResourceIdentifier === null || indicatorResourceIdentifier === undefined) {
            throw new Error('Required parameter indicatorResourceIdentifier was null or undefined when calling updateThreatReductionResultIndicator.');
        }
        if (indicatorNew === null || indicatorNew === undefined) {
            throw new Error('Required parameter indicatorNew was null or undefined when calling updateThreatReductionResultIndicator.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/threatreductionresultindicators/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(indicatorResourceIdentifier))}`,
            CaseUtils.toSnake(indicatorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateThreatReductionResultObjectiveRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Threat Reduction Result objective
     * Update specific objective for Threat Reduction Result
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param objectiveResourceIdentifier Objective Unique identifier
     * @param objectiveNew Updated objective for Threat Reduction Result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateThreatReductionResultObjective(projectIdentifier: string, resourceIdentifier: string, objectiveResourceIdentifier: string, objectiveNew: ObjectiveNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateThreatReductionResultObjective.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateThreatReductionResultObjective.');
        }
        if (objectiveResourceIdentifier === null || objectiveResourceIdentifier === undefined) {
            throw new Error('Required parameter objectiveResourceIdentifier was null or undefined when calling updateThreatReductionResultObjective.');
        }
        if (objectiveNew === null || objectiveNew === undefined) {
            throw new Error('Required parameter objectiveNew was null or undefined when calling updateThreatReductionResultObjective.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/threatreductionresultobjectives/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(objectiveResourceIdentifier))}`,
            CaseUtils.toSnake(objectiveNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public validateProjectFactorDeleteRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Factor delete info
     * Returns flag indicating whether factor can be deleted without warning or not
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param factorTypePathParam Factor type
     * @param factorResourceIdentifier Factor Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateProjectFactorDelete(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorDeleteInfo>;
    public validateProjectFactorDelete(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorDeleteInfo>>;
    public validateProjectFactorDelete(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorDeleteInfo>>;
    public validateProjectFactorDelete(projectIdentifier: string, factorTypePathParam: FactorType, factorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling validateProjectFactorDelete.');
        }
        if (factorTypePathParam === null || factorTypePathParam === undefined) {
            throw new Error('Required parameter factorTypePathParam was null or undefined when calling validateProjectFactorDelete.');
        }
        if (factorResourceIdentifier === null || factorResourceIdentifier === undefined) {
            throw new Error('Required parameter factorResourceIdentifier was null or undefined when calling validateProjectFactorDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorDeleteInfo>(`${this.configuration.basePath}/projectfactorvalidatedelete/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(factorTypePathParam))}/${encodeURIComponent(String(factorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorDeleteInfo(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorDeleteInfo(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public validateProjectFileCheckinRequiredPermission() {
        return 'CheckIn' || '';
    }

    /**
     * Validate Project file for check-in
     * Validate Project file for check-in
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectFileBytes Project file bytes (.xmpz2 format)
     * @param checkinMessage Check-in message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateProjectFileCheckin(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectFileInfo>;
    public validateProjectFileCheckin(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectFileInfo>>;
    public validateProjectFileCheckin(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectFileInfo>>;
    public validateProjectFileCheckin(projectIdentifier: string, projectFileBytes?: Blob, checkinMessage?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling validateProjectFileCheckin.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (projectFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('project_file_bytes', <any>projectFileBytes) as any || localVarFormParams;
        }
        if (checkinMessage !== undefined) {
            localVarFormParams = localVarFormParams.append('checkin_message', <any>checkinMessage) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectFileInfo>(`${this.configuration.basePath}/projectvalidatecheckin/${encodeURIComponent(String(projectIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFileInfo(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFileInfo(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

}
