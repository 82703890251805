/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { map }                                               from 'rxjs/operators';


import { AssociatedFile } from '../model/associatedFile';
import { AssociatedLink } from '../model/associatedLink';
import { AssociatedLinkNew } from '../model/associatedLinkNew';
import { ChartData } from '../model/chartData';
import { CustomReport } from '../model/customReport';
import { CustomReportNew } from '../model/customReportNew';
import { FeatureCollection } from '../model/featureCollection';
import { Portfolio } from '../model/portfolio';
import { PortfolioNew } from '../model/portfolioNew';
import { ProblemDetail } from '../model/problemDetail';
import { Program } from '../model/program';
import { ProgramAssumptionSummaryReport } from '../model/programAssumptionSummaryReport';
import { ProgramCustomReportGroup } from '../model/programCustomReportGroup';
import { ProgramCustomReportGroupNew } from '../model/programCustomReportGroupNew';
import { ProgramFactorEvidenceReport } from '../model/programFactorEvidenceReport';
import { ProgramFactorSummaryReport } from '../model/programFactorSummaryReport';
import { ProgramFactorTaxonomyClassificationsReport } from '../model/programFactorTaxonomyClassificationsReport';
import { ProgramGoalObjectiveAssessmentReport } from '../model/programGoalObjectiveAssessmentReport';
import { ProgramGoalObjectiveFactorProgressReport } from '../model/programGoalObjectiveFactorProgressReport';
import { ProgramMap } from '../model/programMap';
import { ProgramMapLayer } from '../model/programMapLayer';
import { ProgramMember } from '../model/programMember';
import { ProgramMemberNew } from '../model/programMemberNew';
import { ProgramMemberUpdate } from '../model/programMemberUpdate';
import { ProgramProgress } from '../model/programProgress';
import { ProgramReportDocumentTemplate } from '../model/programReportDocumentTemplate';
import { ProgramReportDocumentTemplateCopy } from '../model/programReportDocumentTemplateCopy';
import { ProgramResource } from '../model/programResource';
import { ProgramResourceCopy } from '../model/programResourceCopy';
import { ProgramResultsProgressReport } from '../model/programResultsProgressReport';
import { ProgramStrategyActivityProgressReport } from '../model/programStrategyActivityProgressReport';
import { ProgramSummary } from '../model/programSummary';
import { ProgramSummaryUpdate } from '../model/programSummaryUpdate';
import { ProgramTargetSummaryReport } from '../model/programTargetSummaryReport';
import { ProgramTemplate } from '../model/programTemplate';
import { ProgramTemplateTaxonomy } from '../model/programTemplateTaxonomy';
import { ProgramThreatSummaryReport } from '../model/programThreatSummaryReport';
import { ProgramViabilityAssessmentReport } from '../model/programViabilityAssessmentReport';
import { ProgramWorkPlanReport } from '../model/programWorkPlanReport';
import { ProjectFactorGoal } from '../model/projectFactorGoal';
import { ProjectFactorGoalProgress } from '../model/projectFactorGoalProgress';
import { ProjectFactorIndicator } from '../model/projectFactorIndicator';
import { ProjectFactorObjective } from '../model/projectFactorObjective';
import { ProjectFactorObjectiveProgress } from '../model/projectFactorObjectiveProgress';
import { ProjectFootprint } from '../model/projectFootprint';
import { ProjectStrategyActivity } from '../model/projectStrategyActivity';
import { ProjectStrategyExpense } from '../model/projectStrategyExpense';
import { ProjectStrategyObjective } from '../model/projectStrategyObjective';
import { ProjectTargetKeyEcologicalAttribute } from '../model/projectTargetKeyEcologicalAttribute';
import { ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications } from '../model/projectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications';
import { ProjectTeam } from '../model/projectTeam';
import { ProjectTeamNew } from '../model/projectTeamNew';
import { ProjectWithFactorIndicatorMeasurements } from '../model/projectWithFactorIndicatorMeasurements';
import { ProjectWithFactorIndicatorTaxonomyClassifications } from '../model/projectWithFactorIndicatorTaxonomyClassifications';
import { SlideShowImage } from '../model/slideShowImage';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

import { CaseUtils } from 'app/shared/utils';



@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

    protected basePath = 'http://localhost/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.configuration.withCredentials = true;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    public copyProgramReportDocumentTemplatesRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Copy Program report document templates
     * Copy report document templates to Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param programReportDocumentTemplateCopy Report document templates to copy, Program to copy to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyProgramReportDocumentTemplates(programIdentifier: string, programReportDocumentTemplateCopy: ProgramReportDocumentTemplateCopy, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramReportDocumentTemplate>>;
    public copyProgramReportDocumentTemplates(programIdentifier: string, programReportDocumentTemplateCopy: ProgramReportDocumentTemplateCopy, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramReportDocumentTemplate>>>;
    public copyProgramReportDocumentTemplates(programIdentifier: string, programReportDocumentTemplateCopy: ProgramReportDocumentTemplateCopy, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramReportDocumentTemplate>>>;
    public copyProgramReportDocumentTemplates(programIdentifier: string, programReportDocumentTemplateCopy: ProgramReportDocumentTemplateCopy, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling copyProgramReportDocumentTemplates.');
        }
        if (programReportDocumentTemplateCopy === null || programReportDocumentTemplateCopy === undefined) {
            throw new Error('Required parameter programReportDocumentTemplateCopy was null or undefined when calling copyProgramReportDocumentTemplates.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<ProgramReportDocumentTemplate>>(`${this.configuration.basePath}/programreportdocumenttemplatescopy/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(programReportDocumentTemplateCopy),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramReportDocumentTemplate(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramReportDocumentTemplate(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public copyProgramResourcesRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Copy program resources
     * Copy program resources
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param programResourceCopy Program resources to copy, Projects to copy to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyProgramResources(programIdentifier: string, programResourceCopy: ProgramResourceCopy, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramResource>>;
    public copyProgramResources(programIdentifier: string, programResourceCopy: ProgramResourceCopy, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramResource>>>;
    public copyProgramResources(programIdentifier: string, programResourceCopy: ProgramResourceCopy, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramResource>>>;
    public copyProgramResources(programIdentifier: string, programResourceCopy: ProgramResourceCopy, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling copyProgramResources.');
        }
        if (programResourceCopy === null || programResourceCopy === undefined) {
            throw new Error('Required parameter programResourceCopy was null or undefined when calling copyProgramResources.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<ProgramResource>>(`${this.configuration.basePath}/programresources/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(programResourceCopy),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramAssociatedFileRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program associated file
     * Add new associated file for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param fileBytesList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramAssociatedFile(programIdentifier: string, fileBytesList?: Array<Blob>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssociatedFile>>;
    public createProgramAssociatedFile(programIdentifier: string, fileBytesList?: Array<Blob>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssociatedFile>>>;
    public createProgramAssociatedFile(programIdentifier: string, fileBytesList?: Array<Blob>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssociatedFile>>>;
    public createProgramAssociatedFile(programIdentifier: string, fileBytesList?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramAssociatedFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (fileBytesList) {
            if (localVarUseForm) {
                fileBytesList.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('file_bytes_list', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('file_bytes_list', fileBytesList.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<AssociatedFile>>(`${this.configuration.basePath}/programassociatedfiles/${encodeURIComponent(String(programIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedFile(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedFile(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramAssociatedLinkRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program associated link
     * Add new associated link for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param associatedLinkNew New associated link for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramAssociatedLink(programIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssociatedLink>;
    public createProgramAssociatedLink(programIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssociatedLink>>;
    public createProgramAssociatedLink(programIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssociatedLink>>;
    public createProgramAssociatedLink(programIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramAssociatedLink.');
        }
        if (associatedLinkNew === null || associatedLinkNew === undefined) {
            throw new Error('Required parameter associatedLinkNew was null or undefined when calling createProgramAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<AssociatedLink>(`${this.configuration.basePath}/programassociatedlinks/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(associatedLinkNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramCustomReportRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program custom report
     * Add new custom report for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param customReportNew New custom report for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramCustomReport(programIdentifier: string, customReportNew: CustomReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CustomReport>;
    public createProgramCustomReport(programIdentifier: string, customReportNew: CustomReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CustomReport>>;
    public createProgramCustomReport(programIdentifier: string, customReportNew: CustomReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CustomReport>>;
    public createProgramCustomReport(programIdentifier: string, customReportNew: CustomReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramCustomReport.');
        }
        if (customReportNew === null || customReportNew === undefined) {
            throw new Error('Required parameter customReportNew was null or undefined when calling createProgramCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CustomReport>(`${this.configuration.basePath}/programcustomreports/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(customReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new CustomReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new CustomReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramCustomReportGroupRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program custom report group
     * Add new custom report group for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param programCustomReportGroupNew New custom report group for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramCustomReportGroup(programIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramCustomReportGroup>;
    public createProgramCustomReportGroup(programIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramCustomReportGroup>>;
    public createProgramCustomReportGroup(programIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramCustomReportGroup>>;
    public createProgramCustomReportGroup(programIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramCustomReportGroup.');
        }
        if (programCustomReportGroupNew === null || programCustomReportGroupNew === undefined) {
            throw new Error('Required parameter programCustomReportGroupNew was null or undefined when calling createProgramCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgramCustomReportGroup>(`${this.configuration.basePath}/programcustomreportgroups/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(programCustomReportGroupNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramCustomReportGroup(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramCustomReportGroup(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramMapLayerRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program map layer
     * Add new map layer for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param shapeFileBytes GIS shapefile (zip archive)
     * @param name Map layer name
     * @param description Miradi formatted_text fields (allows limited html markup)
     * @param source Miradi formatted_text fields (allows limited html markup)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramMapLayer(programIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramMapLayer>;
    public createProgramMapLayer(programIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramMapLayer>>;
    public createProgramMapLayer(programIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramMapLayer>>;
    public createProgramMapLayer(programIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramMapLayer.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (shapeFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('shape_file_bytes', <any>shapeFileBytes) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }
        if (source !== undefined) {
            localVarFormParams = localVarFormParams.append('source', <any>source) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgramMapLayer>(`${this.configuration.basePath}/programmaplayers/${encodeURIComponent(String(programIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMapLayer(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMapLayer(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramMemberRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program member
     * Add new member for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param programMemberNew New member for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramMember(programIdentifier: string, programMemberNew: ProgramMemberNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramMember>;
    public createProgramMember(programIdentifier: string, programMemberNew: ProgramMemberNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramMember>>;
    public createProgramMember(programIdentifier: string, programMemberNew: ProgramMemberNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramMember>>;
    public createProgramMember(programIdentifier: string, programMemberNew: ProgramMemberNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramMember.');
        }
        if (programMemberNew === null || programMemberNew === undefined) {
            throw new Error('Required parameter programMemberNew was null or undefined when calling createProgramMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgramMember>(`${this.configuration.basePath}/programmembers/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(programMemberNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMember(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMember(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramPortfolioRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program portfolio
     * Add new portfolio for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param portfolioNew New portfolio for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramPortfolio(programIdentifier: string, portfolioNew: PortfolioNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Portfolio>;
    public createProgramPortfolio(programIdentifier: string, portfolioNew: PortfolioNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Portfolio>>;
    public createProgramPortfolio(programIdentifier: string, portfolioNew: PortfolioNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Portfolio>>;
    public createProgramPortfolio(programIdentifier: string, portfolioNew: PortfolioNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramPortfolio.');
        }
        if (portfolioNew === null || portfolioNew === undefined) {
            throw new Error('Required parameter portfolioNew was null or undefined when calling createProgramPortfolio.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Portfolio>(`${this.configuration.basePath}/programportfolios/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(portfolioNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Portfolio(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Portfolio(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramProjectTeamMemberRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program project team member
     * Add new project team member for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param projectTeamNew New member for Program project team(s)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramProjectTeamMember(programIdentifier: string, projectTeamNew: ProjectTeamNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public createProgramProjectTeamMember(programIdentifier: string, projectTeamNew: ProjectTeamNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public createProgramProjectTeamMember(programIdentifier: string, projectTeamNew: ProjectTeamNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public createProgramProjectTeamMember(programIdentifier: string, projectTeamNew: ProjectTeamNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramProjectTeamMember.');
        }
        if (projectTeamNew === null || projectTeamNew === undefined) {
            throw new Error('Required parameter projectTeamNew was null or undefined when calling createProgramProjectTeamMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/programprojectteammembers/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(projectTeamNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramReportDocumentTemplateRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program report document template
     * Add new Program-specific report document template
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param templateFileBytes Template file bytes (supported content types are docx. Max file size allowed is 15MB.)
     * @param name Template name (unique within program)
     * @param templateDescription Template description
     * @param templateGuidance Template guidance
     * @param isActive Indicates whether template is active or not (i.e. visible to relevant projects)
     * @param relevantProjectResourceIdentifiers Relevant Project for Template
     * @param filterTags List of tags for filtering
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramReportDocumentTemplate(programIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramReportDocumentTemplate>;
    public createProgramReportDocumentTemplate(programIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramReportDocumentTemplate>>;
    public createProgramReportDocumentTemplate(programIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramReportDocumentTemplate>>;
    public createProgramReportDocumentTemplate(programIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramReportDocumentTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (templateFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('template_file_bytes', <any>templateFileBytes) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (templateDescription !== undefined) {
            localVarFormParams = localVarFormParams.append('template_description', <any>templateDescription) as any || localVarFormParams;
        }
        if (templateGuidance !== undefined) {
            localVarFormParams = localVarFormParams.append('template_guidance', <any>templateGuidance) as any || localVarFormParams;
        }
        if (isActive !== undefined) {
            localVarFormParams = localVarFormParams.append('is_active', <any>isActive) as any || localVarFormParams;
        }
        if (relevantProjectResourceIdentifiers) {
            if (localVarUseForm) {
                relevantProjectResourceIdentifiers.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('relevant_project_resource_identifiers', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('relevant_project_resource_identifiers', relevantProjectResourceIdentifiers.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }
        if (filterTags) {
            if (localVarUseForm) {
                filterTags.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('filter_tags', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('filter_tags', filterTags.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProgramReportDocumentTemplate>(`${this.configuration.basePath}/programreportdocumenttemplates/${encodeURIComponent(String(programIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramReportDocumentTemplate(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramReportDocumentTemplate(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProgramSlideshowImageRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Create Program slideshow image
     * Add new program slideshow image for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param imageFileBytes Image file bytes (supported content types are png, jpeg, gif, bmp and tif. Max file size allowed is 3MB. All images will be converted to .png.)
     * @param caption Image caption
     * @param imageOrder Image order - defaults to next in sequence if not supplied
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgramSlideshowImage(programIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SlideShowImage>;
    public createProgramSlideshowImage(programIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SlideShowImage>>;
    public createProgramSlideshowImage(programIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SlideShowImage>>;
    public createProgramSlideshowImage(programIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling createProgramSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (imageFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('image_file_bytes', <any>imageFileBytes) as any || localVarFormParams;
        }
        if (caption !== undefined) {
            localVarFormParams = localVarFormParams.append('caption', <any>caption) as any || localVarFormParams;
        }
        if (imageOrder !== undefined) {
            localVarFormParams = localVarFormParams.append('image_order', <any>imageOrder) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SlideShowImage>(`${this.configuration.basePath}/programslideshowimages/${encodeURIComponent(String(programIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SlideShowImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SlideShowImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramAssociatedFileRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program associated file
     * Delete specific associated file for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramAssociatedFile.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramAssociatedFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programassociatedfiles/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramAssociatedLinkRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program associated link
     * Delete specific associated link for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramAssociatedLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programassociatedlinks/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramCustomReportRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program custom report
     * Delete specific custom report for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramCustomReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programcustomreports/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramCustomReportGroupRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program custom report group
     * Delete specific custom report group for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramCustomReportGroup.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programcustomreportgroups/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramMapLayerRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program map layer
     * Delete specific program map layer for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramMapLayer.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramMapLayer.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programmaplayers/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramMemberRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program member
     * Delete specific member for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramMember(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramMember(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramMember(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramMember(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramMember.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programmembers/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramPortfolioRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program portfolio
     * Delete specific portfolio for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramPortfolio.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramPortfolio.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programportfolios/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramReportDocumentTemplateRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program report document template
     * Delete Program-specific report document template
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramReportDocumentTemplate.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramReportDocumentTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programreportdocumenttemplates/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProgramSlideshowImageRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Delete Program slideshow image
     * Delete specific program slideshow image for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling deleteProgramSlideshowImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProgramSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/programslideshowimages/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getMyProgramsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get list of Programs
     * Get list of Programs where end-user is on project team (or is program member)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyPrograms(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Program>>;
    public getMyPrograms(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Program>>>;
    public getMyPrograms(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Program>>>;
    public getMyPrograms(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Program>>(`${this.configuration.basePath}/myprograms`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Program(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Program(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Find Program by Identifier
     * Returns a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgram(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Program>;
    public getProgram(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Program>>;
    public getProgram(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Program>>;
    public getProgram(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgram.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Program>(`${this.configuration.basePath}/programs/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Program(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Program(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramAssociatedFileRequiredPermission() {
        return '' || 'ViewProgramDetail';
    }

    /**
     * Get Program associated file
     * Get specific associated files for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssociatedFile>;
    public getProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssociatedFile>>;
    public getProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssociatedFile>>;
    public getProgramAssociatedFile(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramAssociatedFile.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramAssociatedFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AssociatedFile>(`${this.configuration.basePath}/programassociatedfiles/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedFile(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedFile(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramAssociatedFilesRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program associated files
     * Get list of associated files for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramAssociatedFiles(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssociatedFile>>;
    public getProgramAssociatedFiles(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssociatedFile>>>;
    public getProgramAssociatedFiles(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssociatedFile>>>;
    public getProgramAssociatedFiles(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramAssociatedFiles.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AssociatedFile>>(`${this.configuration.basePath}/programassociatedfiles/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedFile(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedFile(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramAssociatedLinkRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program associated link
     * Get specific associated links for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssociatedLink>;
    public getProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssociatedLink>>;
    public getProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssociatedLink>>;
    public getProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramAssociatedLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AssociatedLink>(`${this.configuration.basePath}/programassociatedlinks/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramAssociatedLinksRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program associated links
     * Get list of associated links for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramAssociatedLinks(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AssociatedLink>>;
    public getProgramAssociatedLinks(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AssociatedLink>>>;
    public getProgramAssociatedLinks(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AssociatedLink>>>;
    public getProgramAssociatedLinks(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramAssociatedLinks.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AssociatedLink>>(`${this.configuration.basePath}/programassociatedlinks/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssociatedLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssociatedLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramAssumptionsRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Assumption Summary by Program Identifier
     * Returns Program Assumption Summary
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramAssumptions(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramAssumptionSummaryReport>;
    public getProgramAssumptions(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramAssumptionSummaryReport>>;
    public getProgramAssumptions(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramAssumptionSummaryReport>>;
    public getProgramAssumptions(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramAssumptions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramAssumptionSummaryReport>(`${this.configuration.basePath}/programassumptions/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramAssumptionSummaryReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramAssumptionSummaryReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramChartsRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Find Program charts by Identifier
     * Returns chart data for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramCharts(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ChartData>>;
    public getProgramCharts(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ChartData>>>;
    public getProgramCharts(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ChartData>>>;
    public getProgramCharts(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramCharts.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ChartData>>(`${this.configuration.basePath}/programcharts/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ChartData(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ChartData(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramCustomReportRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program custom report
     * Get specific custom report for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CustomReport>;
    public getProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CustomReport>>;
    public getProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CustomReport>>;
    public getProgramCustomReport(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramCustomReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CustomReport>(`${this.configuration.basePath}/programcustomreports/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new CustomReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new CustomReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramCustomReportGroupRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program custom report group
     * Get specific custom report group for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramCustomReportGroup>;
    public getProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramCustomReportGroup>>;
    public getProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramCustomReportGroup>>;
    public getProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramCustomReportGroup.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramCustomReportGroup>(`${this.configuration.basePath}/programcustomreportgroups/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramCustomReportGroup(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramCustomReportGroup(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramCustomReportGroupsRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program custom report groups
     * Get list of custom report groups for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramCustomReportGroups(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramCustomReportGroup>>;
    public getProgramCustomReportGroups(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramCustomReportGroup>>>;
    public getProgramCustomReportGroups(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramCustomReportGroup>>>;
    public getProgramCustomReportGroups(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramCustomReportGroups.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramCustomReportGroup>>(`${this.configuration.basePath}/programcustomreportgroups/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramCustomReportGroup(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramCustomReportGroup(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramCustomReportsRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program custom reports
     * Get list of custom reports for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramCustomReports(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<CustomReport>>;
    public getProgramCustomReports(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<CustomReport>>>;
    public getProgramCustomReports(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<CustomReport>>>;
    public getProgramCustomReports(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramCustomReports.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<CustomReport>>(`${this.configuration.basePath}/programcustomreports/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new CustomReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new CustomReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorEvidenceRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Factor Evidence by Program Identifier
     * Returns Program Factor Evidence
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorEvidence(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramFactorEvidenceReport>;
    public getProgramFactorEvidence(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramFactorEvidenceReport>>;
    public getProgramFactorEvidence(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramFactorEvidenceReport>>;
    public getProgramFactorEvidence(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorEvidence.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramFactorEvidenceReport>(`${this.configuration.basePath}/programfactorevidence/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramFactorEvidenceReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramFactorEvidenceReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorGoalProgressRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Factor goal progress
     * Get list of Progress Percents for Factor Goals across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorGoalProgress(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectFactorGoalProgress>>;
    public getProgramFactorGoalProgress(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectFactorGoalProgress>>>;
    public getProgramFactorGoalProgress(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectFactorGoalProgress>>>;
    public getProgramFactorGoalProgress(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorGoalProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectFactorGoalProgress>>(`${this.configuration.basePath}/programfactorgoalprogress/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorGoalProgress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorGoalProgress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorGoalsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Factor goals
     * Get list of Goals for Factors across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorGoals(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectFactorGoal>>;
    public getProgramFactorGoals(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectFactorGoal>>>;
    public getProgramFactorGoals(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectFactorGoal>>>;
    public getProgramFactorGoals(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorGoals.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectFactorGoal>>(`${this.configuration.basePath}/programfactorgoals/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorGoal(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorGoal(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorIndicatorMeasurementsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Indicator measurements
     * Get list of Measures for Indicators for Factors across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorIndicatorMeasurements(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectWithFactorIndicatorMeasurements>>;
    public getProgramFactorIndicatorMeasurements(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectWithFactorIndicatorMeasurements>>>;
    public getProgramFactorIndicatorMeasurements(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectWithFactorIndicatorMeasurements>>>;
    public getProgramFactorIndicatorMeasurements(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorIndicatorMeasurements.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectWithFactorIndicatorMeasurements>>(`${this.configuration.basePath}/programfactorindicatormeasurements/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectWithFactorIndicatorMeasurements(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectWithFactorIndicatorMeasurements(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorIndicatorTaxonomyClassificationsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Indicator Taxonomy Classifications
     * Get list of Taxonomy Classifications for Indicators for Factors across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>;
    public getProgramFactorIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>>;
    public getProgramFactorIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>>;
    public getProgramFactorIndicatorTaxonomyClassifications(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorIndicatorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>(`${this.configuration.basePath}/programfactorindicatortaxonomyclassifications/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorIndicatorsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Factor indicators
     * Get list of Indicators for Factors across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorIndicators(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectFactorIndicator>>;
    public getProgramFactorIndicators(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectFactorIndicator>>>;
    public getProgramFactorIndicators(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectFactorIndicator>>>;
    public getProgramFactorIndicators(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectFactorIndicator>>(`${this.configuration.basePath}/programfactorindicators/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorIndicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorIndicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorObjectiveProgressRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Factor objective progress
     * Get list of Progress Percents for Factor Objectives across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorObjectiveProgress(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectFactorObjectiveProgress>>;
    public getProgramFactorObjectiveProgress(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectFactorObjectiveProgress>>>;
    public getProgramFactorObjectiveProgress(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectFactorObjectiveProgress>>>;
    public getProgramFactorObjectiveProgress(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorObjectiveProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectFactorObjectiveProgress>>(`${this.configuration.basePath}/programfactorobjectiveprogress/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorObjectiveProgress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorObjectiveProgress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorObjectivesRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Factor objectives
     * Get list of Objectives for Factors across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorObjectives(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectFactorObjective>>;
    public getProgramFactorObjectives(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectFactorObjective>>>;
    public getProgramFactorObjectives(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectFactorObjective>>>;
    public getProgramFactorObjectives(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectFactorObjective>>(`${this.configuration.basePath}/programfactorobjectives/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorObjective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorObjective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorSummaryRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Factor Summary by Program Identifier
     * Returns Program Factor Summary
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorSummary(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramFactorSummaryReport>;
    public getProgramFactorSummary(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramFactorSummaryReport>>;
    public getProgramFactorSummary(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramFactorSummaryReport>>;
    public getProgramFactorSummary(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramFactorSummaryReport>(`${this.configuration.basePath}/programfactorsummary/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramFactorSummaryReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramFactorSummaryReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramFactorTaxonomyClassificationsRequiredPermission() {
        return 'ViewProgramBasics' || '';
    }

    /**
     * Get Factor Taxonomy Classifications by Program Identifier
     * Returns Factor Taxonomy Classifications for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramFactorTaxonomyClassifications(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramFactorTaxonomyClassificationsReport>;
    public getProgramFactorTaxonomyClassifications(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramFactorTaxonomyClassificationsReport>>;
    public getProgramFactorTaxonomyClassifications(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramFactorTaxonomyClassificationsReport>>;
    public getProgramFactorTaxonomyClassifications(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramFactorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramFactorTaxonomyClassificationsReport>(`${this.configuration.basePath}/programfactortaxonomyclassifications/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramFactorTaxonomyClassificationsReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramFactorTaxonomyClassificationsReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramGoalObjectiveAssessmentRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Goal / Objective Assessment by Program Identifier
     * Returns Program Goal / Objective Assessment
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramGoalObjectiveAssessment(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramGoalObjectiveAssessmentReport>;
    public getProgramGoalObjectiveAssessment(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramGoalObjectiveAssessmentReport>>;
    public getProgramGoalObjectiveAssessment(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramGoalObjectiveAssessmentReport>>;
    public getProgramGoalObjectiveAssessment(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramGoalObjectiveAssessment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramGoalObjectiveAssessmentReport>(`${this.configuration.basePath}/programgoalobjectiveassessment/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramGoalObjectiveAssessmentReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramGoalObjectiveAssessmentReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramGoalObjectiveFactorProgressRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Goal / Objective Factor Progress by Program Identifier
     * Returns Program Goal / Objective Factor Progress
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramGoalObjectiveFactorProgress(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramGoalObjectiveFactorProgressReport>;
    public getProgramGoalObjectiveFactorProgress(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramGoalObjectiveFactorProgressReport>>;
    public getProgramGoalObjectiveFactorProgress(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramGoalObjectiveFactorProgressReport>>;
    public getProgramGoalObjectiveFactorProgress(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramGoalObjectiveFactorProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramGoalObjectiveFactorProgressReport>(`${this.configuration.basePath}/programgoalobjectivefactorprogress/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramGoalObjectiveFactorProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramGoalObjectiveFactorProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramMapRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Find Program map data by Identifier
     * Returns map data for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramMap(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramMap>;
    public getProgramMap(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramMap>>;
    public getProgramMap(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramMap>>;
    public getProgramMap(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramMap.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramMap>(`${this.configuration.basePath}/programmap/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMap(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMap(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramMapLayerRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Find Program map layer by Identifier
     * Returns specific map layer for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramMapLayer>;
    public getProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramMapLayer>>;
    public getProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramMapLayer>>;
    public getProgramMapLayer(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramMapLayer.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramMapLayer.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramMapLayer>(`${this.configuration.basePath}/programmaplayers/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMapLayer(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMapLayer(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramMapLayerFileRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Download Program map layer shapefile
     * Download Program map layer shapefile
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramMapLayerFile(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<Blob>;
    public getProgramMapLayerFile(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getProgramMapLayerFile(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getProgramMapLayerFile(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramMapLayerFile.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramMapLayerFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'multipart/form-data',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.get(`${this.configuration.basePath}/programmaplayerfiles/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Blob(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Blob(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramMapLayersRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Find Program map layers by Identifier
     * Returns map layers for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramMapLayers(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramMapLayer>>;
    public getProgramMapLayers(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramMapLayer>>>;
    public getProgramMapLayers(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramMapLayer>>>;
    public getProgramMapLayers(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramMapLayers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramMapLayer>>(`${this.configuration.basePath}/programmaplayers/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMapLayer(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMapLayer(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramMemberRequiredPermission() {
        return '' || 'ViewProgramMembers';
    }

    /**
     * Get Program member
     * Get specific member for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramMember(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramMember>;
    public getProgramMember(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramMember>>;
    public getProgramMember(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramMember>>;
    public getProgramMember(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramMember.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramMember>(`${this.configuration.basePath}/programmembers/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMember(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMember(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramMembersRequiredPermission() {
        return '' || 'ViewProgramMembers';
    }

    /**
     * Get program members by Program Identifier
     * Returns members for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramMembers(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramMember>>;
    public getProgramMembers(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramMember>>>;
    public getProgramMembers(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramMember>>>;
    public getProgramMembers(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramMembers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramMember>>(`${this.configuration.basePath}/programmembers/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMember(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMember(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramPortfolioRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program portfolio
     * Get specific portfolio for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Portfolio>;
    public getProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Portfolio>>;
    public getProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Portfolio>>;
    public getProgramPortfolio(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramPortfolio.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramPortfolio.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Portfolio>(`${this.configuration.basePath}/programportfolios/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Portfolio(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Portfolio(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramPortfoliosRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program portfolios
     * Get list of portfolios for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramPortfolios(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Portfolio>>;
    public getProgramPortfolios(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Portfolio>>>;
    public getProgramPortfolios(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Portfolio>>>;
    public getProgramPortfolios(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramPortfolios.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Portfolio>>(`${this.configuration.basePath}/programportfolios/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Portfolio(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Portfolio(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramProgressRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get project progress report hierarchy for Program
     * Returns project progress report hierarchy for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramProgress(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramProgress>;
    public getProgramProgress(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramProgress>>;
    public getProgramProgress(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramProgress>>;
    public getProgramProgress(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramProgress>(`${this.configuration.basePath}/programprogress/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramProgress(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramProgress(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramProjectTeamMembersRequiredPermission() {
        return '' || 'ViewProgramMembers';
    }

    /**
     * Get program project team members by Program Identifier
     * Returns project teams for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramProjectTeamMembers(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectTeam>>;
    public getProgramProjectTeamMembers(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectTeam>>>;
    public getProgramProjectTeamMembers(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectTeam>>>;
    public getProgramProjectTeamMembers(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramProjectTeamMembers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectTeam>>(`${this.configuration.basePath}/programprojectteammembers/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTeam(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTeam(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramReportDocumentTemplateRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program report document template
     * Get report document template (Program-specific or standard)
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramReportDocumentTemplate>;
    public getProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramReportDocumentTemplate>>;
    public getProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramReportDocumentTemplate>>;
    public getProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramReportDocumentTemplate.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramReportDocumentTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramReportDocumentTemplate>(`${this.configuration.basePath}/programreportdocumenttemplates/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramReportDocumentTemplate(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramReportDocumentTemplate(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramReportDocumentTemplateFileRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Download program report document file
     * Download report document template file (.docx) (Program-specific or standard)
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramReportDocumentTemplateFile(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<Blob>;
    public getProgramReportDocumentTemplateFile(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getProgramReportDocumentTemplateFile(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getProgramReportDocumentTemplateFile(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'multipart/form-data' | 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramReportDocumentTemplateFile.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramReportDocumentTemplateFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'multipart/form-data',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.get(`${this.configuration.basePath}/programreportdocumenttemplatefiles/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Blob(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Blob(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramReportDocumentTemplatesRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get program report document templates by Program Identifier
     * Returns report document templates (both Program-specific and standard)
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramReportDocumentTemplates(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramReportDocumentTemplate>>;
    public getProgramReportDocumentTemplates(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramReportDocumentTemplate>>>;
    public getProgramReportDocumentTemplates(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramReportDocumentTemplate>>>;
    public getProgramReportDocumentTemplates(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramReportDocumentTemplates.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramReportDocumentTemplate>>(`${this.configuration.basePath}/programreportdocumenttemplates/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramReportDocumentTemplate(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramReportDocumentTemplate(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramResourcesRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program resources
     * Get list of program resources for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramResources(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramResource>>;
    public getProgramResources(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramResource>>>;
    public getProgramResources(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramResource>>>;
    public getProgramResources(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramResources.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramResource>>(`${this.configuration.basePath}/programresources/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramResource(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramResource(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramResultsProgressRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Results Progress by Program Identifier
     * Returns Program Results Progress
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramResultsProgress(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramResultsProgressReport>;
    public getProgramResultsProgress(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramResultsProgressReport>>;
    public getProgramResultsProgress(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramResultsProgressReport>>;
    public getProgramResultsProgress(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramResultsProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramResultsProgressReport>(`${this.configuration.basePath}/programresultsprogress/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramResultsProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramResultsProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramSlideshowImageRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program slideshow image
     * Get specific program slideshow image for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SlideShowImage>;
    public getProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SlideShowImage>>;
    public getProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SlideShowImage>>;
    public getProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramSlideshowImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProgramSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SlideShowImage>(`${this.configuration.basePath}/programslideshowimages/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SlideShowImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SlideShowImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramSlideshowImagesRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program slideshow images
     * Get list of program slideshow images for Program (in image order)
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramSlideshowImages(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<SlideShowImage>>;
    public getProgramSlideshowImages(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<SlideShowImage>>>;
    public getProgramSlideshowImages(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<SlideShowImage>>>;
    public getProgramSlideshowImages(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramSlideshowImages.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SlideShowImage>>(`${this.configuration.basePath}/programslideshowimages/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SlideShowImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SlideShowImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramStrategyActivitiesRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Strategy activities
     * Get list of Activities for Strategies across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param latestProgressReport Flag indicating whether latest progres report should be returned or not (default is false)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramStrategyActivities(programIdentifier: string, latestProgressReport?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectStrategyActivity>>;
    public getProgramStrategyActivities(programIdentifier: string, latestProgressReport?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectStrategyActivity>>>;
    public getProgramStrategyActivities(programIdentifier: string, latestProgressReport?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectStrategyActivity>>>;
    public getProgramStrategyActivities(programIdentifier: string, latestProgressReport?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramStrategyActivities.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (latestProgressReport !== undefined && latestProgressReport !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>latestProgressReport, 'latest_progress_report');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectStrategyActivity>>(`${this.configuration.basePath}/strategyactivities/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectStrategyActivity(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectStrategyActivity(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramStrategyActivityProgressRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Strategy / Activity Progress by Program Identifier
     * Returns Program Strategy / Activity Progress
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramStrategyActivityProgress(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramStrategyActivityProgressReport>;
    public getProgramStrategyActivityProgress(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramStrategyActivityProgressReport>>;
    public getProgramStrategyActivityProgress(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramStrategyActivityProgressReport>>;
    public getProgramStrategyActivityProgress(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramStrategyActivityProgress.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramStrategyActivityProgressReport>(`${this.configuration.basePath}/programstrategyactivityprogress/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramStrategyActivityProgressReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramStrategyActivityProgressReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramStrategyExpensesRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Strategy expenses
     * Get list of Expense Assignments for Strategies across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramStrategyExpenses(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectStrategyExpense>>;
    public getProgramStrategyExpenses(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectStrategyExpense>>>;
    public getProgramStrategyExpenses(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectStrategyExpense>>>;
    public getProgramStrategyExpenses(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramStrategyExpenses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectStrategyExpense>>(`${this.configuration.basePath}/programstrategyexpenses/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectStrategyExpense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectStrategyExpense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramStrategyObjectivesRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Strategy objectives
     * Get list of Objectives for Strategy across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramStrategyObjectives(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectStrategyObjective>>;
    public getProgramStrategyObjectives(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectStrategyObjective>>>;
    public getProgramStrategyObjectives(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectStrategyObjective>>>;
    public getProgramStrategyObjectives(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramStrategyObjectives.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectStrategyObjective>>(`${this.configuration.basePath}/strategyobjectives/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectStrategyObjective(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectStrategyObjective(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramSummaryRequiredPermission() {
        return '' || 'ViewProgramBasic';
    }

    /**
     * Find Program summary by Identifier
     * Returns summary for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramSummary(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramSummary>;
    public getProgramSummary(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramSummary>>;
    public getProgramSummary(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramSummary>>;
    public getProgramSummary(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramSummary>(`${this.configuration.basePath}/programsummary/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramSummary(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramSummary(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramTargetKeaIndicatorTaxonomyClassificationsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get KEA Indicator Taxonomy Classifications
     * Get list of Taxonomy Classifications for Indicators for KEAs across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramTargetKeaIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>>;
    public getProgramTargetKeaIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>>>;
    public getProgramTargetKeaIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>>>;
    public getProgramTargetKeaIndicatorTaxonomyClassifications(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramTargetKeaIndicatorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications>>(`${this.configuration.basePath}/programtargetkeaindicatortaxonomyclassifications/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTargetKeyEcologicalAttributeWithIndicatorTaxonomyClassifications(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramTargetKeaIndicatorsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Target KEA indicators
     * Get list of Key Ecological Attribute Indicators for Targets across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramTargetKeaIndicators(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectTargetKeyEcologicalAttribute>>;
    public getProgramTargetKeaIndicators(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectTargetKeyEcologicalAttribute>>>;
    public getProgramTargetKeaIndicators(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectTargetKeyEcologicalAttribute>>>;
    public getProgramTargetKeaIndicators(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramTargetKeaIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectTargetKeyEcologicalAttribute>>(`${this.configuration.basePath}/programtargetkeaindicators/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectTargetKeyEcologicalAttribute(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectTargetKeyEcologicalAttribute(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramTargetSummaryRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Target Summary by Program Identifier
     * Returns Program Target Summary
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramTargetSummary(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramTargetSummaryReport>;
    public getProgramTargetSummary(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramTargetSummaryReport>>;
    public getProgramTargetSummary(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramTargetSummaryReport>>;
    public getProgramTargetSummary(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramTargetSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramTargetSummaryReport>(`${this.configuration.basePath}/programtargetsummary/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramTargetSummaryReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramTargetSummaryReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramTemplateTaxonomiesRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Find configured taxonomies for Program Template by Identifier
     * Returns configured taxonomies for a specific Program Template
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param programTemplateResourceIdentifier Program template Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramTemplateTaxonomies(programIdentifier: string, programTemplateResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramTemplateTaxonomy>;
    public getProgramTemplateTaxonomies(programIdentifier: string, programTemplateResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramTemplateTaxonomy>>;
    public getProgramTemplateTaxonomies(programIdentifier: string, programTemplateResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramTemplateTaxonomy>>;
    public getProgramTemplateTaxonomies(programIdentifier: string, programTemplateResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramTemplateTaxonomies.');
        }
        if (programTemplateResourceIdentifier === null || programTemplateResourceIdentifier === undefined) {
            throw new Error('Required parameter programTemplateResourceIdentifier was null or undefined when calling getProgramTemplateTaxonomies.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramTemplateTaxonomy>(`${this.configuration.basePath}/programtemplatetaxonomies/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(programTemplateResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramTemplateTaxonomy(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramTemplateTaxonomy(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramTemplatesRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Find Program Templates for Program by Identifier
     * Returns active published Program Templates for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramTemplates(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramTemplate>>;
    public getProgramTemplates(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramTemplate>>>;
    public getProgramTemplates(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramTemplate>>>;
    public getProgramTemplates(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramTemplates.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramTemplate>>(`${this.configuration.basePath}/programtemplates/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramTemplate(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramTemplate(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramThreatIndicatorTaxonomyClassificationsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Indicator Taxonomy Classifications
     * Get list of Taxonomy Classifications for Indicators for Threats across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramThreatIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>;
    public getProgramThreatIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>>;
    public getProgramThreatIndicatorTaxonomyClassifications(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>>;
    public getProgramThreatIndicatorTaxonomyClassifications(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramThreatIndicatorTaxonomyClassifications.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectWithFactorIndicatorTaxonomyClassifications>>(`${this.configuration.basePath}/programthreatindicatortaxonomyclassifications/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectWithFactorIndicatorTaxonomyClassifications(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramThreatIndicatorsRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Get Program Threat indicators
     * Get list of Indicators for Threats across Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramThreatIndicators(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectFactorIndicator>>;
    public getProgramThreatIndicators(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectFactorIndicator>>>;
    public getProgramThreatIndicators(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectFactorIndicator>>>;
    public getProgramThreatIndicators(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramThreatIndicators.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectFactorIndicator>>(`${this.configuration.basePath}/programthreatindicators/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFactorIndicator(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFactorIndicator(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramThreatSummaryRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Threat Summary by Program Identifier
     * Returns Program Threat Summary
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramThreatSummary(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramThreatSummaryReport>;
    public getProgramThreatSummary(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramThreatSummaryReport>>;
    public getProgramThreatSummary(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramThreatSummaryReport>>;
    public getProgramThreatSummary(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramThreatSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramThreatSummaryReport>(`${this.configuration.basePath}/programthreatsummary/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramThreatSummaryReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramThreatSummaryReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramViabilityAssessmentRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Viability Assessment by Program Identifier
     * Returns Program Viability Assessment
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramViabilityAssessment(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramViabilityAssessmentReport>;
    public getProgramViabilityAssessment(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramViabilityAssessmentReport>>;
    public getProgramViabilityAssessment(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramViabilityAssessmentReport>>;
    public getProgramViabilityAssessment(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramViabilityAssessment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramViabilityAssessmentReport>(`${this.configuration.basePath}/programviabilityassessment/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramViabilityAssessmentReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramViabilityAssessmentReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramWorkPlanRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Program Work Plan report by Program Identifier
     * Returns Program work plan report
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramWorkPlan(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramWorkPlanReport>;
    public getProgramWorkPlan(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramWorkPlanReport>>;
    public getProgramWorkPlan(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramWorkPlanReport>>;
    public getProgramWorkPlan(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramWorkPlan.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramWorkPlanReport>(`${this.configuration.basePath}/programworkplan/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramWorkPlanReport(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramWorkPlanReport(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get list of Programs
     * Get list of Programs accessible to end-user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrograms(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Program>>;
    public getPrograms(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Program>>>;
    public getPrograms(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Program>>>;
    public getPrograms(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Program>>(`${this.configuration.basePath}/programs`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Program(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Program(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectFootprintsRequiredPermission() {
        return '' || 'ViewProgramBasics';
    }

    /**
     * Get Project Footprints for Program
     * Returns Project Footprints for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFootprints(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectFootprint>>;
    public getProjectFootprints(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectFootprint>>>;
    public getProjectFootprints(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectFootprint>>>;
    public getProjectFootprints(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProjectFootprints.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectFootprint>>(`${this.configuration.basePath}/projectfootprints/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectFootprint(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectFootprint(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public parseShapefileForProgramMapRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Parse shapefile for Program map
     * Parse shapefile for Program map
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param shapeFileBytes GIS shapefile (zip archive containing a .shp or .gdp file (not larger than 10MB))
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parseShapefileForProgramMap(programIdentifier: string, shapeFileBytes?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FeatureCollection>;
    public parseShapefileForProgramMap(programIdentifier: string, shapeFileBytes?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FeatureCollection>>;
    public parseShapefileForProgramMap(programIdentifier: string, shapeFileBytes?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FeatureCollection>>;
    public parseShapefileForProgramMap(programIdentifier: string, shapeFileBytes?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling parseShapefileForProgramMap.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (shapeFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('shape_file_bytes', <any>shapeFileBytes) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<FeatureCollection>(`${this.configuration.basePath}/programmap/${encodeURIComponent(String(programIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FeatureCollection(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FeatureCollection(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramAssociatedLinkRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program associated link
     * Update specific associated link for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param associatedLinkNew Updated associated link for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramAssociatedLink(programIdentifier: string, resourceIdentifier: string, associatedLinkNew: AssociatedLinkNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramAssociatedLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramAssociatedLink.');
        }
        if (associatedLinkNew === null || associatedLinkNew === undefined) {
            throw new Error('Required parameter associatedLinkNew was null or undefined when calling updateProgramAssociatedLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programassociatedlinks/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(associatedLinkNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramBoundaryRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program boundary
     * Update Program Boundary
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param featureCollection Program boundary (GeoJSON)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramBoundary(programIdentifier: string, featureCollection: FeatureCollection, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramMap>;
    public updateProgramBoundary(programIdentifier: string, featureCollection: FeatureCollection, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramMap>>;
    public updateProgramBoundary(programIdentifier: string, featureCollection: FeatureCollection, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramMap>>;
    public updateProgramBoundary(programIdentifier: string, featureCollection: FeatureCollection, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramBoundary.');
        }
        if (featureCollection === null || featureCollection === undefined) {
            throw new Error('Required parameter featureCollection was null or undefined when calling updateProgramBoundary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<ProgramMap>(`${this.configuration.basePath}/programmap/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(featureCollection),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramMap(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramMap(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramCustomReportRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program custom report
     * Update specific custom report for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param customReportNew Updated custom report for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramCustomReport(programIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramCustomReport(programIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramCustomReport(programIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramCustomReport(programIdentifier: string, resourceIdentifier: string, customReportNew: CustomReportNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramCustomReport.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramCustomReport.');
        }
        if (customReportNew === null || customReportNew === undefined) {
            throw new Error('Required parameter customReportNew was null or undefined when calling updateProgramCustomReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programcustomreports/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(customReportNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramCustomReportGroupRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program custom report group
     * Update specific custom report group for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param programCustomReportGroupNew Updated custom report group for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramCustomReportGroup(programIdentifier: string, resourceIdentifier: string, programCustomReportGroupNew: ProgramCustomReportGroupNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramCustomReportGroup.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramCustomReportGroup.');
        }
        if (programCustomReportGroupNew === null || programCustomReportGroupNew === undefined) {
            throw new Error('Required parameter programCustomReportGroupNew was null or undefined when calling updateProgramCustomReportGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programcustomreportgroups/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(programCustomReportGroupNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramMapLayerRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program map layer
     * Update map layer for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param shapeFileBytes GIS shapefile (zip archive)
     * @param name Map layer name
     * @param description Miradi formatted_text fields (allows limited html markup)
     * @param source Miradi formatted_text fields (allows limited html markup)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramMapLayer(programIdentifier: string, resourceIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramMapLayer(programIdentifier: string, resourceIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramMapLayer(programIdentifier: string, resourceIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramMapLayer(programIdentifier: string, resourceIdentifier: string, shapeFileBytes?: Blob, name?: string, description?: string, source?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramMapLayer.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramMapLayer.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (shapeFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('shape_file_bytes', <any>shapeFileBytes) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }
        if (source !== undefined) {
            localVarFormParams = localVarFormParams.append('source', <any>source) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programmaplayers/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramMemberRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program member
     * Update specific member for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param programMemberUpdate Updated member for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramMember(programIdentifier: string, resourceIdentifier: string, programMemberUpdate: ProgramMemberUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramMember(programIdentifier: string, resourceIdentifier: string, programMemberUpdate: ProgramMemberUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramMember(programIdentifier: string, resourceIdentifier: string, programMemberUpdate: ProgramMemberUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramMember(programIdentifier: string, resourceIdentifier: string, programMemberUpdate: ProgramMemberUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramMember.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramMember.');
        }
        if (programMemberUpdate === null || programMemberUpdate === undefined) {
            throw new Error('Required parameter programMemberUpdate was null or undefined when calling updateProgramMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programmembers/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(programMemberUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramPortfolioRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program portfolio
     * Update specific portfolio for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param portfolioNew Updated portfolio for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramPortfolio(programIdentifier: string, resourceIdentifier: string, portfolioNew: PortfolioNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramPortfolio(programIdentifier: string, resourceIdentifier: string, portfolioNew: PortfolioNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramPortfolio(programIdentifier: string, resourceIdentifier: string, portfolioNew: PortfolioNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramPortfolio(programIdentifier: string, resourceIdentifier: string, portfolioNew: PortfolioNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramPortfolio.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramPortfolio.');
        }
        if (portfolioNew === null || portfolioNew === undefined) {
            throw new Error('Required parameter portfolioNew was null or undefined when calling updateProgramPortfolio.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programportfolios/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(portfolioNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramReportDocumentTemplateRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program report document template
     * Update Program-specific report document template
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param templateFileBytes Template file bytes (supported content types are docx. Max file size allowed is 15MB.)
     * @param name Template name (unique within program)
     * @param templateDescription Template description
     * @param templateGuidance Template guidance
     * @param isActive Indicates whether template is active or not (i.e. visible to relevant projects)
     * @param relevantProjectResourceIdentifiers Relevant Project for Template
     * @param filterTags List of tags for filtering
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramReportDocumentTemplate(programIdentifier: string, resourceIdentifier: string, templateFileBytes?: Blob, name?: string, templateDescription?: string, templateGuidance?: string, isActive?: boolean, relevantProjectResourceIdentifiers?: Array<string>, filterTags?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramReportDocumentTemplate.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramReportDocumentTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (templateFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('template_file_bytes', <any>templateFileBytes) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (templateDescription !== undefined) {
            localVarFormParams = localVarFormParams.append('template_description', <any>templateDescription) as any || localVarFormParams;
        }
        if (templateGuidance !== undefined) {
            localVarFormParams = localVarFormParams.append('template_guidance', <any>templateGuidance) as any || localVarFormParams;
        }
        if (isActive !== undefined) {
            localVarFormParams = localVarFormParams.append('is_active', <any>isActive) as any || localVarFormParams;
        }
        if (relevantProjectResourceIdentifiers) {
            if (localVarUseForm) {
                relevantProjectResourceIdentifiers.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('relevant_project_resource_identifiers', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('relevant_project_resource_identifiers', relevantProjectResourceIdentifiers.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }
        if (filterTags) {
            if (localVarUseForm) {
                filterTags.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('filter_tags', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('filter_tags', filterTags.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programreportdocumenttemplates/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramSlideshowImageRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program slideshow image
     * Update specific program slideshow image for Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param imageFileBytes Image file bytes (supported content types are png, jpeg, gif, bmp and tif. Max file size allowed is 3MB. All images will be converted to .png.)
     * @param caption Image caption
     * @param imageOrder Image order - defaults to next in sequence if not supplied
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramSlideshowImage(programIdentifier: string, resourceIdentifier: string, imageFileBytes?: Blob, caption?: string, imageOrder?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramSlideshowImage.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProgramSlideshowImage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (imageFileBytes !== undefined) {
            localVarFormParams = localVarFormParams.append('image_file_bytes', <any>imageFileBytes) as any || localVarFormParams;
        }
        if (caption !== undefined) {
            localVarFormParams = localVarFormParams.append('caption', <any>caption) as any || localVarFormParams;
        }
        if (imageOrder !== undefined) {
            localVarFormParams = localVarFormParams.append('image_order', <any>imageOrder) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programslideshowimages/${encodeURIComponent(String(programIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProgramSummaryRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Update Program summary
     * Update summary for a specific Program
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param programSummaryUpdate New summary for Program
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgramSummary(programIdentifier: string, programSummaryUpdate: ProgramSummaryUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProgramSummary(programIdentifier: string, programSummaryUpdate: ProgramSummaryUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProgramSummary(programIdentifier: string, programSummaryUpdate: ProgramSummaryUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProgramSummary(programIdentifier: string, programSummaryUpdate: ProgramSummaryUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling updateProgramSummary.');
        }
        if (programSummaryUpdate === null || programSummaryUpdate === undefined) {
            throw new Error('Required parameter programSummaryUpdate was null or undefined when calling updateProgramSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/programsummary/${encodeURIComponent(String(programIdentifier))}`,
            CaseUtils.toSnake(programSummaryUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

}
