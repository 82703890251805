
export class UserPreferences {

  dismissedUxElements?: string[]; // 'new-feature-assumption', 'new-feature-output', 'new-feature-support'

  constructor(item?: Partial<UserPreferences>) {
    Object.assign(this, item);
  }

}
