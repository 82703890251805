import { OnDestroy, Component } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ProgramFeaturePermission, ProjectFeaturePermission } from 'app/shared/services';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { BrowserUtils } from 'app/shared/utils';
import { Subscription } from 'rxjs';


@Component({
  template: '<div></div>'
})
export abstract class BaseComponent implements OnDestroy {

  baseSubscriptions: Subscription[];
  subscriptions: Subscription[];
  scrollbarWidth: number;

  constructor(
    protected authService: AuthService,
  ) {
    this.baseSubscriptions = [];
    this.subscriptions = [];

    this.scrollbarWidth = BrowserUtils.getScrollbarWidth();
  }

  ngOnDestroy() {
    this.unsubscribeAll(true);
  }

  unsubscribeAll(clearBaseSubscriptions = false) {
    if (clearBaseSubscriptions && this.baseSubscriptions) {
      for (const sub of this.baseSubscriptions) {
        if (sub) sub.unsubscribe();
      };
      this.baseSubscriptions.length = 0;
    }

    if (this.subscriptions) {
      for (const sub of this.subscriptions) {
        if (sub) sub.unsubscribe();
      };
      this.subscriptions.length = 0;
    }
  }

  hasProgramPermission(featureName: string): boolean {
    if (!featureName) return true;

    if (!this.authService.currentProgramPermission) {
      console.warn('Need to get program permissions before calling this method.');
      if ((window as any).appInsights) {
        (window as any).appInsights.trackTrace({
          message: 'Need to get program permissions before calling this method.',
          severityLevel: SeverityLevel.Warning,
        });
      }
      return false;
    }

    return (this.authService.currentProgramPermission.featurePermissions || [])
    .find((pfp: ProgramFeaturePermission) => {
      return pfp.featureName === featureName;
    })?.isGranted;
  }

  hasProjectPermission(featureName: string): boolean {
    if (!featureName) return true;

    if (!this.authService.currentProjectPermission) {
      if ((window as any).appInsights) {
        (window as any).appInsights.trackTrace({
          message: 'Need to get program permissions before calling this method.',
          severityLevel: SeverityLevel.Warning,
        });
      }
      console.warn('Need to get program permissions before calling this method.');
      return false;
    }

    return (this.authService.currentProjectPermission.featurePermissions || [])
    .find((pfp: ProjectFeaturePermission) => {
      return pfp.featureName === featureName;
    })?.isGranted;
  }
}

