import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRouteGuardService, HomeRouteGuardService } from './shared/services';


const appRoutes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'about',
    loadChildren: () => import('app/about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'account',
    loadChildren: () => import('app/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'account-setup',
    loadChildren: () => import('app/account-setup/account-setup-routing.module').then(m => m.AccountSetupRoutingModule),
    canActivate: [AuthRouteGuardService],
  },
  {
    path: 'building-blocks',
    loadChildren: () => import('app/building-blocks/building-blocks.module').then(m => m.BuildingBlocksModule),
  },
  {
    path: 'home',
    loadChildren: () => import('app/home/home.module').then(m => m.HomeModule),
    canActivate: [HomeRouteGuardService],
  },
  {
    path: 'home-user',
    loadChildren: () => import('app/home-user/home-user.module').then(m => m.HomeUserModule),
    canActivate: [HomeRouteGuardService],
  },
  {
    path: 'miradi-desktop', // permalink miradi-desktop/downloads
    loadChildren: () => import('app/miradi-desktop/miradi-desktop.module').then(m => m.MiradiDesktopModule),
  },
  {
    path: 'miradi-downloads',
    loadChildren: () => import('app/biz-admin/miradi-downloads/miradi-downloads.module').then(m => m.MiradiDownloadsModule),
  },
  {
    path: 'program-report',
    loadChildren: () => import('app/program-report-old/program-report-old.module').then(m => m.ProgramReportOldModule),
  },
  {
    path: 'program',
    loadChildren: () => import('app/program/program.module').then(m => m.ProgramModule),
  },
  {
    path: 'program-information',
    loadChildren: () => import('app/biz-admin/program-information/program-information.module').then(m => m.ProgramInformationModule),
  },
  {
    path: 'project-report',
    loadChildren: () => import('app/project-report-old/project-report-old.module').then(m => m.ProjectReportOldModule),
  },
  {
    path: 'project-search',
    loadChildren: () => import('app/project-search/project-search.module').then(m => m.ProjectSearchModule),
  },
  {
    path: 'project',
    loadChildren: () => import('app/project/project.module').then(m => m.ProjectModule),
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('app/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
  },
  {
    path: 'user-information',
    loadChildren: () => import('app/biz-admin/user-information/user-information.module').then(m => m.StandaloneUsersModule),
  },

  {
    path: '**',
    loadChildren: () => import('app/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
